<template>
  <v-dialog v-model="newRequestDialogIsVisible">
    <v-card
      class="mx-auto"
      max-width="600px"
      scrollable
    >
      <v-card-title>
        {{ $t('Add location') }}
      </v-card-title>
      <v-card-text>
        <p class="fs-18 fw-500 mb-4">
          {{ $t('Search for a location to request ownership.') }}
        </p>
        <v-autocomplete
          v-model="selectedProvider"
          v-model:search="providerSearchQuery"
          :aria-label="$t('Search by location name')"
          :items="searchedProviders"
          :placeholder="$t('Search')"
          class="mb-4"
          color="secondary"
          density="compact"
          item-title="name"
          item-value="id"
          prepend-inner-icon="search"
          variant="filled"
          auto-select-first
          hide-details
          hide-no-data
          no-filter
          return-object
        >
          <template #item="{ item, props }">
            <v-list-item
              v-bind="props"
              title=""
            >
              <div class="fw-500">{{ item.raw.name }}</div>
              <div class="fs-14">{{ item.raw.location }}</div>
            </v-list-item>
          </template>
        </v-autocomplete>

        <LabeledTextarea
          v-model="requestReason"
          message="Describe who you are and why you require access to this location"
          auto-grow
          filled
          mandatory
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="newRequestDialogIsVisible = false"
          :loading="processing"
          variant="text"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="createRequest()"
          :disabled="!selectedProvider || !requestReason"
          :loading="processing"
          color="primary"
          variant="flat"
        >
          {{ $t('Add location') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const props = defineProps({
  accessRequests: {
    type: Array,
    required: true,
  },
  ownershipRequests: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['accessRequestAdded', 'ownershipRequestAdded']);

const eventBus = useEventBus();
const newRequestDialogIsVisible = ref(false);
const processing = ref(false);
const providerSearchQuery = ref(null);
const requestReason = ref(null);
const searchedProviders = ref([]);
const selectedProvider = ref(null);
const store = useStore();

defineExpose({
  open,
});

watch(
  providerSearchQuery,
  (searchValue) => {
    if (searchValue) {
      searchProviders();
    }
  },
  { immediate: true },
);

function open() {
  searchedProviders.value = [];
  providerSearchQuery.value = null;
  selectedProvider.value = null;
  newRequestDialogIsVisible.value = true;
}

async function createRequest() {
  // Access request
  if (selectedProvider.value.business_id === store.state.profile.business_id) {
    const userAlreadyHasAccessToProvider =
      store.state.profile.provider_ids.length === 0 ||
      store.state.profile.provider_ids.includes(selectedProvider.value.id);
    if (userAlreadyHasAccessToProvider) {
      return eventBus.chime('You already have access to this provider location.');
    }

    const userHasPendingRequestForLocation = props.accessRequests.find(
      (request) => request.provider_id === selectedProvider.value.id,
    );
    if (userHasPendingRequestForLocation) {
      return eventBus.chime('You have a pending access request to this location.');
    }

    if (!confirm('You are requesting access to the provider location. Would you like to continue?'))
      return;
    await createAccessRequest();
  } else {
    // Ownership request
    if (
      props.ownershipRequests.find(
        (request) => request.business_id === selectedProvider.value.business_id,
      )
    ) {
      return eventBus.chime('You have a pending ownership request for this provider location.');
    }

    if (
      !confirm(
        'You are requesting the transfer of ownership of this provider into your business. Would you like to continue?',
      )
    )
      return;
    await createOwnershipRequest();
  }
}

async function createAccessRequest() {
  processing.value = true;
  const params = {
    provider_id: selectedProvider.value.id,
    request_reason: requestReason.value,
  };
  const response = await api.manager.access_request.create(params);

  processing.value = false;
  newRequestDialogIsVisible.value = false;
  emit('accessRequestAdded', response.data);
  eventBus.chime('Request sent');
}

async function createOwnershipRequest() {
  processing.value = true;
  const params = {
    business_id: store.state.profile.business_id,
    provider_id: selectedProvider.value.id,
    request_reason: requestReason.value,
  };
  const response = await api.manager.ownership_request.create(params);

  processing.value = false;
  newRequestDialogIsVisible.value = false;
  emit('ownershipRequestAdded', response.data);
  eventBus.chime('Request sent');
}

function searchProviders() {
  api.public_api.provider.index({ query: providerSearchQuery.value }, (resp) => {
    searchedProviders.value = resp.data;
  });
}
</script>
