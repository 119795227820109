<template>
  <v-card
    data-cy="overview-card"
    elevation="0"
    tile
  >
    <v-card-title class="tab-title">
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            v-if="$vuetify.display.smAndDown"
            @click="emit('back')"
            class="me-1 c-black"
            variant="text"
            icon
          >
            <v-icon
              icon="chevron_left"
              size="28"
            />
          </v-btn>
          <h2 class="v-card-title py-0 px-0">
            {{ $t('Overview') }}
          </h2>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="py-4">
      <template v-if="provider.published">
        <p>Your profile is published!</p>
        <v-btn
          @click="togglePublished"
          :loading="processing"
          color="primary"
          data-cy="unpublish-button"
          variant="outlined"
        >
          Unpublish
        </v-btn>
      </template>
      <template v-else>
        <p>Your profile is not published</p>
        <v-btn
          @click="togglePublished"
          :loading="processing"
          color="primary"
          data-cy="publish-button"
        >
          Publish
        </v-btn>
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
const props = defineProps({
  provider: {
    type: Object,
    required: true,
  },
  processing: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['back', 'change:publish']);

const togglePublished = () => {
  emit('change:publish', !props.provider.published);
};
</script>
