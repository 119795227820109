<template>
  <v-container
    v-if="grants"
    class="px-0 pt-0"
    fluid
  >
    <PageHero
      :title="$store.state.pages.Grants.title || 'Grants'"
      image="/icons/hands_phone.svg"
      subtitle="Find grants you may be eligible for and apply in minutes!"
    />

    <v-container class="mxw-1400">
      <v-row
        v-if="grantPrograms.length > publicGrantPrograms.length"
        class="mb-3"
      >
        <v-col class="ta-right">
          <v-btn
            @click="$refs.passwordDialog.open({})"
            color="primary"
            data-cy="grant-password-button"
          >
            {{ $t('Enter a code') }}
          </v-btn>

          <ResourceDialog
            @save="submitPassword"
            ref="passwordDialog"
            :fields="[
              {
                text: 'Enter code',
                value: 'password',
                required: true,
              },
            ]"
            save-button-text="Submit"
            title="New application from code"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="grantProgram in publicGrantPrograms"
          :key="grantProgram.id"
          class="d-flex flex-column"
          cols="12"
          md="4"
        >
          <GrantProgramCard
            :grant-program="grantProgram"
            :grants="grants.filter((grant) => grant.grant_program_id == grantProgram.id)"
            class="flex-1-0"
          />
        </v-col>
      </v-row>

      <VerticalSpacer v-if="publicGrantPrograms.length > 0" />
    </v-container>

    <NullState v-if="publicGrantPrograms.length == 0 && grants.length == 0" />

    <v-pagination
      v-show="pages > 1"
      v-model="page"
      :length="pages"
      :total-visible="8"
      class="mt-4"
    />

    <VerticalSpacer />
  </v-container>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import GrantProgramCard from '@/manager/components/grants/GrantProgramCard.vue';
import NullState from '@/shared/components/NullState.vue';
import PageHero from '@/shared/components/content/PageHero.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();

const grants = ref(null);
const grantPrograms = ref([]);
const page = ref(1);
const pages = ref(null);
const passwordDialog = ref(null);
const publicGrantPrograms = ref([]);

onMounted(() => void load());

async function load() {
  void loadGrantPrograms();

  const { data } = await Api.manager.grant.index({});
  grants.value = data;
}

async function loadGrantPrograms() {
  const { data } = await Api.public_api.organization.grant_program.index();
  grantPrograms.value = data;
  publicGrantPrograms.value = grantPrograms.value.filter(
    (grantProgram) => grantProgram.hidden !== true,
  );
}

function submitPassword(newVal) {
  const foundGrantProgram = grantPrograms.value.find(
    (grantProgram) => grantProgram.password === newVal.password,
  );
  if (foundGrantProgram) {
    router.push({
      name: 'GrantProgramShow',
      params: { grantProgramId: foundGrantProgram.id },
    });
  } else {
    passwordDialog.value.close();
    eventBus.chime('Incorrect password. Please try again.');
  }
}
</script>
