<template>
  <div v-if="subsidyPrograms">
    <template v-if="subsidyPrograms.length > 1">
      <v-row class="bg-primary pt-3 mb-2">
        <v-col class="d-flex justify-center py-6">
          <v-select
            v-model="subsidyProgramId"
            :item-title="(subsidyProgram) => $t(subsidyProgram.name)"
            :items="subsidyPrograms"
            class="mxw-400"
            density="comfortable"
            item-value="id"
            variant="solo"
            hide-details
          />
        </v-col>
      </v-row>
    </template>

    <v-container class="mxw-1440">
      <template v-if="subsidyPrograms !== null">
        <v-card
          border
          flat
          tile
        >
          <v-card-title role="heading">{{
            $t($store.state.pages.Students.title || 'Students')
          }}</v-card-title>

          <v-divider />

          <v-toolbar
            v-if="$store.state.pages.Attendance.enabled || paymentsEnabled"
            elevation="0"
          >
            <v-tabs>
              <v-tab
                key="Enrollment"
                :to="{ name: 'EnrollmentIndex' }"
                class="w-150 c-black fs-16 fw-500 tt-none ls-normal"
                >{{ $t('All') }}</v-tab
              >

              <v-tab
                v-if="paymentsEnabled"
                key="Payment"
                :to="{ name: 'PaymentIndex' }"
                class="w-150 c-black fs-16 fw-500 tt-none ls-normal"
                >{{ $t('Payments') }}</v-tab
              >

              <v-tab
                v-if="$store.state.pages.Attendance.enabled"
                key="Attendance"
                :to="{ name: 'AttendanceIndex' }"
                class="w-150 c-black fs-16 fw-500 tt-none ls-normal"
                >{{ $t(terms.attendance) }}</v-tab
              >
            </v-tabs>
          </v-toolbar>

          <v-divider />

          <v-card-text>
            <router-view
              :subsidy-program-id="subsidyProgramId"
              :subsidy-programs="subsidyPrograms"
            />
          </v-card-text>

          <VerticalSpacer :min-height="50" />
        </v-card>
      </template>
    </v-container>
  </div>
</template>

<script>
import Api from '@/manager/services/bright_finder';
import Terms from '@/shared/mixins/terms';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    VerticalSpacer,
  },

  mixins: [Terms],

  data() {
    return {
      paymentsEnabled: false,
      subsidyProgramId: null,
      subsidyPrograms: null,
    };
  },

  watch: {
    subsidyProgramId() {
      this.$store.commit('setFilters', ['subsidy', { subsidyProgramId: this.subsidyProgramId }]);
    },
  },

  created() {
    if (this.$store.state.features.enable_payments) {
      Api.manager.business.get((response) => {
        this.business = response.data;
        this.paymentsEnabled = this.business.enable_payments;
      });
    }

    this.loadSubsidyPrograms();
  },

  methods: {
    async loadSubsidyPrograms() {
      const { data } = await Api.public_api.organization.subsidy_program.index();
      this.subsidyPrograms = data;
      if (this.$store.state.filters.subsidy?.subsidyProgramId) {
        this.subsidyProgramId = this.$store.state.filters.subsidy.subsidyProgramId;
      } else if (this.$route.query.subsidyProgramId) {
        this.subsidyProgramId = this.$route.query.subsidyProgramId;
      } else {
        this.subsidyProgramId = this.subsidyPrograms[0]?.id;
      }
    },
  },
};
</script>

<style scoped>
.v-tab :deep(.v-btn__content) {
  white-space: normal;
  text-align: center;
}
</style>
