<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-card
          max-width="700"
          border
          flat
          tile
        >
          <v-card-title>
            <h1 class="fs-24">
              {{ $t('Complete your registration') }}
            </h1>
          </v-card-title>

          <v-divider class="mb-3" />

          <v-card-text>
            <v-row>
              <LabeledTextfield
                v-model="firstName"
                aria-label="First name"
                message="First name"
              />
              <LabeledTextfield
                v-model="lastName"
                aria-label="Last name"
                message="Last name"
              />
            </v-row>
          </v-card-text>

          <v-divider class="my-6" />

          <v-card-actions class="d-flex justify-end">
            <v-btn
              @click="save"
              :loading="processing"
              class="px-8"
              color="primary"
              size="x-large"
            >
              <span>
                {{ $t('Save and continue') }}
              </span>
              <v-icon class="ms-2"> east </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const router = useRouter();
const store = useStore();
const firstName = ref(null);
const lastName = ref(null);
const processing = ref(false);

const save = () => {
  if (!firstName.value || !lastName.value) {
    return eventBus.chime('First and last name are required to continue');
  }
  processing.value = true;

  return Api.manager.profile.update(
    { first_name: firstName.value, last_name: lastName.value },
    (resp) => {
      const profile = {
        ...store.state.profile,
        first_name: resp.data.first_name,
        last_name: resp.data.last_name,
      };
      store.commit('setProfile', profile);
      eventBus.chime('Profile saved');
      processing.value = false;
      router.replace({ name: 'Dashboard' });
    },
    (err) => {
      eventBus.chime(err.response.data.errors.join('. '));
      processing.value = false;
    },
  );
};
</script>
