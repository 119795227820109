<template>
  <v-card
    border
    flat
    tile
  >
    <v-card-text>
      <v-row>
        <v-col class="d-flex flex-grow-1 flex-shrink-0 py-0 pe-0">
          <span class="py-2">{{ $t(enrollmentProgramGroup.program_name) }}</span>
          <template v-if="seatEditingAvailable">
            <v-spacer />
            <ActionMenu
              @click:action:seats="$refs.editSeatsDialog.open(enrollmentProgramGroup)"
              :button-icon-size="20"
              :items="[{ event: 'seats', title: 'Edit seats', avatar: 'chair_alt' }]"
              button-icon="more_vert"
              right
            />
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <template v-if="seatsByType">
            <div
              v-for="[seatTypeEnrolledSeats, seatTypeTotalSeats, seatTypeName] in seatsByType"
              :key="seatTypeName"
            >
              <span :data-testId="`${seatTypeName}-seat-count`">
                <router-link
                  :to="{
                    name: 'EnrollmentIndex',
                    query: {
                      providerId: providerId,
                      programId: enrollmentProgramGroup.program_id,
                      subsidyProgramId: subsidyProgram.id,
                    },
                  }"
                  class="fs-20 fw-500"
                >
                  {{ seatTypeEnrolledSeats }} {{ $t('students') }}
                </router-link>
                / {{ seatTypeTotalSeats }} {{ $t(seatTypeName) }} {{ $t('seats') }}
              </span>
            </div>
          </template>
          <template v-else>
            <router-link
              :to="{
                name: 'EnrollmentIndex',
                query: {
                  providerId: providerId,
                  programId: enrollmentProgramGroup.program_id,
                  subsidyProgramId: subsidyProgram?.id,
                },
              }"
              class="fs-20 fw-500"
            >
              {{ enrollmentProgramGroup.count || 0 }} {{ $t('students') }}
            </router-link>
            <template v-if="shouldShowSeats">
              <span class="ms-2 me-2"> / </span>
              <span>{{ enrollmentProgramGroup.seats || 0 }} {{ $t('seats') }}</span>
            </template>
          </template>
        </v-col>
      </v-row>
    </v-card-text>

    <EditSeatsDialog
      @update:seats="emit('update:seats')"
      ref="editSeatsDialog"
      :creation-enabled="subsidyProgram?.enable_provider_seat_creation"
      :deletion-enabled="subsidyProgram?.enable_provider_seat_deletion"
      :seat-types="seatTypes"
      :subsidy-program-id="subsidyProgram?.id"
    />
  </v-card>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import EditSeatsDialog from '@/manager/components/provider/EditSeatsDialog.vue';

const emit = defineEmits(['update:seats']);

const props = defineProps({
  enrollmentProgramGroup: {
    type: Object,
    required: true,
  },
  providerId: {
    type: String,
    required: true,
  },
  seatTypes: {
    type: Array,
    required: true,
  },
  subsidyProgram: {
    type: Object,
    required: true,
  },
});

const shouldShowSeats = computed(() => {
  return props.enrollmentProgramGroup.subsidy_program_id && props.seatTypes.length;
});

const seatsByType = computed(() => {
  if (!props.enrollmentProgramGroup.seats_by_type?.length) return null;

  const seatTypes = props.enrollmentProgramGroup.seats_by_type.map((seatType) => {
    const enrolledSeats = seatType.seat_counts['enrolled'];
    const totalSeats = seatType.seat_counts['total'];

    return [enrolledSeats, totalSeats, seatType.name];
  });

  return seatTypes;
});

const seatEditingAvailable = computed(() => {
  if (!props.subsidyProgram?.enable_providers_can_view_seat_types) return false;
  if (
    !props.subsidyProgram?.enable_provider_seat_creation &&
    !props.subsidyProgram?.enable_provider_seat_deletion
  )
    return false;
  return props.seatTypes.some((seatType) => seatType.providers_can_manage);
});
</script>
