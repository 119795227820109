<template>
  <v-dialog
    v-model="visible"
    fullscreen
  >
    <v-card>
      <v-card-text class="pa-4">
        <v-container>
          <template v-if="loaded">
            <v-row class="d-flex align-center">
              <v-col cols="10">
                <span
                  v-text="[childData.name, subsidyProgram.name].join(' - ')"
                  class="fs-28 fw-600 mb-4"
                />
              </v-col>
              <v-col
                class="ta-right"
                cols="2"
              >
                <v-btn
                  :href="downloadLink"
                  class="me-3"
                  color="primary"
                  target="_blank"
                >
                  <span>{{ $t('Download') }}</span>
                </v-btn>
                <v-btn
                  @click="close"
                  variant="text"
                  icon
                >
                  <v-icon
                    class="c-black"
                    size="32"
                  >
                    close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <SubsidiesReview
              :attachments="attachments"
              :child-data="childData"
              :child-eligibility-questions="childEligibilityQuestions"
              :child-questions="childOtherQuestions"
              :child-schema="childSchema"
              :child-verification-questions="childVerificationQuestions"
              :group="groupData"
              :group-eligibility-questions="groupEligibilityQuestions"
              :group-questions="groupOtherQuestions"
              :group-schema="groupSchema"
              :group-verification-questions="groupVerificationQuestions"
              :subsidy-program="subsidyProgram"
            />

            <v-row class="mt-6 ta-center">
              <v-col>
                <v-btn
                  @click="close"
                  color="primary"
                  size="x-large"
                >
                  <span>{{ $t('Close') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <div class="mxw-600 mx-auto py-16">
              <v-progress-linear indeterminate />
            </div>
          </template>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import SubsidiesReview from '@/manager/components/SubsidiesReview.vue';
import useSubsidyData from '@/shared/composables/useSubsidyData';
import { useStore } from 'vuex';

const store = useStore();

const attachments = ref(null);
const subsidy = ref(null);
const subsidyId = ref(null);
const subsidyProgram = ref(null);
const visible = ref(false);

const {
  childData,
  childEligibilityQuestions,
  childOtherQuestions,
  childSchema,
  childVerificationQuestions,
  groupData,
  groupEligibilityQuestions,
  groupOtherQuestions,
  groupSchema,
  groupVerificationQuestions,
  loadChildDataAndGroupData,
  loadChildAndGroupQuestions,
  loadSubsidyProgramSchemas,
} = useSubsidyData({ includeUnpublished: { value: false } }, subsidyId, subsidyProgram);

const downloadLink = computed(() => {
  if (!childData.value) return '';
  const filename = childData.value.name.replaceAll(' ', '-');
  return Api.manager.subsidy.downloadUrl(
    subsidyId.value,
    store.state.profile.default_locale,
    filename,
  );
});

const loaded = computed(() => {
  return (
    attachments.value &&
    childData.value &&
    groupData.value &&
    groupVerificationQuestions.value &&
    subsidy.value &&
    subsidyProgram.value
  );
});

defineExpose({
  open,
});

function close() {
  visible.value = false;
  subsidyId.value = null;
  subsidyProgram.value = null;
}

async function open(newSubsidyId, newSubsidyProgram) {
  subsidyId.value = newSubsidyId;
  subsidyProgram.value = newSubsidyProgram;
  visible.value = true;

  loadSubsidyProgramSchemas();
  loadSubsidyAndAttachments();
  await loadChildDataAndGroupData();
  await loadChildAndGroupQuestions();
}

function loadSubsidyAndAttachments() {
  Api.manager.subsidy.get(subsidyId.value, (resp) => {
    subsidy.value = resp.data;
    attachments.value = subsidy.value.meta.attachments;
  });
}
</script>
