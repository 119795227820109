<template>
  <div>
    <v-container
      v-if="massMessages"
      class="mxw-1400"
    >
      <TitleBar
        data-cy="page-title"
        name="Mass messages"
      />

      <v-card
        class="bb-1 pa-0"
        tile
      >
        <v-card-text>
          <v-row>
            <TableHeader
              cols="6"
              title="Subject"
            />
            <TableHeader
              cols="2"
              title="Recipients"
            />
            <TableHeader
              cols="2"
              title="Date"
            />
            <TableHeader
              cols="2"
              title="Status"
            />
          </v-row>
        </v-card-text>
      </v-card>

      <TableRow
        v-for="mm in massMessages"
        :key="mm.id"
        class="my-2 px-4"
      >
        <v-col
          cols="12"
          data-cy="row-subject"
          md="6"
        >
          <div v-text="mm.subject" />
        </v-col>
        <v-col
          cols="12"
          data-cy="row-recipients"
          md="2"
        >
          <div v-text="mm.enrollment_ids.length" />
        </v-col>
        <v-col
          cols="12"
          data-cy="row-date"
          md="2"
        >
          <LongDateTime
            :date="mm.created_at"
            short-month
          />
        </v-col>
        <v-col
          cols="12"
          data-cy="row-date"
          md="2"
        >
          <v-chip color="green">
            <span v-t="'Sent'" />
          </v-chip>
        </v-col>
      </TableRow>

      <NullState
        v-if="massMessages.length == 0"
        title="You have not sent any mass messages yet."
        hide-new-button
      />
    </v-container>
  </div>
</template>

<script>
import Api from '@/manager/services/bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LongDateTime,
  },

  data() {
    return {
      massMessages: null,
    };
  },

  created() {
    this.loadMassMessages();
  },

  methods: {
    loadMassMessages() {
      this.massMessages = Api.manager.mass_message.index({}, (resp) => {
        this.massMessages = resp.data;
      });
    },
  },
};
</script>
