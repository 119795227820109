<template>
  <v-card
    v-if="homeworkAsset() || assignment.required_homework_file_upload"
    border
  >
    <v-card-title class="pa-6 pb-0">
      <v-row class="d-flex align-center">
        <v-col col="6">
          {{ $t('Homework') }}
        </v-col>

        <v-col
          v-if="homeworkAsset()"
          class="d-flex justify-end"
          col="6"
        >
          <v-btn
            @click="download"
            color="primary"
            prepend-icon="download"
            target="_blank"
          >
            {{ $t('Download') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="ma-2">
      <p>
        {{ $t('Upload one completed homework file') }}
      </p>

      <AttachmentList
        @delete="attachmentDeleted"
        :attachments="attachments"
        class="mt-4"
        outlined
      />

      <AttachmentUploader
        @uploaded="homeworkUploaded($event)"
        ref="attachment_uploader"
        :owner="{
          type: ownerType,
          id: assignmentCompletion.id,
        }"
        :restrictions="{
          maxNumberOfFiles: 1,
        }"
        class="mt-4"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const props = defineProps({
  assignment: {
    type: Object,
    default: null,
  },
  assignmentCompletion: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['change']);

const attachments = ref([]);
const language = ref(locale.value.split('-')[0]);
const ownerType = ref('AssignmentCompletion');

async function attachmentDeleted() {
  const response = await Api.assignmentCompletion.update(props.assignmentCompletion.id, {
    homework_attachment_id: null,
  });

  if (!response?.data) return;
  emit('change');

  props.assignmentCompletion.homework_attachment_id = null;
  attachments.value = [];
}

function download() {
  window.open(homeworkAsset(), '_blank');
}

function homeworkAsset() {
  return props.assignment?.assets[`${language.value}_homework`] || null;
}

async function homeworkUploaded(upload) {
  const response = await Api.assignmentCompletion.update(props.assignmentCompletion.id, {
    homework_attachment_id: upload.id,
  });

  if (!response?.data) return;
  emit('change');

  props.assignmentCompletion.homework_attachment_id = upload.id;
  attachments.value = [upload];
}

async function load() {
  await loadAttachments();
}

async function loadAttachments() {
  const ownerId = props.assignmentCompletion?.id;
  if (!ownerId) return;

  const response = await Api.manager.attachment.index({
    owner_type: ownerType.value,
    owner_id: ownerId,
  });
  if (response?.status !== 200) return;

  attachments.value =
    response.data.filter(
      (attachment) => attachment.id === props.assignmentCompletion.homework_attachment_id,
    ) || [];
}

onMounted(load);
</script>
