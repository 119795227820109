import { EventBus } from '@/plugins/event-bus';
import { isAuthenticated, needsMFA } from '@/shared/services/auth';
import Api from '@/shared/services/bright_finder';

export function createLoginCode({ next, query }) {
  Api.member.login_code.create(
    {
      email: query.login_code_email,
      organization_id: window.organization_id,
      is_provider: true,
      redirect_url: query.redirect_url,
      site_id: window.site_id,
    },
    (resp) => {
      next({
        name: 'CodeSent',
        query: {
          token: resp.data.code_id,
          redirect_url: query.redirect_url,
          email_sent_to: query.login_code_email,
          is_provider: true,
        },
      });
    },
    (err) => {
      const message = err.response?.data?.errors?.join('. ') || err.message;
      EventBus.$emit('chime', message);
    },
  );
}

export function directAuthenticatedUser({ next, store, to }) {
  if (to.meta?.registration) return next();
  if (to.name === 'SignOut') return next();

  const { config, profile } = store.state;

  const needsPassword =
    config.enable_password_authentication &&
    !profile.require_sso &&
    (!profile.password_set ||
      to.query.password_reset === 'true' ||
      profile?.password_reset_required === true);
  if (needsPassword)
    return next({ name: 'RegisterPassword', query: { password_reset: to.query.password_reset } });

  if (needsMFA(store)) return next({ name: 'RegisterFactor' });

  const needsTermsAgreement = config.terms_agreement && !profile.terms_agreement_id;
  if (needsTermsAgreement) return next({ name: 'TermsAgreement' });

  const needsName = !profile.first_name || !profile.last_name;
  if (needsName) return next({ name: 'RegisterName' });

  const needsBusinessConfirmation =
    store.getters.needsBusinessConfirmation() && to.name !== 'RegisterAccess';
  if (needsBusinessConfirmation) return next({ name: 'RegisterAccess' });

  if (to.query.redirect_url) return next(to.query.redirect_url);

  return next();
}

export function handleUnauthenticatedUser({ next, to }) {
  if (to.name === 'ProviderCrawlerShow' || to.name === 'PublicProviderShow') return next();

  return next({ path: '/' });
}

export function handleProtectedRoute({ next, store, to }) {
  if (isAuthenticated(store)) return directAuthenticatedUser({ next, store, to });

  store.dispatch('identify', {
    success() {
      directAuthenticatedUser({ next, store, to });
    },
    failure() {
      if (to.query.login_code_email) {
        createLoginCode({ next, query: to.query });
      } else {
        handleUnauthenticatedUser({ next, to });
      }
    },
  });

  return true;
}
