<template>
  <ResourceDialog
    @close="close()"
    @save="updateSeatCounts()"
    ref="dialog"
    :max-width="600"
    :title="program?.program_name"
    data-testid="edit-seats-dialog"
    closeable
  >
    <template #form>
      <v-row
        v-for="seatType in program?.seats_by_type"
        :key="seatType.id"
      >
        <v-col>
          <v-row class="pa-2">
            {{ $t(seatType.name) }}
          </v-row>
          <v-row class="ps-2">
            <span class="fs-12">
              {{
                `${seatType.seat_counts.reserved} reserved + ${seatType.seat_counts.open} available = ${seatType.originalCount} total`
              }}
            </span>
          </v-row>
        </v-col>
        <v-col
          class="pa-0"
          cols="6"
        >
          <LabeledCounter
            v-model="seatType.seat_counts.total"
            :data-testid="`${seatType.name}-seat-type-counter`"
            :disabled="!seatType.providersCanManage"
            :enforce-min-max-rules="false"
            :max="creationEnabled ? null : seatType.originalCount"
            :min="deletionEnabled ? 0 : seatType.originalCount"
            suffix="seats"
          />
        </v-col>
      </v-row>
    </template>
  </ResourceDialog>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/manager/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import LabeledCounter from '@/shared/components/form/LabeledCounter.vue';
import useEventBus from '@/shared/composables/useEventBus';

const emit = defineEmits(['update:seats']);

const props = defineProps({
  creationEnabled: {
    type: Boolean,
    default: false,
  },
  deletionEnabled: {
    type: Boolean,
    default: false,
  },
  subsidyProgramId: {
    type: String,
    default: null,
  },
  seatTypes: {
    type: Array,
    default: () => [],
  },
});

const eventBus = useEventBus();
const dialog = ref(null);
const program = ref(null);

defineExpose({
  open,
});

function open(group) {
  program.value = _.cloneDeep(group);
  program.value.seats_by_type.forEach((seatType) => {
    seatType.originalCount = seatType.seat_counts.total;
    seatType.providersCanManage = canManageSeatType(seatType.id);
  });
  dialog.value.open();
}

function close() {
  dialog.value?.close();
}

function canManageSeatType(seatTypeId) {
  return !!props.seatTypes.find((seatType) => seatType.id === seatTypeId)?.providers_can_manage;
}

function seatTypeModificationMessages() {
  const messages = [];

  for (const seatType of program.value.seats_by_type) {
    if (seatType.seat_counts.total > seatType.originalCount) {
      messages.push(
        `Create ${seatType.seat_counts.total - seatType.originalCount} ${seatType.name} seat(s)`,
      );
    } else if (seatType.seat_counts.total < seatType.originalCount) {
      messages.push(
        `Delete ${seatType.originalCount - seatType.seat_counts.total} ${seatType.name} seat(s)`,
      );
    }
  }

  return messages.join('\n');
}

async function updateSeatCounts() {
  if (!checkValidSeatUpdate()) return;

  if (!confirm(`Are you sure you want to do the following?\n\n ${seatTypeModificationMessages()}`))
    return;

  let seatResponse = null;
  let messages = [];
  for (const seatType of program.value.seats_by_type) {
    let seatCountToAddOrRemove = seatType.seat_counts.total - seatType.originalCount;

    const params = {
      subsidy_program_id: props.subsidyProgramId,
      program_id: program.value.program_id,
      seat_type_id: seatType.id,
      quantity: Math.abs(seatCountToAddOrRemove),
    };

    if (seatCountToAddOrRemove > 0) {
      seatResponse = await Api.manager.seat.create(params);
      if (seatResponse.status === 200) {
        messages.push(`Created ${params.quantity} ${seatType.name} seat(s)`);
      }
    } else if (seatCountToAddOrRemove < 0) {
      seatResponse = await Api.manager.seat.destroy(params);
      if (seatResponse.status === 200) {
        messages.push(`Deleted ${seatResponse.data.deleted_count} ${seatType.name} seat(s)`);
      }
    }
  }

  eventBus.chime(messages.join(', '));

  if (seatResponse) emit('update:seats');
  close();
}

function checkValidSeatUpdate() {
  const managedSeatTypes = program.value.seats_by_type.filter(
    (seatType) => seatType.providersCanManage,
  );

  for (let seatType of managedSeatTypes) {
    // Negative seat count error
    if (seatType.seat_counts.total < 0) {
      eventBus.error('Seat count must be greater than or equal to zero');
      return false;
    }

    // Creation error
    if (!props.creationEnabled && seatType.seat_counts.total > seatType.originalCount) {
      seatType.seat_counts.total = seatType.originalCount;
      eventBus.error(`Creating '${seatType.name}' seats is not permitted`);
      return false;
    }

    // Creating more than 100 seats error
    if (seatType.seat_counts.total > seatType.originalCount + 100) {
      seatType.seat_counts.total = seatType.originalCount + 100;
      eventBus.error('Creating more than 100 seats at a time is not permitted');
      return false;
    }

    // Deletion error
    if (!props.deletionEnabled && seatType.seat_counts.total < seatType.originalCount) {
      seatType.seat_counts.total = seatType.originalCount;
      eventBus.error(`Deleting '${seatType.name}' seats is not permitted`);
      return false;
    }

    // Reserved seat deletion error
    if (seatType.seat_counts.total < seatType.seat_counts.reserved) {
      seatType.seat_counts.total = seatType.seat_counts.reserved;
      eventBus.error(
        `Reserved '${seatType.name}' seats may not be removed. You must allow at least ${seatType.seat_counts.reserved} seats to remain.`,
      );
      return false;
    }
  }

  return true;
}
</script>
