<template>
  <div v-if="assignmentCompletion">
    <AssignmentHeader
      @close="$emit('close')"
      :assignment="assignment"
    />

    <v-card
      v-if="asset"
      class="mb-4"
      border
      flat
    >
      <ContentPlayer
        @complete="$emit('video-progress', 100)"
        @update-video-progress="$emit('video-progress', $event)"
        :url="asset"
        :watched-video-progress="assignmentCompletion.video_progress"
      />
    </v-card>

    <template v-if="course.restricted">
      <AssignmentHomework
        @change="$emit('reload')"
        :assignment="assignment"
        :assignment-completion="assignmentCompletion"
        class="my-4"
      />

      <CommentsCard
        @change="$emit('reload')"
        :assignment="assignment"
        :model-id="assignment.id"
        class="my-4"
        model-type="assignment"
      />
    </template>
  </div>

  <div v-else>
    <v-progress-linear indeterminate />
  </div>
</template>

<script setup>
import AssignmentHeader from '@/manager/components/assignments/AssignmentHeader.vue';
import AssignmentHomework from '@/manager/components/assignments/AssignmentHomework.vue';
import CommentsCard from '@/shared/components/learning/CommentsCard.vue';
import ContentPlayer from '@/shared/components/learning/ContentPlayer.vue';

const props = defineProps({
  assignment: {
    type: Object,
    default: null,
  },
  assignmentCompletion: {
    type: Object,
    default: null,
  },
  course: {
    type: Object,
    default: null,
  },
  tab: {
    type: String,
    default: null,
  },
});

defineEmits(['close', 'reload', 'video-progress']);

const asset = computed(() => {
  return props.assignment?.assets?.en;
});
</script>
