<template>
  <div
    v-show="loaded"
    class="h-100pc"
  >
    <div
      v-show="step == 1"
      class="h-100pc"
    >
      <v-row
        class="h-100pc"
        dense
      >
        <v-col
          class="bg-primary d-none d-sm-block pt-16"
          cols="5"
        >
          <v-row dense>
            <v-col>
              <div
                class="bc-h4 c-white px-xl-10 mx-xl-10 px-lg-10 mx-lg-10 px-md-7 mx-md-7 px-sm-4 mx-sm-4"
              >
                {{ $t($store.state.pages.Welcome.content.intro) }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <div class="pt-16">
                <img
                  :alt="$t('Iconography of a child care center and playground')"
                  :src="$a.url('images.care')"
                  class="w-100pc"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="bg-white"
          cols="12"
          lg="7"
          md="7"
          sm="7"
        >
          <v-row>
            <v-col class="d-flex align-center justify-end">
              <a
                v-if="$store.state.pages.Navigation.faq"
                id="manager_faq_link"
                :href="$store.state.pages.Navigation.faq"
                class="c-black d-inline-block fw-400 lh-20 me-4"
                target="_blank"
                tracked
              >
                {{ $t('FAQ') }}
              </a>
              <LocaleSelector />
            </v-col>
          </v-row>
          <v-container class="pt-10 mxw-700">
            <v-row
              class="mb-4"
              dense
            >
              <v-col>
                <div>
                  <img
                    :alt="$t(`Login for ${$store.state.brand.name}`)"
                    :src="$store.state.brand.logo.full"
                    class="w-100pc mxw-300"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="$store.state.pages.Welcome.content.title"
              class="mb-2"
              dense
            >
              <v-col>
                <div
                  class="c-primary bc-h5"
                  data-cy="welcome-title"
                >
                  {{ $t($store.state.pages.Welcome.content.title) }}
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="$store.state.pages.Welcome.content.subtitle"
              class="mb-2"
              dense
            >
              <v-col>
                <MarkdownContent
                  :content="$store.state.pages.Welcome.content.subtitle"
                  class="fs-16"
                  data-cy="welcome-subtitle"
                />
              </v-col>
            </v-row>

            <LoginWithSSO>
              <template
                v-if="requireEmail"
                #append
              >
                <v-divider class="my-8" />
              </template>
            </LoginWithSSO>

            <template v-if="allowMagicLinkOrPassword">
              <v-row
                class="mb-2"
                dense
              >
                <v-col>
                  <v-btn
                    v-if="requestingLoginCode"
                    @click="requestingLoginCode = false"
                    class="fs-16 px-0 underlined"
                    color="primary"
                    size="large"
                    variant="text"
                  >
                    {{ $t('Login with password instead') }}
                  </v-btn>
                  <v-btn
                    v-else
                    @click="requestingLoginCode = true"
                    class="fs-16 px-0 underlined"
                    color="primary"
                    data-cy="login-mode-button"
                    size="large"
                    variant="text"
                  >
                    {{ $t('Request one-time login code link') }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-if="requireEmail">
              <v-row dense>
                <LabeledTextfield
                  v-model="email"
                  id="access_request_email"
                  autocomplete="off"
                  color="primary"
                  data-cy="email-input"
                  message="Email address"
                  autofocus
                  hide-details
                />
              </v-row>
              <v-row
                v-if="$store.state.config.enable_password_authentication && !requestingLoginCode"
              >
                <LabeledTextfield
                  v-model="password"
                  @keyup.enter="createSession()"
                  id="access_request_password"
                  color="primary"
                  data-cy="password-input"
                  message="Password"
                  type="password"
                  filled
                />
                <v-col>
                  <PasswordReset scope="provider" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    @click="submit()"
                    id="manager_login_link"
                    :disabled="submitDisabled"
                    :loading="processing"
                    color="primary"
                    data-cy="submit-button"
                    size="x-large"
                    variant="flat"
                    tracked
                  >
                    {{ $t('Submit') }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-row
              v-if="$store.state.pages.Welcome.images.partner_1"
              class="mt-10"
              dense
            >
              <v-col cols="12">
                <div class="bc-prelude">
                  {{ $t($store.state.pages.Welcome.content.partnership) }}
                </div>
              </v-col>
              <img
                :alt="$t(`Partnership with ${$store.state.pages.Welcome.images.partner_1_name}`)"
                :src="$store.state.pages.Welcome.images.partner_1"
                class="h-40 me-4 mt-2"
              />
              <img
                v-if="$store.state.pages.Welcome.images.partner_2"
                :alt="$t(`Partnership with ${$store.state.pages.Welcome.images.partner_2_name}`)"
                :src="$store.state.pages.Welcome.images.partner_2"
                class="h-40 me-4 mt-2"
              />
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="challengeDialogIsVisible"
      :max-width="600"
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-text class="pa-6">
          <v-row dense>
            <v-col class="d-flex justify-center">
              <p class="fs-16 fw-600">
                {{ $t('Enter the one-time passcode sent to your device:') }}
              </p>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="d-flex justify-center">
              <v-otp-input
                v-model="code"
                @finish="createSession"
                :disabled="processing"
                class="mxw-400"
                data-cy="otp-input"
                length="6"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import Assets from '@/shared/services/assets';
import { createLoginCode } from '@/manager/services/request-routing';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LoginWithSSO from '@/shared/components/LoginWithSSO.vue';
import LocaleSelector from '@/shared/components/LocaleSelector.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import PasswordReset from '@/shared/components/PasswordReset.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();

const props = defineProps({
  api: {
    type: Object,
    default() {
      return Api;
    },
  },
});

const challenge = ref(null);
const challengeDialogIsVisible = ref(false);
const code = ref(null);
const confirmation_code = ref(null);
const email = ref(null);
const loaded = ref(false);
let mfaAttempts = 0;
const password = ref(null);
const processing = ref(false);
const requestingLoginCode = ref(false);
const step = ref(1);

const allowMagicLinkOrPassword = computed(() => {
  return (
    store.state.config.enable_magic_link_authentication &&
    store.state.config.enable_password_authentication
  );
});

const requireEmail = computed(() => {
  return (
    store.state.config.enable_password_authentication ||
    store.state.config.enable_magic_link_authentication
  );
});

const submitDisabled = computed(() => {
  return (
    !email.value ||
    !validEmail(email.value) ||
    (store.state.config.enable_password_authentication &&
      !password.value &&
      !requestingLoginCode.value)
  );
});

function validEmail(value) {
  return new RegExp(Assets.assets.validation.email_js).test(value);
}

function check() {
  store.dispatch('identify', {
    success() {
      router.replace(store.getters.defaultLandingPage());
    },
    failure() {
      confirmation_code.value = null;
      email.value = null;
      processing.value = false;
      loaded.value = true;
      step.value = 1;
    },
  });
}

function createSession() {
  processing.value = true;
  props.api.member.session.create(
    {
      challenge_id: challenge.value,
      code: code.value,
      email: email.value,
      organization_id: window.organization_id,
      password: password.value,
      scope: 'manager',
    },
    (resp) => {
      const { status } = resp.data;
      processing.value = false;
      if (status === 'challenge') {
        challenge.value = resp.data.challenge_id;
        challengeDialogIsVisible.value = true;
        code.value = null;
        mfaAttempts += 1;
        if (mfaAttempts.value > 1) {
          eventBus.chime(
            'Your previous code has expired. A new code has been sent to your device.',
          );
        }
      } else if (status === 'mfa_failed') {
        code.value = null;
        eventBus.chime('You entered an incorrect code. Please re-enter and try again.');
      } else if (status === 'mfa_register') {
        code.value = null;
        router.push({ name: 'RegisterFactor' });
      } else if (status === 'authenticated') {
        router.push({ name: 'Dashboard' });
      } else {
        eventBus.longChime('An unknown error has occurred. Please contact support for assistance.');
      }
    },
    () => {
      processing.value = false;
      password.value = null;
      eventBus.chime('Account could not be found for that email and password.');
    },
  );
}

function submit() {
  processing.value = true;
  if (store.state.config.enable_password_authentication && !requestingLoginCode.value) {
    createSession();
  } else {
    const query = { ...route.query };
    query.login_code_email = email.value;
    const next = router.push.bind(router);
    createLoginCode({ next, query });
  }
}

onMounted(() => {
  check();
});
</script>
