<template>
  <div class="bg-white w-100pc h-100pc">
    <v-container
      v-if="loaded"
      class="mxw-800 mx-auto py-12"
    >
      <div
        ref="progress"
        :aria-label="$t('Progress indicator - question') + ' ' + stepCount"
        class="px-4 focus-invisible"
        role="progressbar"
        tabindex="0"
        aria-live
      >
        <v-progress-linear
          v-model="progress"
          :indeterminate="!loaded"
          class="focus-invisible mb-4"
          color="primary"
        />
      </div>
      <div class="px-4">
        <QuestionSet
          v-model="form.attributes"
          @back="backFromQuestion($event)"
          @next="forwardFromQuestion($event)"
          :processing="processing"
          :questions="validQuestions"
          :readonly="form.attributes.locked"
          :schema="schema.definition"
          :section="section"
          :transition-name="transitionName"
          key-name="question"
        />

        <transition-group :name="transitionName">
          <FormQuestion
            v-show="section == 'finish'"
            @back="backFromFinish()"
            @finish="finish"
            key="finish"
            :finish-text="form.attributes.locked ? 'Done' : null"
            :forwardable="false"
            :processing="processing"
            section="Finish"
            title="You're all done!"
            finishable
          >
            <p>{{ $t("Click 'Confirm and submit' to finalize your application.") }}</p>
          </FormQuestion>
        </transition-group>
      </div>
    </v-container>
  </div>
</template>
<script>
import Api from '@/manager/services/bright_finder';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import Questionable from '@/shared/mixins/questionable';
import Stepper from '@/shared/mixins/stepper';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FormQuestion,
    QuestionSet,
  },

  mixins: [Questionable, Stepper],

  data() {
    return {
      confirmed: false,
      form: null,
      formSchema: null,
      processing: false,
      schema: null,
      section: null,
      progress: 0,
      stepCount: 0,
    };
  },

  computed: {
    loaded() {
      return this.form && this.formSchema && this.questions && this.questions.length > 0;
    },
  },

  watch: {
    loaded: {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.stepCount = parseInt(this.$route.query.step, 10);
          if (!this.stepTotal) {
            await this.validate();
          }
          this.recalculateStepTotal();
          if (this.$route.query.section) {
            setTimeout(() => {
              this.section = this.$route.query.section;
              this.$refs.progress.focus();
              this.progress = (this.stepCount / this.stepTotal) * 100;
              this.$forceUpdate();
            }, 500);
          } else {
            this.$router.push({ query: { section: 'question-0', step: 1 } });
          }
        }
      },
    },

    '$route.query.section': {
      async handler(newVal) {
        this.section = null;
        this.stepCount = parseInt(this.$route.query.step, 10);
        if (!this.stepTotal) {
          await this.validate();
        }
        this.recalculateStepTotal();

        setTimeout(() => {
          if (newVal) {
            this.section = newVal;
            this.$refs.progress.focus();
          } else {
            this.section = 'question-0';
          }
          this.progress = (this.stepCount / this.stepTotal) * 100;
          this.$forceUpdate();
        }, 600);
      },
    },
  },

  created() {
    this.ownerId = this.$route.params.schemaId;
    this.ownerType = 'Schema';
    this.formSchema = this.$store.state.schemas[this.$route.params.schemaId];
    this.load();
  },

  methods: {
    backFromFinish() {
      this.processing = true;
      this.step_back(`question-${this.validQuestions.length - 1}`);
    },

    async backFromQuestion(index) {
      this.processing = true;
      if (!this.form.attributes.locked) {
        await this.saveForm();
        await this.validate();
      }
      this.recalculateStepTotal();

      if (index - 1 < 0) {
        this.$router.push({ name: 'Dashboard' });
      } else {
        this.step_back(`question-${index - 1}`);
      }
    },

    async finish() {
      this.processing = true;
      if (!this.form.attributes.locked) {
        this.form.attributes.submitted = true;
        await Api.manager.form.update(this.form.id, this.form.attributes);
      }
      this.$router.replace({ name: 'RegisterAccess' });
    },

    async forwardFromQuestion(index) {
      this.processing = true;
      if (!this.form.attributes.locked) {
        await this.saveForm();
        await this.validate();
      }
      this.recalculateStepTotal();

      if (index + 1 >= this.validQuestions.length) {
        this.step_forward('finish');
      } else {
        this.step_forward(`question-${index + 1}`);
      }
    },

    load() {
      this.loadOnboardingForm();
    },

    async loadOnboardingForm() {
      const onboardingFormSchemaId = this.$route.params.schemaId;
      const forms =
        (await Api.manager.form.index({ 'filter[schema]': onboardingFormSchemaId })).data.data ||
        [];
      this.form = forms[forms.length - 1];

      if (!this.form) {
        const createResp = await Api.manager.form.create({
          data: {
            relationships: {
              schema: {
                data: {
                  id: onboardingFormSchemaId,
                },
              },
            },
          },
        });
        this.form = createResp.data.data;
      }

      this.loadQuestions(() => {
        this.schema = this.formSchema;
        this.recalculateStepTotal();
      });
    },

    recalculateStepTotal() {
      this.stepTotal = this.validQuestions.length + 1;
      this.progress = (this.stepCount / this.stepTotal) * 100;
    },

    async saveForm() {
      const { data } = await Api.manager.form.update(this.form.id, this.form.attributes);
      this.form = data.data;
    },
  },
};
</script>
