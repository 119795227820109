<template>
  <v-card
    :disabled="getDisabled()"
    flat
  >
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col cols="8">
          <v-icon
            color="green"
            size="28"
            start
          >
            quiz
          </v-icon>
          <span v-t="quizName" />
        </v-col>
        <v-col
          class="ta-right"
          cols="4"
        >
          <template v-if="getReadyForQuiz()">
            <template v-if="lessonCompletion.completed">
              <v-badge
                :content="$t('Pass')"
                color="green"
                inline
              />
            </template>

            <template v-else>
              <v-btn
                :to="getRoute()"
                color="primary"
              >
                <span v-t="'Take quiz'" />
              </v-btn>
            </template>
          </template>

          <template v-else>
            <v-icon
              color="primary"
              size="28"
            >
              lock_outline
            </v-icon>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import api from '@/shared/services/bright_finder';

const props = defineProps({
  assignmentCompletions: {
    type: Array,
    default: null,
  },
  assignments: {
    type: Array,
    default: null,
  },
  cohortLesson: {
    type: Object,
    default: null,
  },
  lessonCompletion: {
    type: Object,
    default: null,
  },
  schemaId: {
    type: String,
    default: null,
  },
});

const now = Date.now();
const quizName = ref('Final quiz');

function getDisabled() {
  if (props.cohortLesson?.attributes?.start_date) {
    // Convert date to local timestamp right after midnight
    const [year, month, day] = props.cohortLesson.attributes.start_date.split('-').map(Number);
    const startTime = new Date(year, month - 1, day).setHours(0, 0, 0, 0);
    if (now < startTime) return true;
  }

  if (props.cohortLesson?.attributes?.end_date) {
    // Convert date to local timestamp right before midnight
    const [year, month, day] = props.cohortLesson.attributes.end_date.split('-').map(Number);
    const endTime = new Date(year, month - 1, day).setHours(23, 59, 59, 999);
    if (now > endTime) return true;
  }

  return !(props.assignmentCompletions.length === props.assignments.length);
}

function getRoute() {
  return {
    name: 'LessonQuiz',
    params: {
      lessonCompletionId: props.lessonCompletion.id,
      quizSchemaId: props.schemaId,
    },
  };
}

function getReadyForQuiz() {
  return props.lessonCompletion.progress === 100;
}

async function load() {
  const resp = await api.public_api.organization.schema.get(props.schemaId);
  if (!resp || !resp.data) return;
  quizName.value = resp.data.name;
}

onMounted(async () => {
  await load();
});
</script>
