<template>
  <v-card
    v-if="descriptionExists"
    class="mb-4 pa-2 fs-24 fw-600"
    border
    flat
  >
    <v-card-title>
      {{ $t('Instructions') }}
    </v-card-title>

    <v-card-text>
      <MarkdownContent
        :content="assignment?.text?.en"
        class="fs-16"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import _ from 'lodash';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';

const props = defineProps({
  assignment: {
    type: Object,
    default: null,
  },
});

const descriptionExists = computed(() => !_.isEmpty(props.assignment?.text));
</script>
