<template>
  <v-col
    v-if="enabled"
    :cols="cols"
    :lg="lg"
    :md="md"
    :sm="sm"
  >
    <v-row dense>
      <v-col
        v-if="label"
        :class="labelClass"
        cols="12"
      >
        {{ $t(label) }}

        <RequiredIndicator v-if="mandatory" />
      </v-col>

      <v-col
        v-if="description"
        class="fs-16 c-light-black my-1"
        cols="12"
      >
        <span>
          {{ $t(description) }}
        </span>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-text-field
          v-model="localValue"
          @click:append="increment()"
          @click:prepend="decrement()"
          @update:model-value="handleUpdateModelValue"
          :append-inner-icon="appendIcon"
          :aria-label="ariaLabel"
          :autofocus="autofocus"
          :class="inputClass"
          :density="dense ? 'compact' : undefined"
          :disabled="disabled || locked"
          :label="$t(label)"
          :max="max"
          :message="$t(message)"
          :min="min"
          :readonly="readonly"
          :rules="rules"
          :suffix="suffix"
          append-icon="add"
          data-cy="labeled-number-field"
          data-testid="labeled-number-field"
          prepend-icon="remove"
          type="number"
          variant="filled"
          flat
          hide-spin-buttons
          round
          tile
        />
      </v-col>
    </v-row>

    <v-divider
      v-if="dividedBottom"
      class="mt-4 mb-2"
    />
  </v-col>
</template>

<script setup>
import propsToRefs from '@/shared/utils/propsToRefs';
import RequiredIndicator from '@/shared/components/RequiredIndicator.vue';
import useLabeledField from '@/shared/composables/useLabeledField';
import useSchematizedField from '@/shared/composables/useSchematizedField';

const attrs = useAttrs();
const emit = defineEmits(useLabeledField.emits);
defineExpose({ attrs, emit }); // Attrs and emit are used in composable, this is included for linter

const localValue = defineModel({ type: undefined });

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  dividedBottom: {
    type: Boolean,
    default: false,
  },
  max: {
    type: Number,
    default: null,
  },
  min: {
    type: Number,
    default: 0,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  suffix: {
    type: String,
    default: null,
  },
  ...useLabeledField.props,
  ...useSchematizedField.props,
});

const { appendIcon, inputClass, labelClass, handleUpdateModelValue } = useLabeledField(
  ...propsToRefs(props, useLabeledField.paramKeys),
);

const { ariaLabel, enabled, label, locked } = useSchematizedField(
  ...propsToRefs(props, useSchematizedField.paramKeys),
);

const rules = computed(() => {
  if (!props.enforceMinMaxRules) return [];
  return [
    (value) =>
      props.min === null || value >= props.min || `Value must be greater than ${props.min}`,
    (value) => props.max === null || value <= props.max || `Value must be less than ${props.min}`,
  ];
});

function decrement() {
  if (props.min === null || localValue.value > props.min) {
    handleUpdateModelValue(localValue.value - 1);
  }
}

function increment() {
  if (props.max === null || localValue.value < props.max) {
    handleUpdateModelValue(localValue.value + 1);
  }
}
</script>
