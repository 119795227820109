<template>
  <v-progress-linear indeterminate />
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  created() {
    const vm = this;
    this.$store.dispatch('identify', {
      success() {
        vm.$router.replace(vm.$store.getters.defaultLandingPage());
      },
      failure() {
        vm.$router.replace({ path: '/' });
      },
    });
  },
};
</script>
