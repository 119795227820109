<template>
  <v-container>
    <ResourceDialog
      @save="save"
      ref="termsAgreementDialog"
      :cancellable="false"
      :max-width="800"
      :processing="processing"
      :save-button-text="termsAgreement.accept_label"
      :title="termsAgreement.title"
      center-title
    >
      <template #form>
        <v-row>
          <v-col>
            <MarkdownContent
              :content="termsAgreement.content"
              class="focus-very-visible fs-16"
              tabindex="0"
            />
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    MarkdownContent,
    ResourceDialog,
  },

  mixins: [API],

  data() {
    return {
      processing: false,
      termsAgreement: this.$store.state.config.terms_agreement,
    };
  },

  mounted() {
    this.$refs.termsAgreementDialog.open({});
  },

  methods: {
    async save() {
      this.processing = true;
      return this.api.manager.profile.update(
        { terms_agreement_id: this.termsAgreement.id },
        (resp) => {
          const profile = {
            ...this.$store.state.profile,
            terms_agreement_id: resp.data.terms_agreement_id,
          };
          this.$store.commit('setProfile', profile);
          this.$eventBus.$emit('chime', 'Profile saved');
          this.processing = false;
          this.$router.replace({ name: 'Welcome' });
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors.join('. '));
          this.processing = false;
        },
      );
    },
  },
};
</script>
