<template>
  <div>
    <v-container
      v-if="requests"
      class="mxw-1400"
    >
      <v-card
        border
        tile
      >
        <v-card-title role="heading">
          {{ $store.state.pages.Requests.title || $t('Requests') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row class="d-flex align-center mb-0">
            <v-col
              class="d-flex align-center"
              cols="12"
              md="4"
            >
              <v-btn
                @click="$refs.initiateRequestDialog.open()"
                :aria-label="'New request'"
                :loading="processing"
                color="primary"
                prepend-icon="add"
                variant="flat"
              >
                {{ $t('New request') }}
              </v-btn>
            </v-col>

            <v-col
              class="d-flex justify-end"
              cols="12"
              md="8"
            >
              <FilterMenu
                v-if="requestableSchemas.length > 1"
                ref="typeFilter"
                :actions="false"
                :title="filteredSchemaType || 'Type'"
                class="ms-3"
                color="white"
                elevation="0"
                width="400"
                borderless
                left
                outlined
              >
                <template #card>
                  <v-autocomplete
                    v-model="filters.schema_id"
                    @update:model-value="$refs.typeFilter.close()"
                    :items="requestableSchemas"
                    :placeholder="$t('Search by type')"
                    item-title="name"
                    item-value="id"
                    variant="filled"
                    clearable
                    left
                  />
                </template>
              </FilterMenu>

              <FilterMenu
                v-if="providers.length > 0"
                ref="providerFilter"
                :actions="false"
                :title="filteredProviderName || 'Location'"
                classes="ms-3"
                color="white"
                elevation="0"
                width="400"
                borderless
                left
                outlined
              >
                <template #card>
                  <v-autocomplete
                    v-model="filters.provider_id"
                    @update:model-value="$refs.providerFilter.close()"
                    :item-title="
                      (provider) =>
                        (provider.name + ' - ' + provider.address1 + ')').substring(0, 45)
                    "
                    :items="providers"
                    :placeholder="$t('Search by name or address')"
                    item-value="id"
                    variant="filled"
                    clearable
                    left
                  />
                </template>
              </FilterMenu>

              <!-- TODO: Add form status filtering -->
              <!-- TODO: Add created by filtering -->
            </v-col>
          </v-row>

          <v-card
            class="mb-2"
            border
            tile
          >
            <v-card-text class="fs-15">
              <v-row dense>
                <v-col class="d-flex align-center">
                  <template v-if="filterSummary">
                    <v-icon
                      icon="filter_alt"
                      start
                    />
                    <span class="me-1">{{ $t('Filtering by') }}:</span>

                    <span class="fw-600">
                      {{ $t(filterSummary) }}
                    </span>

                    <span>.&nbsp;</span>
                  </template>

                  <span>
                    {{ $t('Displaying first') }} <strong>{{ requests.length }}</strong>
                    {{ $t('results') }}, {{ $t('out of') }} <strong>{{ total }}</strong>
                    {{ $t('total results') }}.
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            class="px-2 py-4"
            border
            tile
          >
            <v-row>
              <!-- TODO: MAKE SORTABLE -->
              <v-col
                class="fw-600 c-black align-center fs-16"
                cols="3"
              >
                {{ $t('Type') }}
              </v-col>

              <v-col
                class="fw-600 c-black align-center fs-16"
                cols="3"
              >
                {{ $t('Location') }}
              </v-col>

              <v-col
                class="fw-600 c-black align-center fs-16"
                cols="1"
              >
                {{ $t('Status') }}
              </v-col>

              <v-col
                class="fw-600 c-black align-center fs-16"
                cols="2"
              >
                {{ $t('Created by') }}
              </v-col>

              <v-col
                class="fw-600 c-black align-center fs-16"
                cols="3"
              >
                {{ $t('Submitted at') }}
              </v-col>
            </v-row>
          </v-card>

          <NullState
            v-if="requests.length === 0"
            @new="$refs.initiateRequestDialog.open()"
            class="my-8"
            new-button-title="New request"
          />

          <div
            v-for="request in requests"
            @click="reviewRequest(request)"
            :key="request.id"
            class="pointer"
          >
            <v-card
              class="px-2 py-4 my-2"
              data-cy="request-row"
              border
              tile
            >
              <v-row class="d-flex align-center fs-16">
                <TableCell
                  :content="getSchemaName(request.relationships.schema.data.id)"
                  cols="3"
                />

                <TableCell
                  :content="getProviderName(request.relationships.provider.data.id)"
                  cols="3"
                />

                <TableCell
                  :content="request.attributes.status ?? undefined"
                  cols="1"
                />

                <TableCell
                  :content="getMemberName(request.relationships.member.data?.id)"
                  cols="2"
                />

                <TableCell
                  :content="request.attributes.submitted_at"
                  cols="3"
                  transform="date-time"
                />
              </v-row>
            </v-card>
          </div>

          <v-pagination
            v-if="pages > 1"
            v-model="page"
            :length="pages"
            :total-visible="8"
            class="mt-4"
          />
        </v-card-text>
      </v-card>

      <VerticalSpacer :min-height="50" />
    </v-container>

    <template v-else>
      <v-progress-linear
        class="mt-6"
        indeterminate
      />
    </template>

    <FormReviewDialog ref="reviewDialog" />

    <FormDialog
      @close="loadRequests()"
      @save="loadRequests()"
      ref="updateRequestDialog"
      :provider-id="updateRequestDialogProviderId"
      :schema-id="updateRequestDialogSchemaId"
      draft
    />

    <ResourceDialog
      @save="continueToForm"
      ref="initiateRequestDialog"
      :fields="initialRequestFields"
      save-button-text="Continue"
      title="Submit a request"
      close-on-save
    />
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import FormDialog from '@/shared/components/form/FormDialog.vue';
import FormReviewDialog from '@/shared/components/form/FormReviewDialog.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import TableCell from '@/shared/components/TableCell.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';
import _ from 'lodash';

const eventBus = useEventBus();
const store = useStore();

const filters = reactive({
  provider_id: null,
  schema_id: null,
});
const includedMembers = ref([]);
const includedProviders = ref([]);
const includedSchemas = ref([]);
const updateRequestDialogProviderId = ref(null);
const updateRequestDialogSchemaId = ref(null);
const members = ref([]);
const page = ref(1);
const pages = ref(1);
const processing = ref(false);
const providers = ref([]);
const requests = ref(null);
const reviewDialog = ref(null);
const total = ref(0);
const updateRequestDialog = ref(null);

const requestableSchemas = computed(() => {
  return _.orderBy(
    store.state.providerRequestableFormSchemas,
    [(schema) => schema.name.toLowerCase()],
    ['asc'],
  );
});

const filterSummary = computed(() => {
  const appliedFilters = [];
  if (filteredSchemaType.value) appliedFilters.push(filteredSchemaType.value);
  if (filteredProviderName.value) appliedFilters.push(filteredProviderName.value);
  return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
});

const filteredProviderName = computed(() => {
  if (!filters.provider_id && providers.value.length === 0) return null;

  return providers.value.find((provider) => provider.id === filters.provider_id)?.name;
});

const filteredSchemaType = computed(() => {
  if (!filters.schema_id && requests.value.length === 0) return null;

  return requestableSchemas.value.find((schema) => schema.id === filters.schema_id)?.name;
});

const initialRequestFields = computed(() => {
  const fields = [
    {
      text: 'Select request type',
      value: 'schema_id',
      items: requestableSchemas.value,
      itemText: 'name',
      itemValue: 'id',
      required: true,
    },
  ];

  if (providers.value.length > 1) {
    fields.push({
      text: 'Select a site',
      value: 'provider_id',
      items: providers.value,
      itemText: 'name',
      itemValue: 'id',
      required: true,
    });
  }

  return fields;
});

watch(
  filters,
  (newValue) => {
    if (newValue && page.value !== 1) page.value = 1;
    void loadRequests();
  },
  { immediate: true, deep: true },
);

watch(
  () => page.value,
  () => void loadRequests(),
);

onMounted(() => {
  processing.value = true;

  void loadProviders();
  void loadMembers();
  void loadRequests();
});

function continueToForm(formData) {
  updateRequestDialogSchemaId.value = formData.schema_id;
  updateRequestDialogProviderId.value = formData.provider_id || providers.value[0].id;

  updateRequestDialog.value.open();
}

function getMemberName(id) {
  if (!id) return;

  return includedMembers.value.find((member) => member.id === id)?.attributes?.name || 'Unknown';
}

function getProviderName(id) {
  if (!id) return;

  return (
    includedProviders.value.find((provider) => provider.id === id)?.attributes?.name || 'Unknown'
  );
}

function getSchemaName(id) {
  return includedSchemas.value.find((schema) => schema.id === id)?.attributes?.name || 'Unknown';
}

async function loadMembers() {
  const response = await Api.manager.member.index({});
  members.value = response.data;
}

async function loadProviders() {
  const response = await Api.manager.provider.index();
  providers.value = response.data;
}

async function loadRequests() {
  const params = {
    'filter[schema]':
      filters.schema_id || requestableSchemas.value.map((schema) => schema.id).join(','),
    // Note: Currently workable, but not leveraging json api
    'page[offset]': (page.value - 1) * (requests.value?.meta?.page_limit || 0),
    include: 'member,provider,schema',
  };
  if (filters.provider_id) params['filter[provider]'] = filters.provider_id;

  const { data } = await Api.manager.form.index(params);

  includedMembers.value = data.included?.filter((include) => include.type === 'member') || [];
  includedProviders.value = data.included?.filter((include) => include.type === 'provider') || [];
  includedSchemas.value = data.included?.filter((include) => include.type === 'schema') || [];

  requests.value = data.data || [];
  pages.value = data.meta.total_pages;
  total.value = data.meta.total_count;
  processing.value = false;
}

function reviewRequest(request) {
  const form = {
    id: request.id,
    name: getSchemaName(request.relationships.schema.data.id),
    schema_id: request.relationships.schema.data.id,
    custom: request.attributes.custom,
    providerId: request.relationships.provider.data.id,
  };

  if (!request.attributes.submitted_at) {
    if (request.relationships.member.data?.id !== store.state.profile.id) {
      eventBus.chime('Cannot update a request in progress created by another user');
      return;
    }

    updateRequestDialogProviderId.value = form.providerId;
    updateRequestDialogSchemaId.value = form.schema_id;

    updateRequestDialog.value.open(form);
  } else {
    reviewDialog.value.open(form);
  }
}
</script>
