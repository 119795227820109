<template>
  <v-card
    :disabled="getAssignmentDisabled()"
    :to="getRoute()"
    class="ma-4"
    border
    flat
    rounded
    tile
  >
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col cols="8">
          <span>{{ $t(assignment.name) }}</span>
        </v-col>
        <v-col
          class="ta-right"
          cols="4"
        >
          <template v-if="getAssignmentDisabled()">
            <v-icon
              color="primary"
              size="28"
            >
              lock_outline
            </v-icon>
          </template>

          <template v-else>
            <v-icon
              v-if="getCompleted()"
              color="green"
              size="28"
            >
              check_circle
            </v-icon>
            <v-badge
              v-else
              :content="$t('Incomplete')"
              class="td-none"
              color="red"
              inline
            />

            <v-icon
              class="ml-4"
              color="grey"
              size="18"
            >
              arrow_forward_ios
            </v-icon>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
const props = defineProps({
  assignment: {
    type: Object,
    default: null,
  },
  assignmentCompletions: {
    type: Array,
    default: null,
  },
  cohortId: {
    type: String,
    default: null,
  },
  cohortLesson: {
    type: Object,
    default: null,
  },
  courseCompletion: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: null,
  },
  lesson: {
    type: Object,
    default: null,
  },
  nextAssignmentIndex: {
    type: Number,
    default: null,
  },
});

const now = Date.now();

function getAssignmentDisabled() {
  if (props.disabled) return true;

  if (props.cohortLesson?.attributes?.start_date) {
    // Convert date to local timestamp right after midnight
    const [year, month, day] = props.cohortLesson.attributes.start_date.split('-').map(Number);
    const startTime = new Date(year, month - 1, day).setHours(0, 0, 0, 0);
    if (now < startTime) return true;
  }

  if (
    props.cohortLesson?.attributes?.end_date &&
    props.cohortLesson?.attributes?.lock_after_end_date
  ) {
    // Convert date to local timestamp right before midnight
    const [year, month, day] = props.cohortLesson.attributes.end_date.split('-').map(Number);
    const endTime = new Date(year, month - 1, day).setHours(23, 59, 59, 999);
    if (now > endTime) return true;
  }

  if (props.index === props.nextAssignmentIndex) return false;

  return !props.assignmentCompletions.find(
    (ac) => ac.assignment_id === props.assignment.id && ac.completed,
  );
}

function getCompleted() {
  return props.assignmentCompletions.find((ac) => ac.assignment_id === props.assignment.id)
    ?.completed;
}

function getRoute() {
  return {
    name: 'LessonShow',
    params: { courseId: props.courseCompletion.course_id, lessonId: props.lesson.id },
    query: {
      assignmentId: props.assignment.id,
      ...(props.cohortId ? { cohortId: props.cohortId } : {}),
    },
  };
}
</script>

<style>
.td-none span:hover {
  text-decoration: none !important;
}
</style>
