<template>
  <div v-if="loaded">
    <v-container class="mxw-800 mx-auto py-12 px-4">
      <template v-if="section === 'finish'">
        <div class="px-4">
          <h1 class="mb-4">
            {{ $t(quizSchema.name) }}
          </h1>

          <div class="d-flex align-start mb-4">
            <div>
              {{ $t(gradeScaleDisplayText) }}
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="mx-auto mb-6 ta-center">
          <div class="fs-22 fw-500 mb-0">
            {{ $t(quizSchema.name) }}
          </div>

          <div class="fs-16 c-light-black">
            <span class="me-1">
              {{ $t('Question') }}
            </span>

            <span>
              {{ stepCount >= stepTotal ? stepTotal - 1 : stepCount }} / {{ stepTotal - 1 }}
            </span>
          </div>
        </div>

        <div
          ref="progress"
          :aria-label="$t('Progress indicator - question') + ' ' + stepCount"
          class="px-4 focus-invisible"
          tabindex="0"
          aria-live
        >
          <v-progress-linear
            v-model="progress"
            :indeterminate="!loaded"
            class="focus-invisible mb-4"
            color="primary"
          />
        </div>
      </template>

      <div class="px-4">
        <QuestionSet
          v-model="quiz"
          @back="backFromQuestion($event)"
          @next="forwardFromQuestion($event)"
          :processing="processing"
          :questions="validQuestions"
          :schema="quizSchema.definition"
          :section="section"
          :transition-name="transitionName"
          color="white"
          key-name="question"
          border
        />

        <template v-if="section == 'finish'">
          <QuestionSet
            v-model="quiz"
            :questions="validQuestions"
            :schema="quizSchema.definition"
            color="white"
            key-name="question"
            border
            condensed
            dense
            display-answer
            expanded
            hide-actions
            readonly
            show-passing
          />

          <v-row>
            <v-col class="d-flex justify-space-between">
              <v-btn
                v-if="!quiz.passing"
                @click="retake"
                color="primary"
                size="x-large"
              >
                <span>
                  {{ $t('Retake quiz') }}
                </span>
              </v-btn>

              <v-btn
                :to="{ name: 'CourseShow', params: { courseId: lessonCompletion.course_id } }"
                color="primary"
                size="x-large"
              >
                <span>
                  {{ $t('Return to course') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </div>
    </v-container>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import Questionable from '@/shared/mixins/questionable';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import Stepper from '@/shared/mixins/stepper';

export default {
  compatConfig: { MODE: 2 },

  components: {
    QuestionSet,
  },

  mixins: [API, Questionable, Stepper],

  data() {
    return {
      confirmed: false,
      gradeScaleDisplayText: null,
      lesson: null,
      lessonCompletion: null,
      quiz: null,
      quizSchema: null,
      processing: false,
      section: null,
    };
  },

  computed: {
    loaded() {
      return (
        this.lessonCompletion &&
        this.quizSchema &&
        this.quiz &&
        this.questions &&
        this.questions.length > 0
      );
    },
  },

  watch: {
    loaded: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.stepCount = parseInt(this.$route.query.step, 10);
          if (!this.stepTotal) await this.validate();

          this.recalculateStepTotal();
          if (this.$route.query.section) {
            setTimeout(() => {
              this.section = this.$route.query.section;
              this.$refs.progress.focus();
              this.progress = (this.stepCount / this.stepTotal) * 100;
              this.$forceUpdate();
            }, 500);
          } else {
            this.$router.push({ query: { section: 'question-0', step: 1 } });
          }
        }
      },
    },

    '$route.query.section': {
      async handler(newValue) {
        this.section = null;
        this.stepCount = parseInt(this.$route.query.step, 10);
        if (!this.stepTotal) {
          await this.validate();
        }
        this.recalculateStepTotal();

        setTimeout(() => {
          if (newValue) {
            this.section = newValue;
            this.$refs.progress.focus();
          } else {
            this.section = 'question-0';
          }
          this.progress = (this.stepCount / this.stepTotal) * 100;
          this.$forceUpdate();
        }, 600);
      },
    },
  },

  created() {
    this.ownerId = this.$route.params.quizSchemaId;
    this.ownerType = 'Schema';
    this.load();
  },

  methods: {
    backFromFinish() {
      this.processing = true;
      this.step_back(`question-${this.validQuestions.length - 1}`);
    },

    async backFromQuestion(index) {
      this.processing = true;
      await this.saveQuiz();
      await this.validate();
      this.recalculateStepTotal();

      if (index - 1 < 0) {
        this.$router.push({
          name: 'CourseShow',
          params: { courseId: this.lessonCompletion.course_id },
        });
      } else {
        this.step_back(`question-${index - 1}`);
      }
    },

    createQuiz() {
      this.api.manager.quiz.create(
        {
          lesson_completion_id: this.$route.params.lessonCompletionId,
          schema_id: this.$route.params.quizSchemaId,
        },
        (response) => {
          this.quiz = response.data;
        },
      );
    },

    async finish() {
      this.quiz.submitted = true;
      await this.saveQuiz();
      this.gradeScaleDisplayText = this.getGradeScaleDisplayText();
      this.step_forward('finish');
    },

    async forwardFromQuestion(index) {
      this.processing = true;

      if (index + 1 >= this.validQuestions.length) {
        this.finish();
      } else {
        this.quiz.submitted = false;
        await this.saveQuiz();
        await this.validate();
        this.recalculateStepTotal();
        this.step_forward(`question-${index + 1}`);
      }
    },

    getGradeScaleDisplayText() {
      const sortedGradeScales = this.lesson.grade_scale.sort((gradeScale1, gradeScale2) =>
        gradeScale1.min_score > gradeScale2.min_score ? -1 : 1,
      );

      const grade =
        sortedGradeScales.find((gradeScale) => this.quiz.score >= gradeScale.min_score) ||
        sortedGradeScales.at(-1);

      return grade.display_text;
    },

    async load() {
      this.loadQuizSchema();
      this.loadQuestions();
      await this.loadLessonCompletion();
      await this.loadLesson();
      this.createQuiz();
    },

    async loadLesson() {
      const response = await this.api.public_api.organization.lesson.get(
        this.lessonCompletion.lesson_id,
      );
      this.lesson = response.data;
    },

    async loadLessonCompletion() {
      const response = await this.api.lesson_completion.get(this.$route.params.lessonCompletionId);
      this.lessonCompletion = response.data;
    },

    loadQuizSchema() {
      this.api.public_api.organization.schema.get(this.$route.params.quizSchemaId, (response) => {
        this.quizSchema = response.data;
      });
    },

    recalculateStepTotal() {
      this.stepTotal = this.validQuestions.length + 1;
      this.progress = (this.stepCount / this.stepTotal) * 100;
    },

    retake() {
      this.quiz = null;
      this.$router.push({
        name: 'LessonQuiz',
        params: {
          lessonCompletionId: this.lessonCompletion.id,
          quizSchemaId: this.$route.params.quizSchemaId,
        },
        query: { retake: true },
      });
      this.stepCount = 0;
      this.load();
    },

    async saveQuiz() {
      const { data } = await this.api.manager.quiz.promiseUpdate(this.quiz.id, this.quiz);
      this.quiz = data;
    },
  },
};
</script>
