<template>
  <div
    v-if="tour"
    class="h-100pc w-100pc bg-super-light-blue px-4 oy-scroll my-16"
  >
    <div class="mxw-600 mx-auto p-relative pa-8 bg-white elevation-1">
      <div class="mb-6 mxw-150 mx-auto">
        <img
          class="w-100pc"
          src="https://res.cloudinary.com/bridgecare/image/upload/v1627844746/media/Checkmark_mbbvjr.svg"
        />
      </div>

      <div
        v-t="'Tour confirmed!'"
        class="fw-600 fs-32 mb-4 ta-center"
      />

      <div class="mxw-600 mx-auto ta-left">
        <div
          v-t="
            'Your tour time has been confirmed. We will send you a reminder the morning of your tour but we encourage you to add it to your calendar.'
          "
          class="fs-16"
        />
      </div>

      <v-divider class="my-6" />

      <div class="mb-4">
        <p
          v-t="'Parent contact information'"
          class="fs-18 fw-600 mb-2"
        />
        <div
          v-text="tour.meta.member.name"
          class="fs-16 mb-1"
        />
        <div
          v-text="tour.meta.member.email"
          class="fs-16"
        />
      </div>

      <div class="mb-4">
        <p
          v-t="'Children information'"
          class="fs-18 fw-600 mb-2"
        />
        <div
          v-for="child in tour.meta.children"
          :key="child.name"
          class="fs-16 mb-1"
        >
          {{ child.name }}, born {{ child.dob }}
        </div>
      </div>

      <div class="mb-4">
        <p
          v-t="'Preferred hours of care'"
          class="fs-18 fw-600 mb-2"
        />
        <div
          v-text="tour.hours_message"
          class="fs-16 mb-1"
        />
      </div>

      <div class="mb-4">
        <p
          v-t="'Message from the family'"
          class="fs-18 fw-600 mb-2"
        />
        <div
          v-text="tour.other_message"
          class="fs-16 mb-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/manager/services/bright_finder';

export default {
  compatConfig: { MODE: 2 },

  data() {
    return {
      tour: null,
    };
  },

  created() {
    if (this.$route.query.action === 'confirm') {
      this.confirm();
    } else {
      this.load();
    }
  },

  methods: {
    confirm() {
      Api.manager.tour_reservation.update(
        this.$route.params.tourId,
        { confirmed: true },
        (response) => {
          this.tour = response.data;
        },
      );
    },

    load() {
      Api.manager.tour_reservation.get(this.$route.params.tourId, (response) => {
        this.tour = response.data;
      });
    },
  },
};
</script>
