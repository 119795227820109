<template>
  <v-container class="mxw-1000 pt-8">
    <v-row>
      <v-col>
        <h1>{{ $t('Welcome') }}, {{ $store.state.profile.first_name }}</h1>
      </v-col>
    </v-row>

    <v-row v-if="accessRequestsEnabled">
      <v-col>
        <v-card
          border
          flat
          tile
        >
          <v-card-title>
            <h1 class="fs-24">
              {{ $t('Request access') }}
            </h1>
          </v-card-title>

          <v-divider class="mb-3" />

          <v-card-text>
            <p class="mb-8">
              <!-- eslint-disable-next-line max-len vue/max-attributes-per-line -->
              {{
                $t(
                  "You've successfully created an account, but you currently don't have permission to view any locations. To access a specific location, please use our search feature to find it and then request access.",
                )
              }}
            </p>

            <template v-if="accessRequest">
              <v-alert
                class="ta-left"
                color="primary"
                data-cy="access-request-status"
                type="info"
              >
                <v-row>
                  <v-col>
                    <p class="mb-4">
                      <!-- eslint-disable-next-line max-len vue/max-attributes-per-line -->
                      {{
                        $t(
                          'Your request to join the following location has been submitted and will be confirmed shortly. Stay tuned!',
                        )
                      }}
                    </p>
                    <p
                      v-if="accessRequest.provider_name"
                      v-text="accessRequest.provider_name"
                      class="my-3 fw-800 fs-24 underlined"
                    />
                  </v-col>
                </v-row>
                <v-row
                  class="ta-right"
                  dense
                >
                  <v-col>
                    <v-btn
                      @click="destroyAccessRequest"
                      data-cy="access-request-destroy-button"
                      variant="outlined"
                    >
                      {{ $t('Cancel request') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </template>

            <template v-else>
              <v-row class="mb-4">
                <v-col>
                  <v-autocomplete
                    v-model="providerId"
                    @update:search="searchProviders"
                    @vue:mounted="searchProviders('')"
                    :aria-label="$t('Select from one of the following providers')"
                    :items="providers"
                    data-cy="provider-search"
                    item-title="name"
                    item-value="id"
                    variant="filled"
                    auto-select-first
                    bottom
                    hide-details
                  >
                    <template #item="{ item, props }">
                      <v-list-item
                        v-bind="props"
                        :title="getProviderDisplayText(item.raw)"
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-btn
                @click="createAccessRequest"
                :disabled="!providerId"
                :ripple="false"
                class="mb-8"
                color="primary"
                data-cy="access-request-button"
                size="x-large"
                >{{ $t('Request to join location') }}</v-btn
              >
              <v-row>
                <v-col>
                  <p>
                    {{ $t(CONTACT_SUPPORT_MESSAGE) }}
                  </p>

                  <p class="mx-auto">
                    <a
                      v-text="$store.state.site.provider_support_email"
                      :href="'mailto:' + $store.state.site.provider_support_email"
                    />
                  </p>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="onboardingFormSchemaId">
      <v-col>
        <v-card
          border
          flat
          tile
        >
          <v-card-title>
            <v-row dense>
              <v-col>
                <h1 class="fs-24">
                  {{ $t(getThankYouMessage()) }}
                </h1>
              </v-col>

              <v-col
                v-if="onboardingFormSubmitted"
                class="d-flex justify-end"
              >
                <v-icon
                  class="me-2"
                  color="green"
                  size="28"
                >
                  check
                </v-icon>
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider class="mb-3" />

          <v-card-text>
            <template v-if="onboardingFormSubmitted">
              <p>
                <span>{{ $t(ONBOARDING_FORM_SUBMITTED_MESSAGE) }}</span>
              </p>
            </template>
            <template v-else>
              <p class="mb-8">
                {{ $t(ACCESS_LOCATION_MESSAGE) }}
              </p>
              <v-btn
                :to="{ name: 'OnboardingForm', params: { schemaId: onboardingFormSchemaId } }"
                color="primary"
                size="x-large"
              >
                <span>{{ $t('Start application') }}</span>
                <v-icon class="ms-2"> east </v-icon>
              </v-btn>
            </template>
          </v-card-text>
        </v-card>

        <template v-if="!(accessRequestsEnabled || onboardingFormSchemaId)">
          <p class="mb-8">
            {{ $t(CREATE_SUCCESS_MESSAGE) }}
          </p>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const ACCESS_LOCATION_MESSAGE =
  'To get access to the appropriate locations, please fill out the ' +
  'onboarding application. Once you do so, a member of the administrator team will be in touch.';
const CONTACT_SUPPORT_MESSAGE =
  "If you can't locate the specific place you're looking for," +
  " don't hesitate to contact our support team for assistance at:";
const CREATE_SUCCESS_MESSAGE =
  'You have successfully created an account, but you currently do not have permission ' +
  'to view any locations. To access a specific location, please contact your administrator.';
const ONBOARDING_FORM_SUBMITTED_MESSAGE =
  'Once you are approved as a designee, you will receive an email. ' +
  'If you have any questions in the meantime, please email support@getbridgecare.com.';

const accessRequest = ref(null);
const eventBus = useEventBus();
const onboardingForm = ref(null);
const onboardingFormSchemaId = ref(null);
const providerId = ref(null);
const providers = ref([]);
const processing = ref(false);
const queryCount = ref(0);
const store = useStore();

onMounted(created);

async function created() {
  await loadOnboardingForm();
  if (accessRequestsEnabled.value) {
    await getAccessRequest();
  }
}
const accessRequestsEnabled = computed(() => {
  return store.state.config.enable_provider_access_request;
});

const onboardingFormSubmitted = computed(() => {
  return !!onboardingForm.value?.attributes?.submitted_at;
});

function searchProviders(query) {
  queryCount.value += 1;
  const currentQueryCount = queryCount.value;
  Api.public_api.organization.provider.index({ query, page_size: 200 }, (resp) => {
    if (queryCount.value === currentQueryCount) {
      providers.value = resp.data.filter((provider) => provider.business_id !== null);
    }
  });
}

async function createAccessRequest() {
  if (!accessRequestsEnabled.value) return;
  if (accessRequest.value) {
    eventBus.error('You may not create more than one request for a location/business');
    return;
  }

  processing.value = true;
  const response = await Api.manager.access_request.create({ provider_id: providerId.value });

  processing.value = false;
  accessRequest.value = response?.data;
}

async function destroyAccessRequest() {
  processing.value = true;
  await Api.manager.access_request.destroy(accessRequest.value.id);
  providerId.value = null;
  processing.value = false;
  accessRequest.value = null;
}

// NOTE: This all operates under the pre-existing understanding of a single access request per user
//       and so it grabs the first request (while assuming that there is only one -- which is VERY funky)
//       and if there is one it doesn't allow them to request access any more (allowing multiple requests
//       would/could create a situation where they have simultaneous requests to multiple businesses that
//       if approved would create a bad state.... this should probably all be locked down both
//       frontend and backend
async function getAccessRequest() {
  const resp = await Api.manager.access_request.index();
  accessRequest.value = resp?.data?.length > 0 ? resp.data[0] : null;
}

function getProviderDisplayText(provider) {
  if (!provider.location) return provider.name;
  return `${provider.name} - ${provider.location}`;
}

function getThankYouMessage() {
  if (onboardingFormSubmitted.value) return 'Thanks for submitting your application!';
  return 'Thanks for setting up your account!';
}

async function loadOnboardingForm() {
  onboardingFormSchemaId.value = store.state.config.provider_onboarding_form_schema_id;

  if (!onboardingFormSchemaId.value) return false;

  const forms =
    (await Api.manager.form.index({ 'filter[schema]': onboardingFormSchemaId.value })).data.data ||
    [];
  onboardingForm.value = forms[forms.length - 1];

  return true;
}
</script>
