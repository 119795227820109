<template>
  <v-container
    v-if="assessments"
    class="px-0 pt-0"
    fluid
  >
    <PageHero
      :image="heroImageUrl"
      :subtitle="heroSubtitle"
      :title="heroTitle"
    />

    <v-container
      v-if="assessments.length > 0"
      class="mxw-1000 mb-8"
    >
      <v-row>
        <v-col
          v-for="assessment in assessments"
          :key="assessment.id"
          cols="12"
          md="6"
        >
          <AssessmentCard :assessment="assessment" />
        </v-col>
      </v-row>
    </v-container>

    <v-row
      v-if="assessments.length == 0"
      class="justify-center px-2 mb-4"
    >
      <v-col cols="3">
        <v-img :src="$a.url('icons.nullstate')" />
      </v-col>
    </v-row>

    <CreateAssessmentDialog />

    <v-pagination
      v-show="pages > 1"
      v-model="page"
      :length="pages"
      :total-visible="8"
      class="mt-4"
    />

    <VerticalSpacer :min-height="75" />
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import AssessmentCard from '@/manager/components/AssessmentCard.vue';
import CreateAssessmentDialog from '@/manager/components/CreateAssessmentDialog.vue';
import PageHero from '@/shared/components/content/PageHero.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

// TODO: Remove once long term strategy for page configuration is done
// eslint-disable-next-line max-len
const DEFAULT_HERO_IMAGE_URL =
  'https://res.cloudinary.com/bridgecare/image/upload/v1628634744/media/Hands_-_Medal_1_zxk25f.svg';
// eslint-disable-next-line max-len
const DEFAULT_HERO_SUBTITLE =
  'Use the request button below to apply for a QRIS model (Enhancement, Guided Support, or Assessment). Ensure your request is based on your license type. For example, if you are a Family Provider, then only fill out applications labeled "Family Home". Please only fill out the application for the model in which you are ready to participate within 30 days of approval.';
const DEFAULT_HERO_TITLE = 'QRIS';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AssessmentCard,
    CreateAssessmentDialog,
    PageHero,
    VerticalSpacer,
  },

  mixins: [API],

  data() {
    return {
      assessments: [],
      heroImageUrl: this.$store.state.pages.Assessments.hero_image_url || DEFAULT_HERO_IMAGE_URL,
      heroSubtitle: this.$store.state.pages.Assessments.hero_subtitle || DEFAULT_HERO_SUBTITLE,
      heroTitle: this.$store.state.pages.Assessments.hero_title || DEFAULT_HERO_TITLE,
      page: 1,
      pages: null,
    };
  },

  created() {
    this.load();
  },

  methods: {
    async load() {
      const providers = await this.loadProviders();
      if (providers) {
        this.api.manager.assessment.index({}, (resp) => {
          this.assessments = resp.data.map((assessment) => ({
            ...assessment,
            provider: providers.find((provider) => provider.id === assessment.provider_id),
          }));
          this.loaded = true;
        });
      } else {
        this.loaded = true;
      }
    },

    async loadProviders() {
      const { data } = await this.api.manager.provider.index();
      return data;
    },
  },
};
</script>
