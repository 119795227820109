<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    :key="member.id"
    border
    tile
  >
    <v-card-title>
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col class="d-flex align-center">
          <v-avatar
            v-if="member.first_name"
            class="fs-18 c-white me-4"
            color="primary"
            size="40"
          >
            {{ [member.first_name[0], member.last_name[0]].join('').toUpperCase() }}
          </v-avatar>

          <div class="w-100pc">
            <div
              v-text="
                member.first_name
                  ? [member.first_name, member.last_name].join(' ')
                  : $t('Unknown name')
              "
              class="fs-16 fw-600 mb-0 w-100pc word-break-word"
            />

            <div
              v-text="member.email"
              class="fs-14 c-light-black w-100pc word-break-word"
            />
          </div>
        </v-col>

        <v-col class="d-flex justify-end">
          <template v-if="!readOnly">
            <ActionMenu
              @click:action:delete="destroy"
              :items="memberActionItems"
              button-icon="more_vert"
              left
            />
          </template>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <v-row>
        <LabeledTextfield
          v-model="member.first_name"
          @input="changed = true"
          :hard-lock="readOnly"
          cols="6"
          message="First name"
        />

        <LabeledTextfield
          v-model="member.last_name"
          @input="changed = true"
          :hard-lock="readOnly"
          cols="6"
          message="Last name"
        />

        <LabeledTextfield
          v-model="member.email"
          @input="changed = true"
          :hard-lock="readOnly"
          message="Email"
        />

        <LabeledSimpleSelect
          v-model="member.business_role"
          @input="changed = true"
          :hard-lock="readOnly"
          :items="['Admin', 'Member']"
          message="Role"
        />

        <LabeledAutocomplete
          v-model="member.provider_ids"
          @input="changed = true"
          :hard-lock="readOnly"
          :items="providers"
          item-title="name"
          item-value="id"
          message="Locations"
          placeholder="All locations"
          chips
          deletable-chips
          multiple
        />

        <LabeledSwitch
          v-model="member.business_messages_access"
          @input="changed = true"
          :hard-lock="readOnly"
          subtitle="Can access instant messages and respond to messages from organization staff and families."
          title="Messaging"
        />
      </v-row>
    </v-card-text>

    <template v-if="changed == true">
      <v-divider class="mb-4" />

      <v-card-actions>
        <v-btn
          @click="save"
          :loading="processing"
          color="primary"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </template>

    <ConfirmDialog ref="confirmDialog" />
  </v-card>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/manager/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const store = useStore();

const props = defineProps({
  member: {
    type: Object,
    default: null,
  },
  providers: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(['change']);

const changed = ref(false);
const confirmDialog = ref(null);
const memberActionItems = ref([{ title: 'Delete', avatar: 'delete', event: 'delete' }]);
const processing = ref(false);

const readOnly = computed(() => {
  return store.state.profile.business_role !== 'Admin';
});

async function destroy() {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to remove this member? This cannot be undone.',
  );
  if (!confirm) return;

  await Api.manager.member.destroy(props.member.id);
  emit('change');
}

async function save() {
  processing.value = true;

  await Api.manager.member.update(props.member.id, props.member);

  eventBus.chime('Saved');
  processing.value = false;
  changed.value = false;
}
</script>
