<template>
  <v-card
    class="py-3"
    border
    flat
  >
    <v-row dense>
      <v-col
        class="position-relative"
        cols="12"
      >
        <v-img
          :src="course.image_url"
          height="250"
        />
        <v-chip-group class="bottom-0 position-absolute px-4">
          <v-chip
            v-for="label in course.labels || []"
            :key="label.id"
            class="bg-grey-lighten-5"
          >
            {{ label.name }}
          </v-chip>
        </v-chip-group>
      </v-col>

      <v-col cols="12">
        <div class="px-4 pt-3">
          <div
            class="fs-22 fw-600 mb-2 lh-24 h-50"
            style="overflow: hidden"
          >
            <div v-text="course.name" />
          </div>

          <div class="fs-16 fw-500 mb-3 h-100 line-clamp-3">
            <span v-text="$t(course.description || 'No description')" />
          </div>

          <div class="d-flex align-start mb-4">
            <div class="me-3">
              <v-progress-circular
                :model-value="courseCompletion.progress"
                color="primary"
                rotate="-90"
                size="70"
                width="10"
              >
                <div class="fs-16 fw-600">{{ courseCompletion.progress }}%</div>
              </v-progress-circular>
            </div>
            <div>
              <div class="fs-20 fw-500 mb-1">
                {{ courseCompletion.count_of_lesson_completions }} / {{ course.count_of_lessons }}
                {{ $t('Lessons completed') }}
              </div>
              <div class="fs-22 d-flex align-center">
                <template v-if="courseCompletion.completed_at">
                  <v-icon
                    class="me-2"
                    color="primary"
                    size="28"
                  >
                    verified
                  </v-icon>
                  <span class="fw-600 fs-18">{{ $t('Completed') }}</span>
                </template>
                <template v-else>
                  <v-icon
                    class="me-2"
                    color="primary"
                    size="28"
                  >
                    radio_button_unchecked
                  </v-icon>
                  <span
                    v-t="courseCompletion.progress > 0 ? 'In progress' : 'Not started'"
                    class="fs-18"
                  />
                </template>
              </div>
            </div>
          </div>

          <v-btn
            :ripple="false"
            :to="{ name: 'CourseShow', params: { courseId: course.id } }"
            color="primary"
            size="x-large"
            block
          >
            <span>
              {{
                courseCompletion.progress == 0
                  ? 'Get started'
                  : courseCompletion.progress == 100
                    ? 'Download certificate'
                    : 'Continue'
              }}
            </span>
            <v-icon end> east </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
const emit = defineEmits(['getCourseLabels']);
const props = defineProps({
  course: {
    type: Object,
    default: null,
  },
  courseCompletion: {
    type: Object,
    default() {
      return {
        progress: 0,
        count_of_lesson_completions: 0,
      };
    },
  },
});

onMounted(() => {
  emit('getCourseLabels', props.course);
});
</script>

<style>
.line-clamp-3 {
  line-height: 1.5;
  max-height: calc(1.5em * 3);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
