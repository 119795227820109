<template>
  <div class="pa-4">
    <router-link
      :to="backRoute"
      class="td-none"
    >
      <span class="fs-16 c-black">{{ $t(course?.name) }}</span>
    </router-link>

    <div class="mt-1 fs-20 fw-600">
      {{ $t(lesson?.name) }}
    </div>

    <v-row
      class="mt-3"
      dense
    >
      <v-col cols="12">
        <div class="fs-12 fw-600">{{ lessonProgress }}% {{ $t('Completed') }}</div>
      </v-col>

      <v-col cols="12">
        <v-progress-linear
          v-model="lessonProgress"
          color="primary"
          height="6"
          rounded
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
const props = defineProps({
  assignmentCompletions: {
    type: Array,
    default: () => [],
  },
  assignments: {
    type: Array,
    default: null,
  },
  course: {
    type: Object,
    default: null,
  },
  lesson: {
    type: Object,
    default: null,
  },
});

const completedAssignments = computed(() => {
  return props.assignmentCompletions.filter((completion) => completion.completed).length;
});

const lessonProgress = computed(() => {
  if (!props.assignmentCompletions || !props.assignments) return 0;

  return parseInt((completedAssignments.value / props.assignments.length) * 100, 10);
});

const backRoute = computed(() => {
  return {
    name: 'CourseShow',
    params: { courseId: props.course?.id },
  };
});
</script>
