<template>
  <v-alert
    v-if="provider"
    border="start"
    border-color="primary"
    color="white"
    icon="add"
    type="info"
    prominent
  >
    <div>
      <div class="fs-22 fw-500">{{ request.provider_name }}</div>
      <div class="fs-16 fw-500">{{ getProviderAddress() }}</div>
      <v-alert
        v-if="request.request_reason"
        color="white"
        icon="info"
      >
        <div>
          <p>{{ request.request_reason }}</p>
        </div>
      </v-alert>
    </div>
    <template v-slot:append>
      <v-chip
        size="large"
        variant="outlined"
        label
      >
        {{ $t('Pending') }}
      </v-chip>
      <v-btn
        @click="destroyRequest()"
        class="ms-3"
        icon="delete"
        variant="plain"
      />
    </template>
  </v-alert>
</template>

<script setup>
import api from '@/manager/services/bright_finder.js';
import { formatAddress } from '@/shared/services/address';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  request: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['destroyed']);

const provider = ref(null);

onMounted(() => {
  loadProvider();
});

async function destroyRequest() {
  if (confirm('Are you sure you want to cancel this request?')) {
    await api.manager.ownership_request.destroy(props.request.id);
    emit('destroyed');
    eventBus.chime('Request canceled');
  }
}

function loadProvider() {
  api.public_api.provider.get(props.request.provider_id, (resp) => (provider.value = resp.data));
}

function getProviderAddress() {
  if (!provider.value) return null;
  return formatAddress(provider.value.address1, provider.value.city);
}
</script>
