<template>
  <div class="bg-white w-100pc h-100pc">
    <v-container
      v-if="loaded"
      class="mxw-800 mx-auto py-12"
    >
      <div
        ref="progress"
        :aria-label="$t('Progress indicator - question') + ' ' + stepCount"
        class="px-4 focus-invisible"
        role="progressbar"
        tabindex="0"
        aria-live
      >
        <v-progress-linear
          v-model="progress"
          :indeterminate="!loaded"
          class="focus-invisible mb-4"
          color="primary"
        />
      </div>

      <div class="px-4">
        <QuestionSet
          v-model="form"
          @back="backFromQuestion($event)"
          @next="forwardFromQuestion($event)"
          :processing="processing"
          :questions="validQuestions"
          :readonly="form.locked"
          :schema="formSchema.definition"
          :section="section"
          :transition-name="transitionName"
          key-name="question"
        />

        <transition-group :name="transitionName">
          <FormQuestion
            v-show="section == 'finish'"
            @back="backFromFinish()"
            @finish="finish"
            key="finish"
            :finish-text="'Done'"
            :forwardable="false"
            :processing="processing"
            section="Finish"
            title="You're all done!"
            finishable
          >
            <p v-t="'Thank you for completing this form.'" />
          </FormQuestion>
        </transition-group>
      </div>
    </v-container>
  </div>
</template>

<script>
import Api from '@/manager/services/bright_finder';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import Questionable from '@/shared/mixins/questionable';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import Stepper from '@/shared/mixins/stepper';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FormQuestion,
    QuestionSet,
  },

  mixins: [Questionable, Stepper],

  data() {
    return {
      form: null,
      formSchema: null,
      processing: false,
      section: null,
    };
  },

  computed: {
    loaded() {
      return this.form && this.formSchema && this.questions && this.questions.length > 0;
    },
  },

  watch: {
    loaded: {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.stepCount = parseInt(this.$route.query.step, 10);
          if (!this.stepTotal) {
            await this.validate();
          }
          this.recalculateStepTotal();
          if (this.$route.query.section) {
            setTimeout(() => {
              this.section = this.$route.query.section;
              this.$refs.progress.focus();
              this.progress = (this.stepCount / this.stepTotal) * 100;
              this.$forceUpdate();
            }, 500);
          } else {
            this.$router.push({
              query: {
                section: 'question-0',
                step: 1,
                providerId: this.$route.query.providerId,
              },
            });
          }
        }
      },
    },

    '$route.query.section': {
      async handler(newVal) {
        this.section = null;
        this.stepCount = parseInt(this.$route.query.step, 10);
        if (!this.stepTotal) await this.validate();
        this.recalculateStepTotal();

        setTimeout(() => {
          if (newVal) {
            this.section = newVal;
            this.$refs.progress.focus();
          } else {
            this.section = 'question-0';
          }
          this.progress = (this.stepCount / this.stepTotal) * 100;
          this.$forceUpdate();
        }, 600);
      },
    },
  },

  created() {
    this.load();
  },

  methods: {
    backFromFinish() {
      this.processing = true;
      this.step_back(`question-${this.validQuestions.length - 1}`);
    },

    async backFromQuestion(index) {
      this.processing = true;
      if (!this.form.locked) {
        await this.saveForm();
        await this.validate();
      }
      this.recalculateStepTotal();

      if (index - 1 < 0) {
        this.$router.push({ name: 'ProviderIndex' });
      } else {
        this.step_back(`question-${index - 1}`);
      }
    },

    async finish() {
      this.processing = true;
      if (!this.form.locked) {
        this.form.submitted = true;
        await Api.manager.provider.form.promiseUpdate(
          this.form.provider_id,
          this.$route.params.formId,
          this.form,
        );
      }
      this.$router.push({ name: 'ProviderIndex' });
    },

    async forwardFromQuestion(index) {
      this.processing = true;
      if (!this.form.locked) {
        await this.saveForm();
        await this.validate();
      }
      this.recalculateStepTotal();

      if (index + 1 >= this.validQuestions.length) {
        this.step_forward('finish', { providerId: this.$route.query.providerId });
      } else {
        this.step_forward(`question-${index + 1}`, { providerId: this.$route.query.providerId });
      }
    },

    load() {
      this.loadForm();
    },

    loadForm() {
      Api.manager.provider.form.get(
        this.$route.query.providerId,
        this.$route.params.formId,
        (response) => {
          this.form = response.data;
          this.formSchema = this.$store.state.schemas[this.form.schema_id];
          this.ownerId = this.formSchema.id;
          this.ownerType = 'Schema';
          this.loadQuestions(() => {
            this.recalculateStepTotal();
          });
        },
      );
    },

    recalculateStepTotal() {
      this.stepTotal = this.validQuestions.length + this.verificationQuestions.length + 1;
      this.progress = (this.stepCount / this.stepTotal) * 100;
    },

    async saveForm() {
      const { data } = await Api.manager.provider.form.promiseUpdate(
        this.form.provider_id,
        this.form.id,
        this.form,
      );
      this.form = data;
    },
  },
};
</script>
