<template>
  <div class="bg-white w-100pc h-100pc">
    <v-container
      v-if="loaded"
      class="mxw-800 mx-auto py-12"
    >
      <div
        ref="progress"
        :aria-label="$t('Progress indicator - question') + ' ' + stepCount"
        class="px-4 focus-invisible"
        role="progressbar"
        tabindex="0"
        aria-live
      >
        <v-progress-linear
          v-model="progress"
          :indeterminate="!loaded"
          class="focus-invisible mb-4"
          color="primary"
        />
      </div>

      <div class="px-4">
        <template v-if="schema?.data_type == 'Provider'">
          <QuestionSet
            v-model="provider"
            @back="backFromQuestion($event)"
            @next="forwardFromQuestion($event)"
            :processing="processing"
            :questions="validQuestions"
            :readonly="form.locked"
            :schema="schema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="question"
          />
        </template>

        <template v-else>
          <QuestionSet
            v-model="form"
            @back="backFromQuestion($event)"
            @next="forwardFromQuestion($event)"
            :processing="processing"
            :questions="validQuestions"
            :readonly="form.locked"
            :schema="schema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="question"
          />
        </template>

        <transition-group :name="transitionName">
          <div
            v-for="(question, index) in validVerificationQuestions"
            v-show="section == ['verification', index].join('-')"
            :key="['verification', index].join('-')"
          >
            <FormQuestion
              @back="backFromVerificationQuestion(index)"
              @next="forwardFromVerificationQuestion(index)"
              :schema="{}"
              :subtitle="question.verification_subtitle"
              :title="question.verification_title"
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :ref="['uploader', question.id].join('')"
                :owner="{
                  type: 'Form',
                  id: form.id,
                  tag: question.id,
                  tags: [form.id, question.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                class="mb-6"
              />
            </FormQuestion>
          </div>
        </transition-group>

        <transition-group :name="transitionName">
          <FormQuestion
            v-show="section == 'finish'"
            @back="backFromFinish()"
            @finish="finish"
            key="finish"
            :finish-text="'Done'"
            :forwardable="false"
            :processing="processing"
            section="Finish"
            title="You're all done!"
            finishable
          >
            <p
              v-t="
                'Thanks for completing your application. You\'ll receive an email shortly to confirm that it has been received.'
              "
            />
          </FormQuestion>
        </transition-group>
      </div>
    </v-container>
  </div>
</template>

<script>
import Api from '@/manager/services/bright_finder';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import Questionable from '@/shared/mixins/questionable';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import Stepper from '@/shared/mixins/stepper';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FormQuestion,
    QuestionSet,
  },

  mixins: [Questionable, Stepper],

  data() {
    return {
      attachments: [],
      confirmed: false,
      form: null,
      formSchema: null,
      processing: false,
      provider: null,
      schema: null,
      section: null,
    };
  },

  computed: {
    loaded() {
      return (
        this.form && this.formSchema && this.provider && this.questions && this.questions.length > 0
      );
    },
  },

  watch: {
    loaded: {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.stepCount = parseInt(this.$route.query.step, 10);
          if (!this.stepTotal) {
            await this.validate();
          }
          this.recalculateStepTotal();
          if (this.$route.query.section) {
            setTimeout(() => {
              this.section = this.$route.query.section;
              this.$refs.progress.focus();
              this.progress = (this.stepCount / this.stepTotal) * 100;
              this.$forceUpdate();
            }, 500);
          } else {
            this.$router.push({ query: { section: 'question-0', step: 1 } });
          }
        }
      },
    },

    '$route.query.section': {
      async handler(newVal) {
        this.section = null;
        this.stepCount = parseInt(this.$route.query.step, 10);
        if (!this.stepTotal) {
          await this.validate();
        }
        this.recalculateStepTotal();

        setTimeout(() => {
          if (newVal) {
            this.section = newVal;
            this.$refs.progress.focus();
          } else {
            this.section = 'question-0';
          }
          this.progress = (this.stepCount / this.stepTotal) * 100;
          this.$forceUpdate();
        }, 600);
      },
    },
  },

  created() {
    this.ownerId = this.$route.params.schemaId;
    this.ownerType = 'Schema';
    this.formSchema = this.$store.state.schemas[this.$route.params.schemaId];
    this.load();
  },

  methods: {
    backFromFinish() {
      this.processing = true;
      if (this.validVerificationQuestions.length > 0) {
        this.step_back(`verification-${this.validVerificationQuestions.length - 1}`);
      } else {
        this.step_back(`question-${this.validQuestions.length - 1}`);
      }
    },

    async backFromQuestion(index) {
      this.processing = true;
      if (!this.form.locked) {
        await this.saveForm();
        if (this.schema.data_type === 'Provider') await this.saveProvider();
        await this.validate();
      }
      this.recalculateStepTotal();

      if (index - 1 < 0) {
        this.$router.push({ name: 'ProviderIndex' });
      } else {
        this.step_back(`question-${index - 1}`);
      }
    },

    backFromVerificationQuestion(index) {
      this.processing = true;
      if (index - 1 < 0) {
        this.step_back(`question-${this.validQuestions.length - 1}`);
      } else {
        this.step_back(`verification-${index - 1}`);
      }
    },

    async finish() {
      this.processing = true;
      if (!this.form.locked) {
        this.form.submitted = true;
        await Api.manager.provider.form.promiseUpdate(
          this.$route.params.providerId,
          this.form.id,
          this.form,
        );
      }
      this.$router.push({ name: 'ProviderIndex' });
    },

    async forwardFromQuestion(index) {
      this.processing = true;
      if (!this.form.locked) {
        await this.saveForm();
        if (this.schema.data_type === 'Provider') await this.saveProvider();
        await this.validate();
      }
      this.recalculateStepTotal();

      if (index + 1 >= this.validQuestions.length) {
        if (this.validVerificationQuestions.length > 0) {
          this.step_forward('verification-0');
        } else {
          this.step_forward('finish');
        }
      } else {
        this.step_forward(`question-${index + 1}`);
      }
    },

    forwardFromVerificationQuestion(index) {
      this.processing = true;

      if (index + 1 >= this.validVerificationQuestions.length) {
        this.step_forward('finish');
      } else {
        this.step_forward(`verification-${index + 1}`);
      }
    },

    load() {
      this.loadForm();
      this.loadProvider();
    },

    async loadForm() {
      const filters = {
        schema_id: this.$route.params.schemaId,
      };
      const { data } = await Api.manager.provider.form.index(
        this.$route.params.providerId,
        filters,
      );
      [this.form] = data;

      if (data && !this.form) {
        const response = await Api.manager.provider.form.create(this.$route.params.providerId, {
          schema_id: this.$route.params.schemaId,
        });
        this.form = response.data;
      }
      this.loadQuestions(() => {
        if (this.questions[0].synced_model === 'Provider') {
          this.schema = this.$store.state.schemas[this.provider.schema_id];
        } else {
          this.schema = this.formSchema;
        }
        this.recalculateStepTotal();
      });
      this.loadAttachments();
    },

    async loadAttachments() {
      const params = {
        owner_type: 'Form',
        owner_id: this.form.id,
      };
      const response = await Api.member.attachment.index(params);
      if (response?.status !== 200) return;
      this.attachments = response.data;
    },

    loadProvider() {
      Api.manager.provider.get(this.$route.params.providerId, (response) => {
        this.provider = response.data;
      });
    },

    recalculateStepTotal() {
      this.stepTotal = this.validQuestions.length + this.validVerificationQuestions.length + 1;
      this.progress = (this.stepCount / this.stepTotal) * 100;
    },

    async saveForm() {
      const { data } = await Api.manager.provider.form.promiseUpdate(
        this.$route.params.providerId,
        this.form.id,
        this.form,
      );
      this.form = data;
    },

    async saveProvider() {
      await Api.provider.promiseUpdate(this.$route.params.providerId, this.provider);
    },
  },
};
</script>
