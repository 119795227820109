<template>
  <v-row :dense="dense">
    <LabeledTextfield
      v-for="standardProp in standardProps"
      :key="standardProp"
      :field="standardProp"
      :model-value="humanizeBoolean(valueObject[standardProp])"
      :schema-id="schemaId"
      cols="12"
      md="4"
      dense
    />
    <LabeledTextfield
      v-for="customProp in customProps"
      :key="customProp"
      :hard-lock="true"
      :message="schema?.definition?.properties?.custom?.properties[customProp]?.alias"
      :model-value="humanizeBoolean(valueObject.custom[customProp])"
      cols="12"
      md="4"
      dense
    />
  </v-row>
</template>

<script>
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledTextfield,
  },

  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    valueObject: {
      type: Object,
      default: () => {},
    },
    excludedProps: {
      type: Array,
      default: () => [],
    },
    schemaId: {
      type: String,
      default: null,
    },
  },

  computed: {
    customProps() {
      if (!this.valueObject) return [];

      const { custom } = this.valueObject;
      return Object.keys(custom);
    },

    standardProps() {
      if (!this.valueObject) return [];

      const keys = Object.keys(this.valueObject);
      return keys.filter((key) => !this.excludedProps?.concat('custom').includes(key));
    },

    schema() {
      return this.$store.state.schemas[this.schemaId];
    },
  },

  methods: {
    humanizeBoolean(answer) {
      if (answer === true) {
        return this.$t('Yes');
      }
      if (answer === false) {
        return this.$t('No');
      }
      return answer;
    },
  },
};
</script>
