<template>
  <v-card
    v-if="assignments && assignmentCompletions"
    border
    flat
    outline
  >
    <LessonCardHeader
      :assignment-completions="assignmentCompletions"
      :assignments="assignments"
      :cohort-lesson="cohortLesson"
      :index="index"
      :lesson-completion="lessonCompletion"
      :title="lesson.name"
    />

    <v-divider class="pb-2" />

    <StartAssignmentButton
      v-for="(assignment, assignmentIndex) in assignments"
      :key="assignment.id"
      :assignment="assignment"
      :assignment-completions="assignmentCompletions"
      :cohort-id="cohortId"
      :cohort-lesson="cohortLesson"
      :course-completion="courseCompletion"
      :disabled="locked()"
      :index="assignmentIndex"
      :lesson="lesson"
      :next-assignment-index="nextAssignmentIndex"
    />

    <StartQuizButton
      v-if="lesson.quiz_schema_id"
      :assignment-completions="assignmentCompletions"
      :assignments="assignments"
      :cohort-lesson="cohortLesson"
      :lesson-completion="lessonCompletion"
      :schema-id="lesson.quiz_schema_id"
    />
  </v-card>
</template>

<script setup>
import api from '@/shared/services/bright_finder';
import LessonCardHeader from '@/manager/components/lessons/LessonCardHeader.vue';
import StartAssignmentButton from '@/manager/components/StartAssignmentButton.vue';
import StartQuizButton from '@/manager/components/StartQuizButton.vue';

const props = defineProps({
  cohortLesson: {
    type: Object,
    default: null,
  },
  cohortId: {
    type: String,
    default: null,
  },
  courseCompletion: {
    type: Object,
    default: null,
  },
  index: {
    type: Number,
    default: 0,
  },
  lastLessonCompletedIndex: {
    type: Number,
    default: null,
  },
  lesson: {
    type: Object,
    default: null,
  },
  lessonCompletion: {
    type: Object,
    default() {
      return {
        count_of_assignment_completions: 0,
        progress: 0,
      };
    },
  },
});

const now = Date.now();
const assignmentCompletions = ref(null);
const assignments = ref(null);
const nextAssignmentIndex = ref(-1);

function getNextAssignmentIndex() {
  if (assignmentCompletions.value?.length === 0) return 0;

  const workRemaining = assignments.value?.filter(
    (assignment) =>
      !assignmentCompletions.value?.some(
        (assignmentCompletion) =>
          assignmentCompletion.assignment_id === assignment.id && assignmentCompletion.completed,
      ),
  );

  if (workRemaining.length === 0) return -1;

  return assignments.value?.findIndex((assignment) => workRemaining[0].id === assignment.id);
}

async function loadAssignments() {
  const resp = await api.public_api.organization.assignment.index({
    lesson_id: props.lesson.id,
  });
  assignments.value = resp.data;
}

async function loadAssignmentCompletions() {
  const params = {
    course_completion_id: props.courseCompletion.id,
    lesson_id: props.lesson.id,
  };
  const resp = await api.assignmentCompletion.index(params);
  assignmentCompletions.value = resp.data;
}

function locked() {
  if (props.cohortLesson) {
    if (props.cohortLesson.attributes?.start_date) {
      // Convert date to local timestamp right after midnight
      const [year, month, day] = props.cohortLesson.attributes.start_date.split('-').map(Number);
      const startTime = new Date(year, month - 1, day).setHours(0, 0, 0, 0);
      if (now < startTime) return true;
    }

    if (
      props.cohortLesson.attributes?.end_date &&
      props.cohortLesson?.attributes?.lock_after_end_date
    ) {
      // Convert date to local timestamp right before midnight
      const [year, month, day] = props.cohortLesson.attributes.end_date.split('-').map(Number);
      const endTime = new Date(year, month - 1, day).setHours(23, 59, 59, 999);
      if (now > endTime) return true;
    }

    if (props.cohortLesson.attributes?.start_date || props.cohortLesson.attributes?.end_date) {
      // The lesson is between the start and end date, so it's unnecessary
      // to compare against lastLessonCompletedIndex
      return false;
    }
  }

  // Base case is the first is unlocked and the rest are locked
  if (props.index === 0) return false;
  if (props.lastLessonCompletedIndex < 0) return true;

  // The cohortLesson is not present or empty and the lastLessonCompletedIndex is not negative
  // Therefore, it's appropriate to compare against lastLessonCompletedIndex
  return props.index > props.lastLessonCompletedIndex;
}

async function load() {
  await loadAssignments();
  await loadAssignmentCompletions();
  nextAssignmentIndex.value = getNextAssignmentIndex();
}

onMounted(async () => {
  await load();
});
</script>
