<template>
  <div class="d-flex w-full">
    <LabeledTextField
      @focus="$event.target.select()"
      ref="copyInput"
      :message="label"
      :model-value="value"
      class="copyable-ui"
      variant="solo-filled"
      flat
      readonly
    >
      <template #append>
        <v-btn
          @click="copyInput"
          class="centered text--primary"
          data-testid="copy-input-btn"
          density="comfortable"
          icon="content_copy"
          variant="text"
        />
      </template>
    </LabeledTextField>
  </div>
</template>

<script>
import LabeledTextField from '@/shared/components/form/LabeledTextfield.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    LabeledTextField,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },

  methods: {
    copyInput() {
      this.writeClipboardText();
    },

    async writeClipboardText() {
      try {
        await navigator.clipboard.writeText(this.value);
      } catch (error) {
        this.$eventBus.$emit('error', error);
      }
    },
  },
};
</script>

<style scoped>
:deep(.v-field) {
  border-radius: 8px;
  border: 1px solid #e4e7ec;
}

:deep(.v-field) input {
  color: #98a2b3;
  font-size: 14px;
}
</style>
