<template>
  <div class="w-100pc h-100pc oy-hidden bg-super-light-blue">
    <v-row
      class="bg-super-light-blue w-100pc h-100pc oy-hidden bottom-0 p-absolute"
      style="padding-top: 65px"
      no-gutters
    >
      <v-col
        v-show="$vuetify.display.mdAndUp || activeThread == false"
        class="h-100pc oy-hidden-scroll br-1 bc-extra-light-gray bg-white pb-16"
        cols="12"
        md="4"
      >
        <div class="pa-4 bb-1 bc-extra-light-gray d-flex align-center">
          <v-text-field
            v-model="query"
            :aria-label="$t('Search by name')"
            :placeholder="$t('Search by name')"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tile
          />
          <v-btn
            @click="load"
            :loading="processing"
            class="ms-1"
            size="small"
            variant="text"
            icon
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </div>
        <div
          v-if="$store.state.pages.Messaging.features.enable_support"
          @click="switchThread()"
          class="pa-4 bb-1 bc-extra-light-gray d-flex align-center pointer"
          data-cy="support-thread"
          data-testid="support-thread"
        >
          <v-icon
            class="me-2"
            size="26"
          >
            support_agent
          </v-icon>
          <div>
            <div
              v-t="$store.state.brand.organization_name"
              class="fs-18 fw-500"
            />
            <div
              v-t="'Need assistance? Message one of our staff members.'"
              class="fs-16 c-light-black"
            />
          </div>
          <v-spacer />
          <v-icon
            v-if="displayNewSupportMessageIcon"
            class="mx-1"
            color="green"
          >
            circle
          </v-icon>
        </div>
        <div
          v-for="meta in filteredThreads"
          @click="switchThread(meta)"
          :key="metaKey(meta)"
          class="pa-4 bb-1 bc-extra-light-gray d-flex align-center pointer"
          data-cy="parent-thread"
        >
          <v-icon
            v-if="meta.type && meta.type === 'assessment'"
            class="me-2"
            size="26"
          >
            school
          </v-icon>
          <v-icon
            v-else
            class="me-2"
            size="26"
          >
            escalator_warning
          </v-icon>
          <div>
            <div
              v-text="metaName(meta)"
              class="fs-18 fw-500"
            />
            <MarkdownContent
              :content="getMessages(meta).text"
              class="fs-16 c-light-black h-20 oy-hidden"
            />
          </div>
          <v-spacer />
          <v-icon
            v-if="checkForUnreadGroupMessages(meta)"
            class="mx-1"
            color="green"
          >
            circle
          </v-icon>
        </div>
      </v-col>

      <v-col
        v-show="$vuetify.display.mdAndUp || activeThread != false"
        class="h-100pc oy-hidden-scroll p-relative bg-white"
        cols="12"
        data-cy="conversation"
        md="8"
      >
        <div class="h-100pc w-100pc">
          <MessageThreadHeader
            @back="switchThread(false)"
            :assessment="loadAssessment(activeThread)"
            :subtitle="activeThreadSubtitle"
            :title="activeThreadTitle"
          />
          <div
            v-if="$store.state.pages.Messaging.features.enable_support && activeThread == null"
            id="messages_list"
            key="org-messages"
            :class="messageListClass"
            style="bottom: 220px"
          >
            <MessageItem
              v-for="message in messagesWithOrg"
              :key="message.id"
              :message="message"
            />
          </div>
          <div
            v-if="filteredActiveThread"
            id="messages_list"
            :class="messageListClass"
            style="bottom: 220px"
          >
            <MessageItem
              v-for="message in nonOrgMessages[metaKey(filteredActiveThread)]"
              :key="message.id"
              :message="message"
            />
          </div>
          <div
            v-if="activeThread || $store.state.pages.Messaging.features.enable_support"
            class="bc-extra-light-gray bg-white w-100pc bottom-0 p-absolute"
            data-cy="message-composer"
          >
            <div class="pa-2">
              <v-row dense>
                <v-col>
                  <v-card
                    style="background: rgba(0, 0, 0, 0.04)"
                    flat
                    tile
                  >
                    <v-btn
                      @click="$refs.attachmentDialog.open({})"
                      data-cy="attach-button"
                      style="background: transparent"
                      variant="flat"
                    >
                      <v-icon start> attachment </v-icon>
                      <span v-t="'Attach document'" />
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
              <v-divider />
              <AttachmentDialog
                @save="createAttachmentMessages"
                @upload="uploadedAttachments.push($event)"
                ref="attachmentDialog"
                :processing="processing"
              />
              <v-textarea
                v-model="newMessageText"
                :aria-label="$t('Enter your message')"
                class="mb-2"
                data-cy="message-textarea"
                rows="3"
                variant="filled"
                hide-details
                tile
              />
              <v-btn
                @click="createMessage"
                :disabled="submitDisabled"
                :loading="processing"
                color="primary"
                data-cy="send-message-button"
                size="x-large"
                block
                >{{ $t('Send message') }}</v-btn
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Api from '@/manager/services/bright_finder';
import AttachmentDialog from '@/shared/components/attachments/AttachmentDialog.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import MessageItem from '@/shared/components/MessageItem.vue';
import MessageThreadHeader from '@/shared/components/MessageThreadHeader.vue';
import vuetify from '@/plugins/vuetify';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AttachmentDialog,
    MarkdownContent,
    MessageItem,
    MessageThreadHeader,
  },

  data() {
    return {
      activeThread: vuetify.display.mdAndUp ? null : false,
      assessmentThreads: [],
      currentMemberId: this.$store.state.profile.id,
      groupThreads: [],
      lastMessageDates: {},
      lastOrgMessageDate: null,
      messageListClass: 'bg-white w-100pc d-flex flex-column pa-4 top-100 oy-scroll p-absolute',
      messages: [],
      messagesWithOrg: [],
      nonOrgMessages: {},
      newMessageText: null,
      processing: false,
      query: null,
      readDates: {},
      readOrgDate: null,
      uploadedAttachments: [],
    };
  },

  mounted() {
    this.activeThread = vuetify.display.mdAndUp ? null : false;
  },

  computed: {
    filteredActiveThread() {
      return this.filteredThreads?.find(
        (thread) =>
          thread.group?.id === this.activeThread?.group?.id &&
          thread.provider?.id === this.activeThread?.provider?.id,
      );
    },

    activeThreadSubtitle() {
      if (this.activeThread && this.activeThread.type === 'assessment') {
        return this.$t('Open this assessment');
      }
      if (this.activeThread && this.activeThread.group && this.activeThread.provider) {
        return this.$t(`Family interested in ${this.activeThread.provider.address}`);
      }
      if (this.$store.state.pages.Messaging.features.enable_support) {
        return this.$t('Support staff will message you on this channel.');
      }
      return this.$t('Messages you receive from families will appear here.');
    },

    activeThreadTitle() {
      if (this.activeThread && this.activeThread.type === 'assessment') {
        return `${this.activeThread.schema_name} - ${this.activeThread.name}`;
      }
      if (this.activeThread && this.activeThread.group && this.activeThread.provider) {
        return this.activeThread.group.name;
      }
      if (this.$store.state.pages.Messaging.features.enable_support) {
        return this.$store.state.brand.organization_name;
      }
      return this.$t('No messages');
    },

    displayNewSupportMessageIcon() {
      if (!this.messagesWithOrg.length) return false;

      const lastIndex = this.messagesWithOrg.length - 1;
      return (
        this.messagesWithOrg.length > 0 &&
        [this.messagesWithOrg[lastIndex]].some(
          (message) => !message.meta.member.is_provider && !message.read_at,
        )
      );
    },

    filteredThreads() {
      if (this.query && this.query !== '') {
        const filteredGroups = this.groupThreads.filter(
          (thread) => thread.group.name && thread.group.name.toLowerCase().includes(this.query),
        );
        const filteredAssessments = this.assessmentThreads.filter(
          (thread) => thread.name && thread.name.toLowerCase().includes(this.query),
        );
        return filteredGroups.concat(filteredAssessments);
      }
      return this.groupThreads.concat(this.assessmentThreads);
    },

    submitDisabled() {
      return !this.newMessageText || this.newMessageText.trim().length === 0;
    },
  },

  created() {
    this.load();
  },

  methods: {
    checkForUnreadGroupMessages(meta) {
      if (meta.provider) {
        [this.getMessages(meta)].some(
          (message) => !message.meta.member.is_provider && !message.read_at,
        );
      } else if (meta.type && meta.type === 'assessment') {
        [this.getMessages(meta)].some((message) => !message.read_at);
      }
    },

    createMessage() {
      this.processing = true;

      let params;
      if (this.activeThread) {
        if (this.activeThread.type && this.activeThread.type === 'assessment') {
          params = { text: this.newMessageText, assessment_id: this.activeThread.id };
        } else if (this.activeThread.provider && this.activeThread.group) {
          params = {
            text: this.newMessageText,
            provider_id: this.activeThread.provider.id,
            group_id: this.activeThread.group.id,
          };
        }
      } else {
        params = { text: this.newMessageText };
      }

      Api.manager.message.create(params, (resp) => {
        this.messages.push(resp.data);
        this.processing = false;
        this.newMessageText = null;
        this.sortMessages();
        this.scrollToBottom();
      });
    },

    getMessages(meta) {
      let key;
      if (meta.group && meta.provider) {
        key = [meta.group.id, meta.provider.id].join('');
      }
      if (meta.type && meta.type === 'assessment') {
        key = meta.id;
      }
      return this.nonOrgMessages[key][this.nonOrgMessages[key].length - 1];
    },

    load() {
      this.assessmentThreads = [];
      this.messages = [];
      this.groupThreads = [];
      this.messagesWithOrg = [];
      this.nonOrgMessages = {};
      this.processing = true;
      Api.manager.message.index({}, (resp) => {
        this.messages = resp.data;
        this.processing = false;
        this.sortMessages();
        this.scrollToBottom();
      });
    },

    loadAssessment(activeThread) {
      if (activeThread?.type && activeThread?.type === 'assessment') {
        return activeThread;
      }
      return null;
    },

    async createAttachmentMessages() {
      this.processing = true;

      let params;
      if (this.activeThread) {
        if (this.activeThread.type && this.activeThread.type === 'assessment') {
          params = {
            assessment_id: this.activeThread.id,
          };
        } else if (this.activeThread.provider && this.activeThread.group) {
          params = {
            provider_id: this.activeThread.provider.id,
            group_id: this.activeThread.group.id,
          };
        }
      } else {
        params = { group_id: null };
      }

      await Promise.all(
        this.uploadedAttachments.map(async (attachment) => {
          const asset = attachment;
          asset.group_id = params.group_id;
          const { data } = await Api.member.attachment.create(asset);
          const resp = await Api.manager.message.promiseCreate({
            ...params,
            attachment_id: data.id,
          });
          this.messages.push(resp.data);
        }),
      );

      this.$refs.attachmentDialog.close();
      this.uploadedAttachments = [];
      this.processing = false;
      this.newMessageText = null;
      this.sortMessages();
      this.scrollToBottom();
    },

    metaKey(meta) {
      if (meta.group && meta.provider) return [meta.group.id, meta.provider.id].join('');
      if (meta.type && meta.type === 'assessment') return meta.id;
      return undefined;
    },

    metaName(meta) {
      if (meta.group && meta.provider) return meta.group?.name;
      if (meta.type && meta.type === 'assessment') return `${meta.schema_name} - ${meta.name}`;
      return undefined;
    },

    redirectToAssessment(assessment) {
      this.$router.push({ name: 'AssessmentShow', params: { assessmentId: assessment.id } });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const list = document.getElementById('messages_list');
        if (list !== null) {
          list.scrollTop = list.scrollHeight;
        }
      });
    },

    sortMessages() {
      this.messages.forEach((message) => {
        let key;
        if (message.assessment_id) {
          key = message.assessment_id;
          if (this.nonOrgMessages[message.assessment_id] === undefined) {
            this.nonOrgMessages[message.assessment_id] = [];
            if (!this.assessmentThreads.includes(message.meta.assessment)) {
              this.assessmentThreads.push(message.meta.assessment);
            }
          }
          if (!this.nonOrgMessages[message.assessment_id].includes(message)) {
            this.nonOrgMessages[message.assessment_id].push(message);
          }
        } else if (message.group_id == null) {
          if (!this.messagesWithOrg.includes(message)) {
            this.messagesWithOrg.push(message);
          }
        } else {
          key = [message.group_id, message.provider_id].join('');
          if (this.nonOrgMessages[key] === undefined) {
            this.nonOrgMessages[key] = [];
            if (
              !this.groupThreads.includes({
                group: message.meta.group,
                provider: message.meta.provider,
              })
            ) {
              this.groupThreads.push({
                group: message.meta.group,
                provider: message.meta.provider,
              });
            }
          }

          if (!this.nonOrgMessages[key].includes(message)) {
            this.nonOrgMessages[key].push(message);
          }
        }
      }, this);

      if (this.activeThread && this.activeThread.group && this.activeThread.provider) {
        this.switchThread(
          this.filteredThreads.find(
            (thread) =>
              thread.provider &&
              thread.provider.id === this.activeThread.provider.id &&
              thread.group.id === this.activeThread.group.id,
          ),
        );
      }

      if (this.activeThread && this.activeThread.type === 'assessment') {
        this.switchThread(
          this.assessmentThreads.find((thread) => thread.id === this.activeThread.id),
        );
      }

      if (this.$route.query.provider_id && this.$route.query.group_id) {
        this.switchThread(
          this.filteredThreads.find(
            (thread) =>
              thread.provider.id === this.$route.query.provider_id &&
              thread.group.id === this.$route.query.group_id,
          ),
        );
      } else if (
        !this.$store.state.pages.Messaging.features.enable_support &&
        this.activeThread == null
      ) {
        this.switchThread(this.filteredThreads[0]);
      }
    },

    switchThread(thread) {
      this.activeThread = thread;
      if (thread !== false) {
        this.scrollToBottom();
        let msg;
        if (thread) {
          msg = this.getMessages(this.activeThread);
        } else {
          msg = this.messagesWithOrg[this.messagesWithOrg.length - 1];
        }
        if (!msg.read_at) {
          Api.manager.message.get(msg.id, () => {});
        }
        msg.read_at = true;
      }
    },
  },
};
</script>
