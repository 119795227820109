<template>
  <v-card
    border
    flat
    tile
  >
    <v-card-text class="fs-16">
      <v-row class="d-flex align-center">
        <v-col
          class="d-flex align-center"
          cols="3"
        >
          <span
            v-text="childName"
            class="fs-16 fw-500"
          />
        </v-col>
        <v-col cols="1">
          <span>{{ currency(payment.amount) }}</span>
        </v-col>
        <v-col cols="2">
          <LongDate
            :date="payment.date"
            short-month
          />
        </v-col>
        <v-col cols="3">
          <LongDate
            :date="payment.start_date"
            short-month
          />
          <span class="mx-1">-</span>
          <LongDate
            :date="payment.finish_date"
            short-month
          />
        </v-col>
        <v-col cols="3">
          <span>{{ providerName }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { currency } from '@/plugins/filters';

export default {
  compatConfig: { MODE: 2 },

  props: {
    enrollment: {
      type: Object,
      default: null,
    },
    payment: {
      type: Object,
      default: null,
    },
    provider: {
      type: Object,
      default: null,
    },
  },

  setup() {
    return { currency };
  },

  computed: {
    childName() {
      if (!this.enrollment) return null;
      return this.enrollment.meta.child.name;
    },

    providerName() {
      if (!this.provider) return null;
      return this.provider.name;
    },
  },
};
</script>
