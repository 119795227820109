<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    id="tours_card"
    elevation="0"
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>
      <h2 class="v-card-title py-0 px-0">
        {{ $t('Tour requests') }}
      </h2>
    </v-card-title>
    <v-divider />
    <v-card-text class="py-4">
      <p class="c-light-black fs-14">
        {{ $t(TOUR_CONFIRM_OR_DENY_TEXT) }}
      </p>

      <p class="c-black fs-18 fw-500">
        {{ $t('Would you like to allow families to request an on-site tour at your locations?') }}
      </p>

      <v-btn
        @click="toggleTours"
        :disabled="processing"
        color="primary"
        data-cy="enable_requests"
        size="x-large"
        block
      >
        <v-icon
          v-if="provider.tours_enabled"
          class="me-3"
        >
          check_circle
        </v-icon>
        {{ $t(provider.tours_enabled ? 'Tours enabled' : 'Enable families to request a tour') }}
      </v-btn>

      <template v-if="provider.tours_enabled">
        <v-divider class="my-8" />

        <p class="c-black fs-18 fw-500">
          {{ $t('When would you like to offer tours?') }}
        </p>

        <div
          v-for="(day, index) in hours"
          :key="index"
        >
          <v-row dense>
            <v-col
              class="d-flex align-center mb-2"
              cols="12"
            >
              <div class="fw-600 fs-18">
                {{ $t($a.assets.weekdays[day.day]) }}
              </div>
              <v-spacer />
              <v-switch
                @update:model-value="(value) => updateHours(value, index)"
                :label="hours[index].included ? $t('Yes') : $t('No')"
                :model-value="hours[index].included"
                class="ma-0"
                color="primary"
                data-cy="switch_on"
                hide-details
                inset
              />
            </v-col>
            <LabeledOpenClose
              v-show="day.included"
              @change="updateDay(index, $event)"
              :max-mins="1200"
              :min-mins="420"
              :model-value="[day.open, day.close]"
              cols="12"
            />
          </v-row>

          <v-divider
            v-if="index < hours.length - 1"
            class="my-4"
          />
        </div>

        <v-divider class="my-8" />

        <v-checkbox
          v-model="provider.tour_groups"
          @update:model-value="updateTourGroups"
          :label="$t('Allow multiple families to tour at the same time')"
          hide-details
        />

        <v-divider class="my-8" />

        <p class="c-black fs-18 fw-500">
          {{ $t('How long are your tours?') }}
        </p>
        <v-select
          v-model="provider.tour_time"
          @update:model-value="updateTourTime"
          :item-title="(tourLength) => $t(tourLength.text)"
          :items="[
            { text: $t('30 minutes'), value: 30 },
            { text: $t('1 hour'), value: 60 },
            { text: $t('2 hours'), value: 120 },
          ]"
          data-cy="tour_length"
          variant="filled"
        />
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
import api from '@/shared/services/bright_finder';
import LabeledOpenClose from '@/shared/components/form/LabeledOpenClose.vue';

const TOUR_CONFIRM_OR_DENY_TEXT =
  'Before a family can confirm a tour, you will be required to confirm or deny ' +
  'the request. You will receive an email whenever a family requests a tour.';

const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['back', 'progress', 'refresh']);

const hours = ref([
  { day: 0, open: 1700, close: 1900, included: false },
  { day: 1, open: 1700, close: 1900, included: false },
  { day: 2, open: 1700, close: 1900, included: false },
  { day: 3, open: 1700, close: 1900, included: false },
  { day: 4, open: 1700, close: 1900, included: false },
  { day: 5, open: 1700, close: 1900, included: false },
  { day: 6, open: 1700, close: 1900, included: false },
]);
const processing = ref(false);

const progress = computed(() => {
  return props.provider.tours_enabled &&
    props.provider.tour_hours.length > 0 &&
    props.provider.tour_time
    ? 100
    : 0;
});

watch(progress, (newVal) => {
  emit('progress', newVal);
});

onMounted(() => {
  setHours();
});

function saveHours() {
  const tourHours = hours.value
    .filter((day) => day.included)
    .map((day) => ({
      day: day.day,
      open: day.open,
      close: day.close,
    }));

  updateProvider({ tour_hours: tourHours });
}

function setHours() {
  if (props.provider.tour_hours) {
    hours.value = hours.value.map((newDay) => {
      const oldDayFound = props.provider.tour_hours.find((oldDay) => oldDay.day === newDay.day);
      const updatedNewDay = newDay;

      if (oldDayFound) {
        updatedNewDay.close = oldDayFound.close;
        updatedNewDay.included = true;
        updatedNewDay.open = oldDayFound.open;
        return updatedNewDay;
      }
      updatedNewDay.included = false;
      return updatedNewDay;
    });
  }
}

function toggleTours() {
  processing.value = true;
  const params = { tours_enabled: !props.provider.tours_enabled };
  updateProvider(params);
}

function updateDay(index, timeRange) {
  const [openTime, closeTime] = timeRange;
  hours.value[index].open = openTime;
  hours.value[index].close = closeTime;

  saveHours();
}

function updateHours(value, index) {
  hours.value[index].included = value;
  saveHours();
}

function updateTourGroups(newVal) {
  const params = { tour_groups: newVal };
  updateProvider(params);
}

function updateTourTime(newVal) {
  const params = { tour_time: newVal };
  updateProvider(params);
}

function updateProvider(params) {
  api.provider.update(props.provider.id, params, () => {
    emit('refresh');
    processing.value = false;
  });
}
</script>
