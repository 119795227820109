<template>
  <div>
    <v-row class="mb-1">
      <v-col>
        <router-link
          :ripple="false"
          :to="{ name: 'CourseIndex' }"
          class="c-black d-flex align-center td-none"
          tracked
        >
          <v-icon
            icon="keyboard_backspace"
            size="24"
            start
          />

          <span>
            {{ $t('Back') }}
          </span>
        </router-link>
      </v-col>
    </v-row>

    <v-card
      class="d-flex align-start mb-8"
      border
      outline
    >
      <v-col
        v-if="course.image_url"
        cols="4"
      >
        <v-img
          :src="course.image_url"
          rounded
        />
      </v-col>

      <v-col
        cols="8"
        sm="8"
      >
        <v-card class="bg-transparent">
          <v-card-title class="fs-30">
            {{ course.name }}
          </v-card-title>

          <v-card-subtitle class="py-0">
            <p v-if="course.credits">
              {{ parseInt(course.credits) }}
              {{ parseInt(course.credits) === 1 ? $t('credit') : $t('credits') }}
            </p>
          </v-card-subtitle>

          <v-card-text>
            <v-row
              v-if="course.description"
              class="pa-3"
            >
              <MarkdownContent
                :content="course.description"
                class="fs-16"
              />
            </v-row>

            <v-row>
              <v-col cols="2">
                <v-progress-circular
                  :model-value="courseCompletion.progress"
                  color="primary"
                  rotate="-90"
                  size="70"
                  width="10"
                >
                  <div class="fs-16 fw-600">{{ courseCompletion.progress }}%</div>
                </v-progress-circular>
              </v-col>

              <v-col
                class="d-flex align-center"
                cols="10"
              >
                {{ courseCompletion.count_of_lesson_completions }} / {{ course.count_of_lessons }}
                {{ $t('Lessons completed') }}
              </v-col>
            </v-row>

            <v-row
              v-if="showDownloadCertificate"
              class="pt-3"
            >
              <v-btn
                :href="downloadLink"
                :ripple="false"
                color="primary"
                prepend-icon="download"
                variant="flat"
              >
                {{ $t('Download certificate') }}
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-card>
  </div>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';

const props = defineProps({
  cohortCourseCompletion: {
    type: Object,
    default: null,
  },
  course: {
    type: Object,
    default: null,
  },
  courseCompletion: {
    type: Object,
    default: null,
  },
});

const downloadLink = computed(() => {
  return Api.manager.course_completion.downloadUrl(
    props.courseCompletion.id,
    props.course.name.replaceAll(' ', '-'),
  );
});

const showDownloadCertificate = computed(() => {
  if (props.cohortCourseCompletion) return props.cohortCourseCompletion?.attributes?.awarded;

  return props.courseCompletion?.completed_at || false;
});
</script>
