<template>
  <div
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
    :id="'tab_' + order"
    :class="containerClass"
    class="pa-4 d-flex align-center focus-visible"
    tabindex="0"
  >
    <template v-if="!hideIcon">
      <v-icon
        v-if="locked"
        :class="heavyActive"
        :disabled="locked"
        class="me-2"
        color="gray"
        size="22"
      >
        lock_outline
      </v-icon>
      <v-icon
        v-if="progress == 100"
        :class="heavyActive"
        class="me-2"
        color="green"
        size="22"
      >
        check_circle
      </v-icon>
      <v-progress-circular
        v-if="!locked && progress < 100"
        :model-value="progress"
        class="me-2"
        size="22"
        width="2"
      >
        <span
          v-text="order"
          class="fs-12"
        />
      </v-progress-circular>
    </template>
    <div>
      <div
        :class="underlinedHeavyActive"
        class="fs-16"
      >
        {{ $t(title) }}
      </div>
    </div>
    <v-spacer />
    <v-icon>chevron_right</v-icon>
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    dividedBottom: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    order: {
      type: String,
      default: null,
    },
    progress: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: null,
    },
  },

  emits: ['click'],

  computed: {
    heavyActive() {
      if (this.active) return 'fw-600';
      return 'fw-500';
    },
    underlinedHeavyActive() {
      const classes = [];
      classes.push(this.heavyActive);
      if (this.active) classes.push('underlined');
      return classes.join(' ');
    },
    containerClass() {
      const classes = [];
      if (this.dividedBottom) {
        classes.push('bb-1 bc-very-light-gray');
      }

      if (this.locked) {
        classes.push('cursor-default');
      } else {
        classes.push('pointer');
      }

      return classes.join(' ');
    },
  },
};
</script>
