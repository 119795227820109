<template>
  <v-card-text>
    <div class="d-flex align-center justify-center my-4">
      <v-autocomplete
        v-model="providerId"
        :items="providers"
        item-title="name"
        item-value="id"
        variant="filled"
        hide-details
      />
    </div>

    <v-row>
      <v-col
        v-for="asset in assets"
        :key="asset.id"
        cols="4"
      >
        <v-img
          :src="asset.url"
          aspect-ratio="1"
          class="bg-grey-lighten-2"
        >
          <template #placeholder>
            <v-row
              align="center"
              class="fill-height ma-0"
              justify="center"
            >
              <v-progress-circular
                color="grey-lighten-5"
                indeterminate
              />
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

    <NullState
      v-if="assets.length === 0"
      class="mt-6"
      title="No images found"
      hide-new-button
    />
  </v-card-text>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';

const assets = ref([]);
const providerId = ref(null);
const providers = ref([]);

watch(providerId, (newValue) => {
  if (newValue) loadAssets();
});

async function load() {
  const response = await Api.manager.provider.index();
  providers.value = response?.data;
  providerId.value = providers.value[0].id;
  loadAssets();
}

function loadAssets() {
  Api.manager.provider.asset.index(providerId.value, { category: 'image' }, (response) => {
    assets.value = response.data;
  });
}

onMounted(load);
</script>
