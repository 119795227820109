<template>
  <div v-if="assessment && selectedSchema">
    <v-container>
      <TitleBar :name="$t('Provider') + ': ' + assessment?.provider?.name" />

      <v-alert
        v-if="assessment?.messages?.length > 0"
        @click="
          $router.push({
            name: 'MessageIndex',
          })
        "
        border="start"
        class="pointer"
        color="primary"
        elevation="2"
        icon="notification_important"
        prominent
      >
        <v-row class="d-flex align-center">
          <v-col class="grow">
            <span class="fs-18">
              {{ $t('You have a message about this assessment') }}
            </span>
          </v-col>

          <v-col class="ta-right">
            <v-icon
              color="primary"
              size="30"
            >
              east
            </v-icon>
          </v-col>
        </v-row>
      </v-alert>

      <v-row
        class="mb-16"
        dense
      >
        <v-col
          cols="12"
          md="3"
        >
          <v-card
            class="mb-4"
            border
            flat
            tile
          >
            <v-card-title class="fs-18 fw-500 bb-1 bc-very-light-grey mb-0">
              <v-row>
                <v-col>
                  <span>
                    {{ $t('Overview') }}
                  </span>
                </v-col>

                <template v-if="assessment.completed_at">
                  <v-col class="d-flex justify-end">
                    <ActionMenu
                      :button-icon-size="18"
                      :items="[
                        {
                          title: 'Download',
                          event: 'download',
                          href: downloadLink(),
                        },
                      ]"
                      button-icon="more_vert"
                      x-small
                    />
                  </v-col>
                </template>
              </v-row>
            </v-card-title>

            <v-list class="v-list--shaped">
              <v-list-item
                :to="{ query: {} }"
                exact
              >
                <v-list-item-title class="d-flex justify-space-between align-center">
                  <h1 class="fs-16 mxw-175 o-dotted">
                    {{ $t(schema.name) }}
                  </h1>

                  <template v-if="assessment.completed_at">
                    <v-icon color="primary"> check_circle </v-icon>
                  </template>
                </v-list-item-title>
              </v-list-item>

              <template v-if="worksheets.length > 0">
                <v-list-group :value="true">
                  <template #activator>
                    <v-list-item-title class="fs-6 fw-500">
                      {{ $t('Worksheets') }}
                    </v-list-item-title>
                  </template>

                  <v-list-item
                    v-for="worksheet in worksheets"
                    :key="worksheet.id"
                    :to="{ query: { worksheet_id: worksheet.id } }"
                    lines="two"
                  >
                    <v-list-item-title class="d-flex justify-space-between align-center">
                      <div class="mxw-200">
                        <div class="fs-16 mb-2 o-dotted">
                          {{ $t(worksheet.name) }}
                        </div>

                        <div class="fs-14 c-light-black o-dotted">
                          {{
                            $t(
                              worksheetSchemas.find(
                                (worksheetSchema) => worksheetSchema.id == worksheet.schema_id,
                              ).name,
                            )
                          }}
                        </div>
                      </div>

                      <v-icon
                        v-if="worksheet.completed"
                        color="primary"
                      >
                        check_circle
                      </v-icon>
                    </v-list-item-title>
                  </v-list-item>
                </v-list-group>
              </template>
            </v-list>
          </v-card>
        </v-col>

        <template v-if="questions.length == 0">
          <v-col
            class="ta-center pa-8"
            cols="12"
            md="9"
          >
            <v-progress-circular
              size="40"
              indeterminate
            />
          </v-col>
        </template>

        <v-col
          cols="12"
          md="9"
        >
          <v-card
            border
            flat
            tile
          >
            <v-card-text>
              <QuestionSet
                v-if="selectedSheet"
                v-model="selectedSheet"
                @change="changed = true"
                @change:attachments="loadAttachments"
                :attachment-owner-id="assessment.id"
                :attachment-owner-type="'Assessment'"
                :attachments="attachments"
                :color="null"
                :divided="$vuetify.display.mdAndUp"
                :hide-field="!fieldsViewable()"
                :landscape="$vuetify.display.mdAndUp"
                :processing="processing"
                :questions="questions"
                :readonly="!!selectedSheet.completed_at"
                :schema="selectedSchema.definition"
                attachment-status
                expanded
                flat
                hide-actions
                inline-verification
                outlined
                tile
                very-dense
              />

              <QuestionSet
                v-else
                v-model="assessment"
                @change="changed = true"
                @change:attachments="loadAttachments"
                :attachment-owner-id="assessment.id"
                :attachment-owner-type="'Assessment'"
                :attachments="attachments"
                :color="null"
                :hide-field="!fieldsViewable()"
                :landscape="$vuetify.display.mdAndUp"
                :processing="processing"
                :questions="questions"
                :readonly="!!assessment.completed_at"
                :schema="selectedSchema.definition"
                attachment-status
                expanded
                flat
                hide-actions
                inline-verification
                outlined
                tile
                very-dense
              />
            </v-card-text>
          </v-card>

          <div class="d-flex align-center mt-4">
            <v-btn
              v-show="changed"
              @click="selectedSheet ? saveWorksheet() : save()"
              :block="$vuetify.display.smallAndDown"
              :loading="processing"
              color="primary"
              size="large"
            >
              {{ $t('Save') }}
            </v-btn>

            <v-spacer />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import API from '@/shared/mixins/api';
import Assessment from '@/shared/mixins/assessment';
import LinkedList from '@/shared/services/linked-list';
import TitleBar from '@/shared/components/TitleBar.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ActionMenu,
    TitleBar,
    QuestionSet,
  },

  mixins: [API, Assessment],

  data() {
    return {
      attachments: [],
      changed: false,
      schema: null,
      assessment: null,
      documentQuery: null,
      newMessageText: null,
      notes: [],
      processing: false,
      questions: [],
      rightBarTab: null,
      rightBarVisible: false,
      worksheets: [],
      worksheetSchemas: [],
      selectedSheet: null,
      selectedSchema: null,
    };
  },

  watch: {
    rightBarTab(newValue) {
      if (newValue != null) {
        this.rightBarVisible = true;
      } else {
        this.rightBarVisible = false;
      }
    },

    '$route.params.assessmentId': {
      immediate: true,
      handler() {
        this.load();
      },
    },

    '$route.query.worksheet_id': {
      immediate: true,
      handler() {
        this.load();
      },
    },
  },

  methods: {
    downloadLink() {
      return this.api.manager.assessment.downloadUrl(this.assessment.id, this.assessment.name);
    },

    async load() {
      this.selectedSchema = null;
      this.processing = true;
      this.selectedSheet = null;
      this.questions = [];
      this.loadAssessment();
      this.processing = false;
    },

    async loadAssessment() {
      const response = await this.api.manager.assessment.get(this.$route.params.assessmentId);
      this.assessment = response.data;

      this.currentStageId = this.assessment.stage_id;
      this.schema = Object.values(this.$store.state.schemas).find(
        (schema) => schema.id === this.assessment.schema_id,
      );

      this.worksheetSchemas = Object.values(this.$store.state.schemas)
        .filter((schema) => this.schema.associated_schema_ids.includes(schema.id))
        .sort((a, b) => (a.name > b.name ? 1 : -1));

      this.loadAttachments();
      this.loadWorksheets();
      this.loadProvider();
      this.loadMessages();

      if (!this.$route.query.worksheet_id && this.schema) {
        this.selectedSchema = this.schema;
        this.loadQuestions(this.schema.id);
      }
    },

    getWorksheetIncomplete() {
      if (this.selectedSheet) {
        const mandatoryQuestions = this.questions.filter(
          (question) => !question.is_section && question.mandatory,
        );

        const incompleteMandatoryQuestions = mandatoryQuestions.filter((question) =>
          question.synced_attributes.some((syncedAttribute) => {
            const attrParts = syncedAttribute.name.split('.');
            let value;

            if (attrParts[0] === 'custom') {
              value = this.selectedSheet.custom[attrParts[attrParts.length - 1]];
            } else {
              value = this.selectedSheet[attrParts[attrParts.length - 1]];
            }

            return value === null || value === undefined || value === '';
          }),
        );
        return incompleteMandatoryQuestions.length > 0;
      }
      return true;
    },

    loadMessages() {
      this.api.manager.message.index({ assessment_id: this.assessment.id }, (response) => {
        this.messages = response.data;
        this.assessment.messages = response.data;
      });
    },

    async loadProvider() {
      const response = await this.api.manager.provider.get(this.assessment.provider_id);
      this.assessment.provider = response.data;
    },

    async loadAttachments() {
      const response = await this.api.manager.attachment.index({
        owner_type: 'Assessment',
        owner_id: this.assessment.id,
      });
      if (response?.status !== 200) return;

      this.attachments = response.data;
    },

    async loadQuestions(ownerId, callback) {
      const response = await this.api.public_api.organization.question.index({
        owner_id: ownerId,
        owner_type: 'Schema',
      });

      this.validateQuestions(
        LinkedList.sort(response.data).filter((question) => question.published),
        callback,
      );
    },

    draftsAccessible() {
      return this.schema.meta.enable_provider_drafts_access;
    },

    fieldsViewable() {
      return this.draftsAccessible() || this.assessment.completed_at;
    },

    async loadWorksheets() {
      const response = await this.api.manager.worksheet.index({
        assessment_id: this.assessment.id,
      });
      this.worksheets = response.data
        .filter((worksheet) =>
          this.worksheetSchemas.find(
            (worksheetSchema) => worksheetSchema.id === worksheet.schema_id,
          ),
        )
        .sort((a, b) =>
          this.$store.state.schemas[a.schema_id].name > this.$store.state.schemas[b.schema_id].name
            ? 1
            : -1,
        );

      if (this.$route.query.worksheet_id && this.worksheetSchemas.length > 0) {
        this.selectedSheet = this.worksheets.find(
          (worksheet) => worksheet.id === this.$route.query.worksheet_id,
        );

        this.selectedSchema = this.worksheetSchemas.find(
          (worksheetSchema) => worksheetSchema.id === this.selectedSheet.schema_id,
        );

        this.loadQuestions(this.selectedSheet.schema_id);
      }
    },

    async save() {
      this.processing = true;
      try {
        const response = await this.api.manager.assessment.update(
          this.assessment.id,
          this.assessment,
        );

        this.assessment = response.data;
        this.changed = false;

        if (this.assessment) {
          this.loadQuestions(this.assessment.schema_id, () => {
            this.$eventBus.$emit('chime', 'Saved');
            this.processing = false;
            this.assessmentIncomplete = this.getAssessmentIncomplete();
          });
        }
      } catch (error) {
        this.processing = false;
        this.$eventBus.$emit('chime', error.response.data.errors[0]);
      }
    },

    getAssessmentIncomplete() {
      if (this.worksheets.some((worksheet) => !worksheet.completed_at)) return true;

      const mandatoryQuestions = this.questions.filter(
        (question) => !question.is_section && question.mandatory && question.valid,
      );

      return mandatoryQuestions.some((question) =>
        question.synced_attributes.some((syncedAttribute) => {
          const attrParts = syncedAttribute.name.split('.');
          let value;

          if (attrParts[0] === 'custom') {
            value = this.assessment.custom[attrParts[attrParts.length - 1]];
          } else {
            value = this.assessment[attrParts[attrParts.length - 1]];
          }

          return value === null || value === undefined || (value === '' && value !== false);
        }),
      );
    },

    async saveWorksheet(newValue, reload = false) {
      this.processing = true;
      try {
        const response = await this.api.manager.worksheet.update(
          this.selectedSheet.id,
          this.assessment.id,
          newValue || this.selectedSheet,
        );

        if (reload) {
          this.load();
        } else {
          this.selectedSheet = response.data;
          this.changed = false;
          this.$set(
            this.worksheets,
            this.worksheets.findIndex((worksheet) => worksheet.id === response.data),
            response.data,
          );
          this.loadQuestions(this.selectedSheet.schema_id, () => {
            this.$eventBus.$emit('chime', 'Saved');
            this.processing = false;
            this.worksheetIncomplete = this.getWorksheetIncomplete();
          });
        }
      } catch (error) {
        this.$eventBus.$emit('chime', error.response.data.errors[0]);
        this.processing = false;
      }
    },
  },
};
</script>

<style>
.v-item-group .v-btn--active.theme--light i.v-icon.c-white {
  color: white !important;
}
</style>
