<template>
  <v-container
    v-if="course && courseCompletion && lessons && lessonCompletions"
    class="py-6 mxw-1000"
  >
    <CourseHeader
      :cohort-course-completion="cohortCourseCompletion"
      :course="course"
      :course-completion="courseCompletion"
    />

    <div
      v-for="(lesson, index) in lessons"
      :key="lesson.id"
      class="mb-8"
    >
      <LessonCard
        :cohort-id="cohort?.id"
        :cohort-lesson="getCohortLesson(lesson)"
        :course-completion="courseCompletion"
        :index="index"
        :last-lesson-completed-index="lastLessonCompletedIndex"
        :lesson="lesson"
        :lesson-completion="lessonCompletion(lesson)"
      />
    </div>
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import CourseHeader from '@/manager/components/CourseHeader.vue';
import LessonCard from '@/manager/components/lessons/LessonCard.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();

const cohort = ref(null);
const cohortCourseCompletion = ref(null);
const cohortLessons = ref(null);
const course = ref(null);
const courseCompletion = ref(null);
const lastLessonCompletedIndex = ref(null);
const lessons = ref(null);
const lessonCompletions = ref(null);

onMounted(() => void load());

function getCohortLesson(lesson) {
  return cohortLessons.value?.find(
    (cohortLesson) => cohortLesson.relationships.lesson.data.id === lesson.id,
  );
}

function getLastLessonCompletedIndex() {
  if (!lessons.value || !lessonCompletions.value) return null;
  if (lessonCompletions.value.length === 0) return -1;

  const lastCompletedIndex = lessons.value.findIndex(
    (lesson) =>
      !lessonCompletions.value.find((lessonCompletion) => {
        return lessonCompletion.completed && lessonCompletion.lesson_id === lesson.id;
      }),
  );

  if (lastCompletedIndex === -1) return lessons.value.length;

  return lastCompletedIndex;
}

function lessonCompletion(lesson) {
  return lessonCompletions.value?.find(
    (lessonCompletion) => lessonCompletion.lesson_id === lesson.id,
  );
}

async function load() {
  await loadCourse();
  await loadLessons();
  await loadCourseCompletions();
  await loadCoursesFromCohorts();

  if (cohort.value) {
    void loadCohortCourseCompletion();
    await loadCohortLessons();
  }

  if (!courseCompletion.value) {
    const response = await Api.manager.course_completion.create({
      course_id: route.params.courseId,
    });
    courseCompletion.value = response.data;
    lessonCompletions.value = [];
  } else {
    const response = await Api.lesson_completion.index({
      course_completion_id: courseCompletion.value.id,
    });
    lessonCompletions.value = response.data;
  }

  lastLessonCompletedIndex.value = getLastLessonCompletedIndex();
}

async function loadCourse() {
  const response = await Api.public_api.organization.course.get(route.params.courseId);
  course.value = response.data;
}

async function loadCohortCourseCompletion() {
  if (!courseCompletion.value) return;

  const params = {
    filter: {
      cohort: cohort.value.id,
      course_completion: courseCompletion.value.id,
    },
  };
  const response = await Api.cohortCourseCompletion.index(params);

  cohortCourseCompletion.value = response?.data?.data[0] || null;
}

async function loadCohortLessons() {
  const response = await Api.cohortLesson.index({
    filter: {
      cohort: cohort.value.id,
    },
    include: 'lesson',
  });
  cohortLessons.value = response?.data.data || [];
}

async function loadCourseCompletions() {
  const response = await Api.manager.course_completion.index({});
  courseCompletion.value = response.data.find(
    (courseCompletion) => courseCompletion.course_id === route.params.courseId,
  );
}

async function loadCoursesFromCohorts() {
  const cohortMembersResponse = await Api.cohortMember
    .index({ include: 'cohort' })
    .catch((error) => {
      eventBus.chime(error.response.data.errors[0]);
    });

  const cohortMembers = cohortMembersResponse?.data?.data || [];

  if (!cohortMembers.length) return;

  await Promise.all(
    cohortMembers.map(async (cohortMember) => {
      const cohortId = cohortMember.relationships?.cohort?.data?.id;
      if (!cohortId) return;

      const cohortResponse = await Api.cohort.get(cohortId);

      const courseId = cohortResponse?.data?.data?.relationships?.course?.data?.id;
      if (!courseId) return;

      if (route.params.courseId === courseId) cohort.value = cohortResponse.data.data;
    }),
  );
}

async function loadLessons() {
  const response = await Api.public_api.organization.lesson.index({
    course_id: route.params.courseId,
  });
  lessons.value = response.data;
}
</script>
