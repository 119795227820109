<template>
  <div>
    <div class="mxw-300 mx-auto">
      <v-btn
        @click="open"
        color="primary"
        size="x-large"
        block
        rounded
      >
        <v-icon start> add </v-icon>
        <span>
          {{ $t('Request') }}
        </span>
      </v-btn>
    </div>

    <v-dialog
      v-model="newDialogIsVisible"
      max-width="600"
      persistent
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-title>{{ $t('New request') }}</v-card-title>

        <v-divider class="mb-6" />

        <v-card-text>
          <v-row
            class="mb-4"
            dense
          >
            <v-col
              class="fs-16"
              cols="12"
            >
              <span>
                {{ $t('Location') }}
              </span>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="providerId"
                @update:model-value="loadAssessmentPrograms"
                :aria-label="$t('Location')"
                :items="providers"
                item-title="name"
                item-value="id"
                placeholder="Location"
                variant="filled"
                hide-details
                hide-no-data
              />
            </v-col>
          </v-row>

          <v-row
            class="mb-2"
            dense
          >
            <v-col
              class="labeled-input"
              cols="12"
              >{{ $t('Type') }}</v-col
            >
            <v-col cols="12">
              <v-select
                v-model="assessmentProgram"
                :aria-label="$t('Type')"
                :items="assessmentPrograms"
                item-title="name"
                item-value="id"
                variant="filled"
                hide-details
                return-object
              />
            </v-col>
          </v-row>

          <v-alert
            v-if="assessmentProgram?.provider_request_disclaimer"
            color="primary"
            type="info"
          >
            <span>
              {{ $t(assessmentProgram.provider_request_disclaimer) }}
            </span>
          </v-alert>
        </v-card-text>

        <v-divider class="mt-4 mb-6" />

        <v-card-actions>
          <v-btn
            @click="newDialogIsVisible = false"
            size="x-large"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="draft()"
            :disabled="!providerId || !assessmentProgram"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="assessmentProgram?.request_form_schema_id">
      <FormDialog
        @save="create"
        ref="requestDialog"
        :provider-id="providerId"
        :schema-id="assessmentProgram.request_form_schema_id"
      />
    </template>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import FormDialog from '@/shared/components/form/FormDialog.vue';
import { sortBy } from 'lodash';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FormDialog,
  },

  mixins: [API],

  data() {
    return {
      assessmentProgram: null,
      assessmentPrograms: [],
      providerId: null,
      newDialogIsVisible: false,
      processing: false,
      providers: [],
      requestDisclaimer: null,
    };
  },

  created() {
    this.load();
  },

  watch: {
    provider(newValue) {
      if (newValue) this.loadAssessmentPrograms(newValue);
    },
  },

  methods: {
    create(form) {
      this.processing = true;
      this.api.manager.assessment.create(
        { provider_id: this.providerId, schema_id: this.assessmentProgram.id },
        (response) => {
          this.processing = false;
          this.newDialogIsVisible = false;

          if (form) {
            this.api.manager.provider.form.update(
              this.providerId,
              form.id,
              { assessment_id: response.data.id },
              () => {
                this.$router.push({
                  name: 'AssessmentShow',
                  params: { assessmentId: response.data.id },
                });
              },
            );
          } else {
            this.$router.push({
              name: 'AssessmentShow',
              params: { assessmentId: response.data.id },
            });
          }
        },
        (error) => {
          this.processing = false;
          this.$eventBus.$emit('error', error);
        },
      );
    },

    draft() {
      const failedCriteria = this.assessmentProgram?.eligibility
        .filter((eligibility) => !eligibility.eligible)
        .map((eligibility) => eligibility.message);

      if (failedCriteria.length > 0) return this.$eventBus.$emit('error', failedCriteria);

      if (this.assessmentProgram?.request_form_schema_id) {
        this.$refs.requestDialog.open();
      } else {
        this.create();
      }

      return null;
    },

    open() {
      this.providerId = null;
      this.assessmentProgram = null;
      this.newDialogIsVisible = true;
    },

    async load() {
      const { data } = await this.api.manager.provider.index();
      this.providers = data;
    },

    async loadAssessmentPrograms(providerId) {
      const response = await this.api.manager.provider.assessment_program.index(providerId);
      this.assessmentPrograms = response.data
        .filter((assessmentProgram) => !!this.$store.state.schemas[assessmentProgram.id])
        .filter(
          (assessmentProgram) =>
            this.$store.state.schemas[assessmentProgram.id].meta.enable_provider_requests,
        );

      this.assessmentPrograms = sortBy(this.assessmentPrograms, [(assessment) => assessment.name]);
    },
  },
};
</script>
