<template>
  <v-window-item value="Notes">
    <v-row>
      <LabeledTextarea
        v-model="draftNotes"
        message="Notes"
        rows="3"
      />
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          @click="processSave"
          color="primary"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-window-item>
</template>

<script>
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledTextarea,
  },

  props: {
    notes: {
      type: String,
      default: null,
    },
  },

  emits: ['save'],

  data() {
    return {
      draftNotes: this.notes,
    };
  },

  methods: {
    processSave() {
      this.$emit('save', this.draftNotes);
    },
  },

  watch: {
    notes(newVal) {
      this.draftNotes = newVal;
    },
  },
};
</script>
