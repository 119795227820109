<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    class="px-2 py-2"
    data-cy="row"
    border
    flat
    tile
  >
    <v-row
      :class="enrollmentRowClasses"
      role="row"
      dense
    >
      <TableCell
        @check="$emit('check')"
        :check-value="enrollment.checked"
        :checkable="true"
        class="v-col-sm-3 v-col-md-3"
        check-locked
      >
        {{ enrollment.meta.child.name }}

        <v-badge
          v-if="!enrollment.seen_by_provider_at"
          :content="$t('NEW')"
          :label="$t('New enrollment')"
          color="primary"
          text-color="white"
          inline
        />
      </TableCell>

      <TableCell
        :content="enrollment.meta.child.dob"
        cols="off"
        md="1"
        sm="12"
      />

      <TableCell
        :content="[enrollment.meta.group.home_address, enrollment.meta.group.home_city].join(' ')"
        cols="off"
        md="2"
        sm="12"
      />

      <TableCell
        :content="enrollment.meta.provider.name"
        cols="off"
        md="2"
        sm="12"
      />

      <template v-if="enableDeferredAcceptance">
        <TableCell
          :content="enrollment.meta.program?.name"
          cols="off"
          md="1"
          sm="12"
        />
      </template>

      <TableCell
        :content="getStatusText(enrollment.status)"
        cols="off"
        md="1"
        sm="12"
      />

      <template v-if="enableDeferredAcceptance">
        <TableCell
          :content="enrollment.due_date"
          cols="off"
          md="1"
          sm="12"
          transform="date"
        />
      </template>

      <template v-if="$store.state.pages.Interested.features.enable_waitlist">
        <v-col
          cols="12"
          md="2"
        >
          <template v-if="enrollment.status == 'Waitlisted'">
            <v-select
              v-model="enrollment.waitlist_number"
              @click.stop=""
              @update:model-value="$emit('waitlist', $event)"
              :items="waitlistNumbers"
              class="mxw-125"
              density="compact"
              variant="filled"
              hide-details
              rounded
            />
          </template>
        </v-col>
      </template>
    </v-row>

    <v-row
      v-if="hasComputedSubsidyFlag"
      dense
    >
      <v-col cols="12">
        <div
          class="col-alignment"
          data-testid="computedSubsidyFlags"
        >
          <v-chip
            v-if="enrollment?.meta?.computed_subsidy_flags?.iep"
            class="mr-2"
            color="primary"
          >
            {{ $t('Individualized education plan') }}
          </v-chip>

          <v-chip
            v-if="enrollment?.meta?.computed_subsidy_flags?.current_provider"
            class="mr-2"
            color="primary"
          >
            {{ $t('Current student') }}
          </v-chip>

          <v-chip
            v-if="enrollment?.meta?.computed_subsidy_flags?.sibling_provider"
            class="mr-2"
            color="primary"
          >
            {{ $t('Sibling of student') }}
          </v-chip>

          <v-chip
            v-if="enrollment?.meta?.computed_subsidy_flags?.staff_provider"
            color="primary"
          >
            {{ $t('Family is staff') }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Statuses from '@/shared/mixins/statuses';
import TableCell from '@/shared/components/TableCell.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    TableCell,
  },

  mixins: [Statuses],

  props: {
    enrollment: {
      type: Object,
      default: null,
    },
    enableDeferredAcceptance: Boolean,
  },

  emits: ['check', 'waitlist'],

  data() {
    return {
      waitlistNumbers: [...Array(200).keys()].map((index) => index + 1),
    };
  },

  computed: {
    enrollmentRowClasses() {
      const classes = ['d-flex', 'align-center', 'fs-16'];

      if (this.enrollment.seen_by_provider_at) return classes;

      return classes.concat(['fw-900']).join(' ');
    },

    hasComputedSubsidyFlag() {
      const { computed_subsidy_flags: computedSubsidyFlags } = this.enrollment.meta;

      return (
        computedSubsidyFlags?.current_provider ||
        computedSubsidyFlags?.sibling_provider ||
        computedSubsidyFlags?.staff_provider ||
        computedSubsidyFlags?.iep
      );
    },
  },
};
</script>

<style>
.col-alignment {
  margin-left: 38px;
}
</style>
