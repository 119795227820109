<template>
  <v-card-text>
    <div class="d-flex align-center justify-center mt-4 mb-8">
      <v-text-field
        v-model="query"
        class="me-3 mxw-600"
        prepend-inner-icon="search"
        variant="filled"
        hide-details
        rounded
      />
    </div>

    <v-card
      v-for="agreement in agreements"
      :key="agreement.id"
      class="mb-0"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <v-col class="d-flex align-center">
            <div>
              <router-link
                :to="{ query: { agreementId: agreement.id } }"
                class="c-primary underlined pointer"
              >
                {{ agreement.name }}
              </router-link>
              <div class="fs-14">
                <LongDateTime
                  :date="agreement.sent_at"
                  prefix="Sent"
                />
              </div>
            </div>
          </v-col>
          <v-col class="d-flex align-center justify-end">
            <template v-if="agreement.submitted_at">
              <v-icon
                color="green"
                size="32"
              >
                check
              </v-icon>
            </template>
            <template v-else>
              <v-btn
                :to="{ query: { agreementId: agreement.id } }"
                color="primary"
              >
                <span v-t="'Review and sign'" />
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <NullState
      v-if="agreements.length == 0"
      title="No agreements assigned"
      hide-new-button
    />

    <AgreementDialog
      @change="load"
      role="manager"
    />
  </v-card-text>
</template>

<script>
import Api from '@/manager/services/bright_finder';
import AgreementDialog from '@/shared/components/AgreementDialog.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import NullState from '@/shared/components/NullState.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AgreementDialog,
    LongDateTime,
    NullState,
  },

  props: {
    api: {
      type: Object,
      default() {
        return Api;
      },
    },
  },

  data() {
    return {
      agreements: [],
      query: null,
      selectedAgreementId: null,
    };
  },

  created() {
    this.load();
  },

  methods: {
    load() {
      this.api.manager.agreement.index({ query: this.query }, (resp) => {
        this.agreements = resp.data;
      });
    },
  },
};
</script>
