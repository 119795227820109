<template>
  <v-card
    border
    flat
    tile
  >
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col>
          <span class="fs-16 fw-600">
            {{ $t(schema.name) }}
          </span>
        </v-col>
        <v-col class="d-flex justify-end">
          <template v-if="form">
            <v-btn
              @click="$emit('open')"
              color="primary"
            >
              <span>{{ $t('Review') }}</span>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              @click="$emit('start')"
              color="primary"
            >
              <v-icon start> priority_high </v-icon>
              <span>Start</span>
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    form: {
      type: Object,
      default: null,
    },
    schemaId: {
      type: String,
      default: null,
    },
  },

  emits: ['open', 'start'],

  computed: {
    schema() {
      return this.$store.state.schemas[this.schemaId];
    },
  },
};
</script>
