<template>
  <div>
    <v-card
      v-for="assignment in assignments"
      @click="onAssignmentClick(assignment)"
      :key="assignment.id"
      :active="assignmentIsActive(assignment)"
      :color="assignmentIsActive(assignment) ? '#D2DEE1' : ''"
      class="my-3 mx-4"
      border
      flat
    >
      <v-card-text
        class="fs-18 font-weight-bold"
        color="primary"
      >
        <v-row>
          <v-col cols="11">
            <span>
              {{ $t(assignment.name) }}
            </span>
          </v-col>

          <v-col
            class="d-flex justify-end align-center"
            cols="1"
          >
            <v-icon
              icon="chevron_right"
              size="24"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="assignmentIsActive(assignment) && assignmentHasActivities(assignment)">
        <span class="fw-400 fs-16">
          {{ $t('Activities') }}
        </span>

        <v-card
          class="my-2"
          color="#E9EFF1"
          flat
        >
          <v-card-text>
            <AssignmentActivity
              :class="
                assignment.required_comment_count > 0 || assignment.required_comment_reply_count > 0
                  ? 'mb-2'
                  : ''
              "
              :completed="!!getAssignmentCompletion(assignment)?.homework_attachment_id"
              :enabled="assignment.required_homework_file_upload"
              name="Upload homework"
            />

            <AssignmentActivity
              :class="assignment.required_comment_reply_count > 0 ? 'mb-2' : ''"
              :completed="
                getAssignmentCompletion(assignment)?.comment_count >=
                assignment.required_comment_count
              "
              :enabled="assignment.enable_comments && assignment.required_comment_count > 0"
              :name="`${$t('Post new comment')} (${getAssignmentCompletion(assignment)?.comment_count} ${$t('of')} ${assignment.required_comment_count})`"
            />

            <AssignmentActivity
              :completed="
                getAssignmentCompletion(assignment)?.comment_reply_count >=
                assignment.required_comment_reply_count
              "
              :enabled="assignment.enable_comments && assignment.required_comment_reply_count > 0"
              :name="`${$t('Reply to a comment')} (${getAssignmentCompletion(assignment)?.comment_reply_count} ${$t('of')} ${assignment.required_comment_reply_count})`"
            />
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import AssignmentActivity from '@/manager/components/assignments/AssignmentActivity.vue';

const props = defineProps({
  assignments: {
    type: Array,
    default: null,
  },
  assignmentCompletions: {
    type: Array,
    default: null,
  },
  course: {
    type: Object,
    default: null,
  },
  currentAssignment: {
    type: Object,
    default: null,
  },
  currentAssignmentIndex: {
    type: Number,
    default: null,
  },
  tab: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['transition']);

function assignmentIsActive(assignment) {
  return props.currentAssignment?.id === assignment.id;
}

function assignmentHasActivities(assignment) {
  return (
    props.course?.restricted &&
    (assignment.required_homework_file_upload ||
      (assignment.enable_comments && assignment.required_comment_count > 0) ||
      (assignment.enable_comments && assignment.required_comment_reply_count > 0))
  );
}

function getAssignmentCompletion(assignment) {
  return props.assignmentCompletions.find(
    (assignmentCompletion) => assignmentCompletion.assignment_id === assignment.id,
  );
}

function onAssignmentClick(assignmentClicked) {
  const assignmentIndex = props.assignments.findIndex(
    (assignment) => assignment.id === assignmentClicked.id,
  );

  if (assignmentIndex === props.currentAssignmentIndex) return;

  if (assignmentIndex > props.currentAssignmentIndex) {
    if (!getAssignmentCompletion(props.currentAssignment)?.completed) return;
  }

  emit('transition', assignmentClicked);
}
</script>
