<template>
  <v-card
    border
    flat
    tile
  >
    <v-img
      v-if="schema"
      :src="schema?.image_url"
      max-height="300"
    />
    <v-card-title
      v-if="schema"
      data-testid="assessment-schema-name"
      >{{ $t(schema?.name) }}</v-card-title
    >
    <v-card-subtitle>
      <span data-testid="assessment-provider-name">{{ assessment.provider?.name }}</span>
      <br />
      <span data-testid="assessment-provider-address1">{{ assessment.provider?.address1 }}</span>
    </v-card-subtitle>
    <v-card-subtitle v-if="schema?.description">{{ $t(schema?.description) }}</v-card-subtitle>
    <v-divider />
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col
          class=""
          cols="12"
          md="6"
        >
          <div
            v-t="'Status'"
            class="c-light-black fs-16 fw-500"
          />
          <div class="fs-16 d-flex align-center">
            <v-icon
              color="primary"
              start
            >
              {{ statusIcon }}
            </v-icon>
            <span data-testid="assessment-status">{{ $t(assessment.status || 'New') }}</span>
          </div>
        </v-col>
        <v-col
          class="ta-right"
          cols="12"
          md="6"
        >
          <v-btn
            :to="{ name: 'AssessmentShow', params: { assessmentId: assessment.id } }"
            color="primary"
            size="large"
          >
            <span v-t="'Continue'" />
            <v-icon
              class="pl-2"
              size="small"
            >
              arrow_forward_ios
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    assessment: { type: Object, default: null },
  },

  data() {
    return {
      schema: this.$store.state.schemas[this.assessment.schema_id],
    };
  },

  computed: {
    statusIcon() {
      if (this.assessment.completed_at) {
        return 'check_circle';
      }
      return 'incomplete_circle';
    },
  },
};
</script>
