<template>
  <div v-if="subsidyPrograms">
    <template v-if="subsidyPrograms.length > 1">
      <v-row class="bg-primary pt-3 mb-2">
        <v-col class="d-flex justify-center py-6">
          <v-select
            v-model="selectedSubsidyProgram"
            @update:model-value="onSubsidyProgramChange()"
            :item-title="(subsidyProgram) => $t(subsidyProgram.name)"
            :items="subsidyPrograms"
            class="mxw-400"
            density="comfortable"
            item-value="id"
            variant="solo"
            hide-details
            return-object
          />
        </v-col>
      </v-row>
    </template>

    <v-container
      v-if="enrollmentRequests"
      class="mxw-1400"
    >
      <v-card
        border
        flat
        tile
      >
        <v-card-title role="heading">
          {{ $t($store.state.pages.Interested.title || 'Interested') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-alert
            v-if="$store.state.site.features.enable_tours && providerRequiresSetup"
            @click="
              $router.push({
                name: 'ProviderShow',
                params: { providerId: providerRequiresSetup.id },
                query: { tab: 'tours' },
              })
            "
            border="start"
            class="pointer"
            color="primary"
            elevation="2"
            icon="notification_important"
            prominent
          >
            <v-row class="d-flex align-center">
              <v-col class="grow">
                <span class="fs-18">
                  {{ $t('Get more interested families by enabling tour scheduling') }}
                </span>
              </v-col>

              <v-col class="ta-right">
                <v-icon
                  color="primary"
                  size="30"
                >
                  east
                </v-icon>
              </v-col>
            </v-row>
          </v-alert>

          <div class="d-flex align-center justify-center my-4">
            <v-text-field
              v-model="query"
              :aria-label="$t('Search interested families and children by name or child ID')"
              :placeholder="$t('Search interested families and children by name or child ID')"
              class="bg-white"
              prepend-inner-icon="search"
              variant="solo-filled"
              flat
              hide-details
            />
            <v-btn
              v-if="$store.state.pages.Interested.features.enable_create_new"
              @click="$refs.newDialog.open()"
              :aria-label="$t('Create new')"
              class="ms-3"
              color="primary"
              data-cy="add-button"
              elevation="0"
              size="small"
              variant="text"
              icon
            >
              <v-icon>add</v-icon>
            </v-btn>

            <ResourceDialog
              @save="create"
              ref="newDialog"
              :fields="newFields"
              :max-width="600"
              :processing="processing"
              title="Add"
            />
          </div>

          <v-row class="d-flex align-center mb-0">
            <v-col
              class="d-flex align-center"
              cols="12"
              md="4"
            >
              <v-btn
                @click="selectAll()"
                class="me-3"
                color="primary"
                data-cy="select-all-button"
                elevation="0"
              >
                <span>{{ $t('Select all') }}</span>
              </v-btn>

              <v-menu>
                <template #activator="{ props }">
                  <v-btn
                    v-show="displayEditCheckedButton"
                    v-bind="props"
                    :loading="processing"
                    color="primary"
                    data-cy="edit-button"
                    elevation="2"
                  >
                    <span>{{ $t('Edit') }}</span>
                  </v-btn>
                </template>
                <v-list
                  v-show="displayEditCheckedOptions"
                  data-testid="edit-checked-options"
                >
                  <v-list-item
                    v-for="status in pendingStatuses"
                    @click="setStatusForAll(status.value)"
                    :key="status.value"
                  >
                    <v-list-item-title>
                      <span class="me-1">
                        {{ $t('Change to') }}
                      </span>

                      <span>{{ $t(status.text) }}</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="draftEnrollAll()"
                    :disabled="enrollAllDisabled"
                  >
                    <v-list-item-title>{{ $t(terms.enroll) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn
                v-if="$store.state.pages.Interested.features.enable_mass_messaging"
                v-show="checkedEnrollmentRequests.length > 0"
                @click="$refs.newMessageDialog.open()"
                :loading="processing"
                class="ms-3"
                color="primary"
                data-cy="message-button"
                elevation="2"
              >
                <span>{{ $t('Message') }}</span>
              </v-btn>

              <v-btn
                v-show="areValidEnrollmentsWithoutSubsidyPrograms()"
                @click="$refs.transferSelectedEnrollmentsDialog.open()"
                class="ms-3"
                color="primary"
                data-cy="transfer-button"
                elevation="2"
              >
                <span>{{ $t('Transfer') }}</span>
              </v-btn>

              <ResourceDialog
                @save="sendMassMessage"
                ref="newMessageDialog"
                :processing="processing"
                :save-button-disabled="!massMessage.subject || !massMessage.body"
                save-button-text="Send"
                title="New message"
              >
                <template #form>
                  <v-row>
                    <LabeledTextfield
                      v-model="massMessage.subject"
                      message="Subject"
                    />

                    <LabeledTextarea
                      v-model="massMessage.body"
                      message="Body"
                    />
                  </v-row>
                </template>
              </ResourceDialog>

              <ResourceDialog
                @save="transferSelectedEnrollments()"
                ref="transferSelectedEnrollmentsDialog"
                :max-width="800"
                :processing="processing"
                :save-button-text="$t('Yes, transfer children')"
                :title="$t('Transfer children')"
                closeable
              >
                <template #form>
                  <v-row>
                    <span>
                      {{
                        $t(
                          'Selecting "Yes, transfer children" will move all children to the chosen location. Their status will not change.',
                        )
                      }}
                    </span>

                    <v-divider />

                    <LabeledSimpleSelect
                      v-model="selectedEnrollAllProviderId"
                      :items="providers"
                      :message="$t('Transfer to:')"
                      :placeholder="$t('Select a location...')"
                      data-cy="provider-transfer-dropdown"
                      item-title="name"
                      item-value="id"
                      variant="filled"
                    />
                  </v-row>
                </template>
              </ResourceDialog>

              <v-pagination
                v-if="pages > 1"
                v-model="page"
                :length="pages"
                :total-visible="8"
                class="d-inline-block"
              />
            </v-col>

            <v-col
              class="d-flex justify-end"
              cols="12"
              md="6"
            >
              <FilterMenu
                ref="providerFilter"
                :actions="false"
                :title="filteredProviderName || 'Location'"
                classes="ms-3"
                elevation="0"
                width="400"
                borderless
                left
                outlined
              >
                <template #card>
                  <v-autocomplete
                    v-model="filters.provider_id"
                    @update:model-value="$refs.providerFilter.close()"
                    :item-title="providerNameWithAddress"
                    :items="providers"
                    :placeholder="$t('Search by name or address')"
                    item-value="id"
                    variant="filled"
                    auto-select-first
                    clearable
                    left
                  />
                </template>
              </FilterMenu>

              <template
                v-if="selectedSubsidyProgram?.enable_deferred_acceptance && filteredProviderName"
              >
                <FilterMenu
                  :actions="false"
                  :title="getProgramText(filters.program_id) || terms.program"
                  classes="ms-3"
                  elevation="0"
                  borderless
                  left
                  outlined
                >
                  <template #card>
                    <v-list
                      v-model:selected="filters.program_id"
                      density="compact"
                    >
                      <v-list-item
                        v-for="program in programs"
                        :key="program.id"
                        :title="$t(program.name)"
                        :value="program.id"
                        color="primary"
                        data-cy="availability-checkmark"
                      >
                        <template #prepend="{ isSelected, select }">
                          <v-checkbox-btn
                            @update:model-value="select"
                            :model-value="isSelected"
                            density="compact"
                            tabindex="-1"
                          >
                            <template #input="{ inputNode, icon }">
                              <v-icon
                                :icon="icon"
                                size="x-small"
                              />

                              <component :is="inputNode" />
                            </template>
                          </v-checkbox-btn>
                        </template>
                      </v-list-item>
                    </v-list>
                  </template>
                </FilterMenu>
              </template>

              <FilterMenu
                :actions="false"
                :title="getStatusText(filters.status) || 'Status'"
                classes="ms-3"
                elevation="0"
                borderless
                left
                outlined
                paddingless
              >
                <template #card>
                  <v-list
                    v-model:selected="filters.status"
                    density="compact"
                  >
                    <v-list-item
                      v-for="status in allStatuses"
                      :key="status.value"
                      :title="$t(status.text)"
                      :value="status.value"
                      color="primary"
                      data-cy="availability-checkmark"
                    >
                      <template #prepend="{ isSelected, select }">
                        <v-checkbox-btn
                          @update:model-value="select"
                          :model-value="isSelected"
                          density="compact"
                          tabindex="-1"
                        >
                          <template #input="{ inputNode, icon }">
                            <v-icon
                              :icon="icon"
                              size="x-small"
                            />

                            <component :is="inputNode" />
                          </template>
                        </v-checkbox-btn>
                      </template>
                    </v-list-item>
                  </v-list>
                </template>
              </FilterMenu>

              <v-menu location="left">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    :loading="processing"
                    icon="more_vert"
                    size="small"
                    variant="text"
                  />
                </template>

                <v-list>
                  <v-list-item @click="download('csv')">
                    <v-list-item-title>
                      {{ $t('Export to CSV for Excel') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <ResourceDialog
                @save="closeInviteDialog()"
                ref="inviteDialog"
                :cancellable="false"
                :closeable="true"
                :max-width="600"
                :save-button-disabled="programs.length === 0"
                save-button-text="Done"
                sub-title="Use this referral link to invite a child to apply to your program."
                title="Invite a child"
                hide-actions
              >
                <template #form>
                  <v-row>
                    <v-col cols="12">
                      <div class="fs-14">{{ $t('Select a location') }}:</div>

                      <v-select
                        v-model="selectedInviteProviderId"
                        :items="providers"
                        class="b-radius-0"
                        data-testid="select-provider"
                        item-title="name"
                        item-value="id"
                        variant="solo"
                        clearable
                      />
                    </v-col>
                  </v-row>

                  <div v-if="selectedInviteProviderId">
                    <v-row
                      v-for="program in inviteProgramOptions"
                      :key="program?.id"
                      dense
                    >
                      <CopyableInput
                        :label="program?.name"
                        :value="buildInviteLink(program)"
                      />
                    </v-row>
                  </div>
                </template>
              </ResourceDialog>
            </v-col>

            <v-col
              class="d-flex justify-end"
              cols="12"
              md="2"
            >
              <v-btn
                v-if="selectedSubsidyProgram?.enable_provider_program_referral_links === true"
                @click="$refs.inviteDialog.open()"
                class="focus-very-visible"
                color="primary"
                data-testid="provider-invite-btn"
                tabindex="0"
              >
                <v-icon> people </v-icon>

                <span class="pl-2">
                  {{ $t('Invite') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>

          <v-card
            class="mb-2"
            border
            flat
            tile
          >
            <v-card-text class="fs-15">
              <v-row dense>
                <v-col class="d-flex align-center">
                  <template v-if="filterSummary">
                    <v-icon start> filter_alt </v-icon>

                    <span class="me-1"> {{ $t('Filtering by') }}: </span>

                    <span class="fw-600">
                      {{ $t(filterSummary) }}
                    </span>

                    <span>.&nbsp;</span>
                  </template>
                  <span>
                    {{ $t('Displaying first') }} <strong>{{ enrollmentRequests.length }}</strong>
                    {{ $t('results') }}, {{ $t('out of') }} <strong>{{ total }}</strong>
                    {{ $t('total results') }}.
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            class="px-2 py-4"
            border
            flat
            tile
          >
            <v-row>
              <SortableTableHeader
                @check="selectAll()"
                @toggle="toggleSort($event)"
                :check-value="allChecked"
                :checkable="true"
                :sort-dir="filters.sort_dir"
                :sort-field="filters.sort_field"
                field="name"
                md="3"
                title="Child"
                check-locked
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="filters.sort_dir"
                :sort-field="filters.sort_field"
                field="dob"
                md="1"
                title="DOB"
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="filters.sort_dir"
                :sort-field="filters.sort_field"
                field="address"
                md="2"
                title="Home"
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="filters.sort_dir"
                :sort-field="filters.sort_field"
                field="provider"
                md="2"
                title="Location"
              />

              <template v-if="selectedSubsidyProgram?.enable_deferred_acceptance">
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  :title="terms.program"
                  field="program"
                  md="1"
                />
              </template>

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="filters.sort_dir"
                :sort-field="filters.sort_field"
                field="status"
                md="1"
                title="Status"
              />

              <template v-if="selectedSubsidyProgram?.uses_enrollment_due_dates">
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="due_date"
                  md="1"
                  title="Due"
                />
              </template>

              <template v-if="waitlistEnabled">
                <SortableTableHeader
                  @toggle="toggleSort($event)"
                  :sort-dir="filters.sort_dir"
                  :sort-field="filters.sort_field"
                  field="waitlist"
                  md="2"
                  title="Waitlist"
                />
              </template>
            </v-row>
          </v-card>

          <template v-if="enrollmentRequests.length == 0">
            <NullState
              class="my-8"
              hide-new-button
            />
          </template>

          <div
            v-for="enrollment in enrollmentRequests"
            @click="updateQuery({ enrollmentId: enrollment.id })"
            :key="enrollment.id"
            class="pointer"
          >
            <EnrollmentRequestRow
              @check="enrollment.checked = !enrollment.checked"
              @display="displayStatus(enrollment)"
              @waitlist="setWaitlistNumber(enrollment, $event)"
              :enable-deferred-acceptance="selectedSubsidyProgram?.enable_deferred_acceptance"
              :enrollment="enrollment"
              class="my-2"
            />
          </div>

          <v-pagination
            v-if="pages > 1"
            v-model="page"
            :length="pages"
            :total-visible="8"
            class="mt-4"
          />
        </v-card-text>
      </v-card>

      <VerticalSpacer :min-height="50" />

      <EnrollmentRequestDialog
        @change="load"
        @decline="clearAndReload"
        @enrolled="clearAndReload"
        ref="detailsDialog"
        :providers="providers"
        :statuses="pendingStatuses"
        :subsidy-program="selectedSubsidyProgram"
      />

      <!-- TODO: Move into sub-component -->
      <v-dialog
        v-model="enrollAllDialogIsVisible"
        max-width="500"
        scrim="transparent"
      >
        <v-card
          v-if="checkedEnrollmentRequests.length > 0"
          class="mx-auto"
          max-width="500"
        >
          <v-card-title> {{ terms.enroll }} {{ $t('all selected') }} </v-card-title>

          <v-card-text>
            <p class="c-black fs-16 fw-500 mb-2">
              {{ $t('Location') }}
            </p>

            <v-select
              v-model="selectedEnrollAllProviderId"
              :disabled="!$store.state.pages.Interested.features.enable_provider_transfer"
              :items="providers"
              :readonly="!$store.state.pages.Interested.features.enable_provider_transfer"
              item-title="name"
              item-value="id"
              variant="filled"
            />

            <div
              v-if="$store.state.pages.Interested.features.enable_program_assignment"
              class="mb-6"
            >
              <p class="c-black fs-16 fw-500 mb-2">
                {{ $t(`What program or class will they ${terms.enroll.toLowerCase()} in?`) }}
              </p>

              <v-select
                v-model="programId"
                :disabled="!selectedEnrollAllProviderId"
                :items="enrollAllProgramOptions"
                item-title="name"
                item-value="id"
                variant="filled"
                hide-details
              />
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn
              @click="enrollAll"
              :loading="processing"
              color="primary"
              size="x-large"
              block
            >
              {{ $t(`Yes, ${terms.enroll.toLowerCase()} these students`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <template v-else>
      <v-progress-linear
        class="mt-6 mxw-1400 mx-auto"
        indeterminate
      />
    </template>
  </div>
</template>

<script>
import { scrollTo } from 'vuetify/lib/composables/goto';

import CopyableInput from '@/shared/components/provider/CopyableInput.vue';
import EnrollmentRequestDialog from '@/manager/components/enrollment/EnrollmentRequestDialog.vue';
import EnrollmentRequestRow from '@/manager/components/enrollment/EnrollmentRequestRow.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

import { createDateTimeFileName } from '@/shared/services/date-time-file-name';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';

import API from '@/shared/mixins/api';
import RouterHelper from '@/shared/mixins/RouterHelper';
import Statuses from '@/shared/mixins/statuses';
import Terms from '@/shared/mixins/terms';

export default {
  compatConfig: { MODE: 3 },

  components: {
    CopyableInput,
    EnrollmentRequestDialog,
    EnrollmentRequestRow,
    FilterMenu,
    LabeledTextarea,
    LabeledTextfield,
    LabeledSimpleSelect,
    NullState,
    ResourceDialog,
    SortableTableHeader,
    VerticalSpacer,
  },

  mixins: [API, RouterHelper, Statuses, Terms],

  data() {
    return {
      detailDialogIsVisible: false,
      enrollAllDialogIsVisible: false,
      enrollAllProgramOptions: [],
      enrollmentRequests: null,
      filters: {
        provider_id: this.$route.query.providerId,
        program_id: this.$route.query.programId,
        status: this.$route.query.status,
        sort_dir: 'asc',
        sort_field: 'waitlist',
      },
      inviteProgramOptions: [],
      massMessage: { subject: null, body: null },
      page: 1,
      pages: null,
      processing: false,
      programId: null,
      programs: [],
      providerFilter: null,
      providerRequiresSetup: null,
      providers: [],
      query: null,
      queryCount: 0,
      selectedEnrollAllProviderId: null,
      selectedInviteProviderId: null,
      selectedSubsidyProgram: null,
      subsidyDialogIsVisible: false,
      subsidyPrograms: null,
    };
  },

  computed: {
    allChecked() {
      return (
        this.enrollmentRequests.length > 0 &&
        this.checkedEnrollmentRequests.length === this.enrollmentRequests.length
      );
    },

    checkedEnrollmentRequests() {
      return this.enrollmentRequests.filter((enrollmentRequest) => enrollmentRequest.checked);
    },

    displayEditCheckedButton() {
      if (this.selectedSubsidyProgram?.enable_deferred_acceptance) return false;

      return this.checkedEnrollmentRequests.length > 0;
    },

    displayEditCheckedOptions() {
      return this.checkedEnrollmentRequests.length > 0;
    },

    enrollAllDisabled() {
      return this.checkedEnrollmentRequests.some(
        (enrollmentRequest) =>
          enrollmentRequest.provider_id !== this.checkedEnrollmentRequests[0].provider_id,
      );
    },

    filterSummary() {
      const appliedFilters = [];
      if (this.filteredProviderName) appliedFilters.push(this.filteredProviderName);
      if (this.filters.status)
        appliedFilters.push(this.$t(this.getStatusText(this.filters.status)));
      if (this.filteredProgramName) appliedFilters.push(this.filteredProgramName);

      return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
    },

    newFields() {
      return [
        {
          cols: '6',
          text: 'Parent first name',
          value: 'primary_first_name',
          required: true,
        },
        {
          cols: '6',
          text: 'Parent last name',
          value: 'primary_last_name',
          required: true,
        },
        { text: 'Parent email', value: 'primary_email' },
        { text: 'Parent phone', value: 'primary_phone' },
        {
          cols: '6',
          text: 'Child first name',
          value: 'child_first_name',
          required: true,
        },
        {
          cols: '6',
          text: 'Child last name',
          value: 'child_last_name',
          required: true,
        },
        {
          text: 'Child DOB',
          value: 'child_dob',
          required: true,
          type: 'dob',
        },
        {
          text: 'Location',
          value: 'provider_id',
          required: true,
          items: this.providers,
          itemText: 'name',
          itemValue: 'id',
        },
      ];
    },

    filteredProgramName() {
      if (this.filters.program_id && this.filters.provider_id) {
        return this.programs.find((program) => program.id === this.filters.program_id)?.name;
      }

      return null;
    },

    filteredProviderName() {
      if (this.filters.provider_id && this.providers.length > 0) {
        return this.providers.find((provider) => provider.id === this.filters.provider_id)?.name;
      }

      return null;
    },

    providersRequiringSetup() {
      return this.providers.filter((provider) => !provider.tours_enabled);
    },

    waitlistEnabled() {
      return this.$store.state.pages.Interested.features.enable_waitlist;
    },
  },

  watch: {
    'filters.provider_id': {
      immediate: true,
      handler() {
        this.loadPrograms(this.filters.provider_id);

        if (this.filters.program_id) {
          this.filters.program_id = null;
          return; // The change above will trigger watch that calls load
        }

        if (this.page !== 1) {
          this.page = 1;
          return; // The change above will trigger watch that calls load
        }

        this.load();
      },
    },

    // eslint-disable-next-line func-names
    'filters.status': function () {
      if (this.page !== 1) {
        this.page = 1;
        return; // The change above will trigger watch that calls load
      }

      this.load();
    },

    // eslint-disable-next-line func-names
    'filters.program_id': function () {
      if (this.page !== 1) {
        this.page = 1;
        return; // The change above will trigger watch that calls load
      }

      this.load();
    },

    page() {
      this.load();
      scrollTo(0);
    },

    query(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.page !== 1) {
          this.page = 1;
          return; // The change above will trigger watch that calls load
        }

        this.load();
      }
    },

    selectedEnrollAllProviderId(newValue) {
      this.loadEnrollAllProgramOptions(newValue);
    },

    selectedInviteProviderId(newValue) {
      this.loadInviteProgramOptions(newValue);
    },
  },

  created() {
    this.load();
  },

  methods: {
    closeInviteDialog() {
      this.selectedInviteProviderId = null;
      this.$refs.inviteDialog.close();
    },

    buildInviteLink(program) {
      const baseUrl = this.$store.state.config?.parent_url;
      const { subsidyProgramId } = this.$route.query;
      const path = `/provider_referral?subsidyProgramId=${subsidyProgramId}&programId=${program?.id}`;

      return `${baseUrl}${path}`;
    },

    clearAndReload() {
      this.$refs.detailsDialog.close();
      this.load();
    },

    create(newReq) {
      if (!newReq.primary_email && !newReq.primary_phone) {
        return this.$eventBus.$emit('chime', 'A parent email or phone number is required');
      }

      this.processing = true;

      const thisNewRew = newReq;
      thisNewRew.status = 'Selected';

      this.api.manager.enrollment.create(
        thisNewRew,
        () => {
          this.$refs.newDialog.close();
          this.processing = false;
          this.$eventBus.$emit('chime', 'Added');
          this.load();
        },
        (error) => {
          this.processing = false;
          if (error.response.data.errors[0] === 'Family already exists') {
            this.$eventBus.$emit(
              'chime',
              this.$store.state.pages.Interested.content.duplicate_family_message,
            );
          } else {
            this.$eventBus.$emit('chime', error.response.data.errors[0]);
          }
        },
      );
      return true;
    },

    getProgramText(programValue) {
      return this.programs.find((program) => program.id === programValue)?.name || programValue;
    },

    draftEnrollAll() {
      this.selectedEnrollAllProviderId = this.checkedEnrollmentRequests[0].provider_id;
      this.enrollAllDialogIsVisible = true;
    },

    areValidEnrollmentsWithoutSubsidyPrograms() {
      const validTransferStatuses = ['Selected', 'Proposed', 'Placed', 'Offered', 'Accepted'];
      if (this.checkedEnrollmentRequests.length > 0) {
        return this.checkedEnrollmentRequests.every(
          (enrollment) =>
            enrollment.subsidy_program_id == null &&
            validTransferStatuses.includes(enrollment.status),
        );
      }
    },

    async download(format) {
      const downloadParams = {};

      downloadParams.subsidy_program_id = this.selectedSubsidyProgram?.id;
      if (this.filters.provider_id) {
        downloadParams.provider_id = this.filters.provider_id;
      }
      downloadParams.status =
        this.filters.status || this.pendingStatuses.map((status) => status.value);
      const filename = createDateTimeFileName(
        this.$store.state.pages.Interested.title || 'Interested',
        this.$store.state.profile.default_locale,
      );
      const url = await this.api.manager.enrollment.downloadUrl(downloadParams, filename, format);

      window.open(url);
    },

    async enrollAll() {
      this.processing = true;

      const providerId = this.checkedEnrollmentRequests[0].provider_id;
      await Promise.all(
        this.checkedEnrollmentRequests.map(async (enrollmentRequest) => {
          this.api.manager.enrollment.update(enrollmentRequest.id, {
            provider_id: providerId,
            program_id: this.programId,
            status: ENROLLMENT_STATUSES.ENROLLED,
          });
        }),
      );
      setTimeout(() => {
        this.enrollAllDialogIsVisible = false;
        this.load();
      }, 2000);
    },

    async load() {
      this.processing = true;
      this.setSubsidyProgramIdQuery();
      await this.loadSubsidyPrograms();

      await this.loadProviders();
      if (
        this.filters.provider_id &&
        !this.providers.find((provider) => provider.id === this.filters.provider_id)
      ) {
        this.filters.provider_id = null;
        return; // The change above will trigger watch that calls load
      }

      this.filters.page = this.page;
      this.filters.query = this.query;
      this.queryCount += 1;
      const currentQueryCount = this.queryCount;
      const newFilters = { ...this.filters };

      if (!newFilters.status)
        newFilters.status = this.pendingStatuses.map((status) => status.value);

      if (this.selectedSubsidyProgram?.id)
        newFilters.subsidy_program_id = this.selectedSubsidyProgram?.id;

      this.api.manager.enrollment.index(
        { ...newFilters, include: 'computed_subsidy_flags' },
        (response) => {
          if (this.queryCount === currentQueryCount) {
            this.enrollmentRequests = response.data.map((enrollmentRequest) => {
              const thisEr = enrollmentRequest;
              thisEr.checked = false;
              return thisEr;
            });
            this.pages = parseInt(response.headers['x-page-count'] || 0, 10);
            this.total = parseInt(response.headers['x-total-count'] || 0, 10);
            this.processing = false;
          }
        },
      );
    },

    async loadEnrollAllProgramOptions(providerId) {
      if (!providerId) {
        this.enrollAllProgramOptions = [];
        return;
      }

      const programFilters = {
        subsidy_program_id: this.selectedSubsidyProgram?.id,
      };
      const { data } = await this.api.program.index(providerId, programFilters);
      this.enrollAllProgramOptions = data;
    },

    async loadInviteProgramOptions(providerId) {
      if (!providerId) {
        this.inviteProgramOptions = [];
        return;
      }

      const programFilters = {
        subsidy_program_id: this.selectedSubsidyProgram?.id,
      };
      const { data } = await this.api.program.index(providerId, programFilters);
      this.inviteProgramOptions = data;
    },

    async loadPrograms(providerId) {
      if (!providerId) {
        this.programs = [];
        return;
      }

      const programFilters = {};
      if (this.selectedSubsidyProgram)
        programFilters.subsidy_program_id = this.selectedSubsidyProgram.id;

      const { data } = await this.api.program.index(providerId, programFilters);
      this.programs = data;
    },

    async loadProviders() {
      const providerFilters = {};
      if (this.selectedSubsidyProgram)
        providerFilters.subsidy_program_id = this.selectedSubsidyProgram.id;

      const { data } = await this.api.manager.provider.index(providerFilters);
      this.providers = data;
    },

    async loadSubsidyPrograms() {
      if (this.subsidyPrograms) return;

      const response = await this.api.public_api.organization.subsidy_program.index();
      this.subsidyPrograms = response.data;

      if (!this.selectedSubsidyProgram && !!this.$store.state.filters.subsidy?.subsidyProgramId) {
        this.selectedSubsidyProgram = this.subsidyPrograms.find(
          (subsidyProgram) =>
            subsidyProgram.id === this.$store.state.filters.subsidy.subsidyProgramId,
        );
        this.setSubsidyProgramIdQuery();
      } else if (this.$route.query.subsidyProgramId) {
        this.selectedSubsidyProgram = this.subsidyPrograms.find(
          (subsidyProgram) => subsidyProgram.id === this.$route.query.subsidyProgramId,
        );
      } else {
        [this.selectedSubsidyProgram] = this.subsidyPrograms;
        this.setSubsidyProgramIdQuery();
      }
    },

    onSubsidyProgramChange() {
      this.selectedInviteProviderId = null;
      this.inviteProgramOptions = [];
      this.load();
    },

    providerNameWithAddress(provider) {
      return [provider.name, provider.address1].filter(Boolean).join(' - ');
    },

    selectAll() {
      const check = this.checkedEnrollmentRequests.length < this.enrollmentRequests.length;
      // eslint-disable-next-line no-return-assign, no-param-reassign
      this.enrollmentRequests.forEach((enrollmentRequest) => (enrollmentRequest.checked = check));
    },

    sendMassMessage() {
      this.processing = true;
      this.massMessage.enrollment_ids = this.checkedEnrollmentRequests.map(
        (enrollmentRequest) => enrollmentRequest.id,
      );
      this.api.manager.mass_message.create(
        this.massMessage,
        () => {
          this.$refs.newMessageDialog.close();
          this.processing = false;
          this.$eventBus.$emit('chime', 'Message sent');
          this.load();
        },
        (error) => {
          this.processing = false;
          this.$eventBus.$emit('chime', error.response.data.errors);
        },
      );
    },

    async setStatusForAll(status) {
      this.processing = true;
      await Promise.all(
        this.checkedEnrollmentRequests.map(async (enrollmentRequest) =>
          this.api.manager.enrollment.update(enrollmentRequest.id, { status }),
        ),
      );

      this.processing = false;
      this.load();
    },

    async setSubsidyProgramIdQuery() {
      if (!this.selectedSubsidyProgram) return;

      await this.updateQuery({ subsidyProgramId: this.selectedSubsidyProgram.id });
      this.$store.commit('setFilters', [
        'subsidy',
        { subsidyProgramId: this.selectedSubsidyProgram.id },
      ]);
    },

    async setWaitlistNumber(enrollmentRequest, number) {
      await this.api.manager.enrollment.update(enrollmentRequest.id, { waitlist_number: number });
      this.load();
    },

    toggleSort(field) {
      if (this.filters.sort_field !== field) {
        this.filters.sort_dir = 'desc';
      } else {
        this.filters.sort_dir = this.filters.sort_dir === 'asc' ? 'desc' : 'asc';
      }

      this.filters.sort_field = field;
      this.load();
    },

    async transferSelectedEnrollments() {
      this.processing = true;

      const providerId = this.selectedEnrollAllProviderId;
      await Promise.all(
        this.checkedEnrollmentRequests.map(async (enrollmentRequest) => {
          this.api.manager.enrollment.update(enrollmentRequest.id, {
            provider_id: providerId,
          });
        }),
      );

      setTimeout(() => {
        this.$refs.transferSelectedEnrollmentsDialog?.close();
        this.load();
      }, 2000);
    },

    async waitlistAll() {
      this.processing = true;
      await Promise.all(
        this.checkedEnrollmentRequests.map(async (enrollmentRequest) =>
          this.api.manager.enrollment.update(enrollmentRequest.id, {
            waitlisted: true,
            waitlist_number: null,
          }),
        ),
      );

      this.processing = false;
      this.load();
    },
  },
};
</script>
