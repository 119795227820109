<template>
  <v-window-item value="Documents">
    <AttachmentUploader
      @uploaded="loadAttachments"
      ref="enrollment_attachment_uploader"
      :owner="{
        type: 'Business',
        id: $store.state.profile.business_id,
        child_id: enrollment.child_id,
      }"
      class="mb-4"
    />

    <AttachmentList
      v-if="attachments.length > 0"
      @delete="loadAttachments"
      :attachments="attachments"
    />
  </v-window-item>
</template>

<script>
import API from '@/shared/mixins/api';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AttachmentList,
    AttachmentUploader,
  },

  mixins: [API],

  props: {
    enrollment: {
      type: Object,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      attachments: [],
    };
  },

  created() {
    this.loadAttachments();
  },

  methods: {
    async loadAttachments() {
      const params = {
        child_id: this.enrollment.child_id,
        subsidy_program_id: this.subsidyProgram?.id,
      };
      const response = await this.api.manager.attachment.index(params);
      if (response?.status !== 200) return;

      this.attachments = response.data;
    },
  },
};
</script>
