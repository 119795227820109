<template>
  <v-window-item value="Forms">
    <template v-if="acceptFormVisible || enrollmentFormVisible">
      <template v-if="enrollmentFormVisible">
        <FormCard
          v-if="subsidyProgram?.enrolled_form_schema_id"
          @open="$refs.reviewDialog.open(enrolledForm)"
          @start="$refs.enrolledFormDialog.open()"
          :form="enrolledForm"
          :schema-id="subsidyProgram.enrolled_form_schema_id"
        />

        <FormDialog
          @save="$emit('change:enrolled-form', $event)"
          ref="enrolledFormDialog"
          :enrollment-id="enrollment.id"
          :group-id="enrollment.group_id"
          :provider-id="enrollment.provider_id"
          :schema-id="subsidyProgram.enrolled_form_schema_id"
          :subsidy-id="enrollment.subsidy_id"
        />
      </template>

      <FormCard
        v-if="acceptFormVisible"
        @open="$refs.reviewDialog.open(acceptForm)"
        :form="acceptForm"
        :schema-id="subsidyProgram.accept_form_schema_id"
      />

      <FormReviewDialog ref="reviewDialog" />
    </template>
  </v-window-item>
</template>

<script>
import FormDialog from '@/shared/components/form/FormDialog.vue';
import API from '@/shared/mixins/api';
import FormCard from '@/manager/components/FormCard.vue';
import FormReviewDialog from '@/manager/components/FormReviewDialog.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    FormCard,
    FormDialog,
    FormReviewDialog,
  },

  mixins: [API],

  props: {
    acceptForm: {
      type: Object,
      default: null,
    },
    enrollment: {
      type: Object,
      default: null,
    },
    enrolledForm: {
      type: Object,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  emits: ['change:enrolled-form'],

  computed: {
    acceptFormVisible() {
      return this.subsidyProgram?.accept_form_schema_id && this.acceptForm;
    },

    enrollmentFormVisible() {
      return this.enrollment && this.subsidyProgram?.enrolled_form_schema_id;
    },
  },
};
</script>
