<template>
  <v-container v-if="profile">
    <v-card
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>{{ $t('My information') }}</v-card-title>
      <v-divider />
      <v-card-text class="pt-4 pb-8">
        <v-row>
          <LabeledTextfield
            v-model="profile.first_name"
            :hard-lock="locked"
            cols="6"
            message="First name"
          />
          <LabeledTextfield
            v-model="profile.last_name"
            :hard-lock="locked"
            cols="6"
            message="Last name"
          />
          <LabeledTextfield
            v-model="profile.email"
            :hard-lock="locked"
            message="Email"
          />
          <LabeledTextfield
            v-model="profile.phone"
            :hard-lock="locked"
            :message="$t('Cell phone')"
          />
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-checkbox
              v-model="profile.text_opted_in"
              :label="
                $t(
                  'I consent to receive communication by text message to the cellphone number provided above.',
                )
              "
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <template v-if="$store.state.pages.Profile.features.enable_profile_edits">
        <v-card-actions>
          <v-btn
            @click="updateProfile"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Update profile') }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>

    <v-card
      v-if="$store.state.config.enable_password_authentication"
      class="mb-4"
      border
      flat
      tile
    >
      <v-card-title>{{ $t('Password') }}</v-card-title>
      <v-divider />
      <v-card-text class="pt-4 pb-8">
        <v-row>
          <LabeledTextfield
            v-model="newPassword"
            :message="$t('New password')"
            cols="6"
            type="password"
          />
          <LabeledTextfield
            v-model="newPasswordConfirmation"
            :message="$t('Confirm new password')"
            cols="6"
            type="password"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="updatePassword"
          :disabled="!newPassword || newPassword != newPasswordConfirmation"
          :loading="processing"
          color="primary"
          size="x-large"
        >
          {{ $t('Update password') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <MFASettingsCard
      v-show="$store.state.config.enable_mfa_authentication"
      @change="load"
      :profile="profile"
    />

    <VerticalSpacer :min-height="60" />
  </v-container>
</template>
<script>
import API from '@/shared/mixins/api';
import MFASettingsCard from '@/shared/components/MFASettingsCard.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    MFASettingsCard,
  },

  mixins: [API],

  data() {
    return {
      currentPassword: null,
      locked: !this.$store.state.pages.Profile.features.enable_profile_edits,
      newPassword: null,
      newPasswordConfirmation: null,
      processing: false,
      profile: null,
    };
  },

  created() {
    this.load();
  },

  methods: {
    async load() {
      const { data } = await this.api.manager.profile.get();
      this.profile = data;
    },

    updatePassword() {
      this.processing = true;
      this.api.manager.profile.update(
        { password: this.newPassword },
        (resp) => {
          this.profile = resp.data;
          this.$eventBus.$emit('chime', 'Password saved');
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors.join('. '));
          this.processing = false;
        },
      );
    },

    updateProfile() {
      this.processing = true;
      this.api.manager.profile.update(
        this.profile,
        (resp) => {
          this.profile = resp.data;
          this.$eventBus.$emit('chime', 'Profile saved');
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors.join('. '));
          this.processing = false;
        },
      );
    },
  },
};
</script>
