<template>
  <v-row class="mt-4 px-4 pt-4 bt-1 bc-extra-light-gray">
    <v-col cols="6">
      <v-btn
        v-if="!hideBack"
        @click="$emit('back')"
        color="primary"
        variant="outlined"
        >{{ $t('Back') }}</v-btn
      >
    </v-col>
    <v-col
      v-if="!hideNext"
      class="d-flex justify-end"
      cols="6"
    >
      <v-btn
        @click="$emit('next')"
        color="primary"
        >{{ $t('Next') }}</v-btn
      >
    </v-col>
  </v-row>
</template>
<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    hideBack: {
      default: false,
      type: Boolean,
    },
    hideNext: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['back', 'next'],
};
</script>
