import { createStore } from 'vuex';

import Api from '@/manager/services/bright_finder.js';
import _ from 'lodash';
import SegmentService from '@/shared/services/analytics/SegmentService';

const defaultState = {
  ageGroups: [],
  appBarChip: null,
  brand: {},
  config: {},
  currentPageTabs: null,
  currentPageTitle: null,
  default_locale: 'en',
  features: {},
  filters: {},
  identified: false,
  languages: [],
  newMessage: false,
  pages: {},
  profile: null,
  providers: [],
  providerRequestableFormSchemas: [],
  role: null,
  schemas: {},
  site: null,
  siteWebpageId: null,
  userSessionTimeout: null,
  userSessionActionTimestamp: null,
};

export default createStore({
  actions: {
    page() {
      if (window.ga) {
        window.ga('set', 'page', window.location.pathname);
        window.ga('send', 'pageview');
      }

      Api.user_event.create();
    },

    identify(context, args = {}) {
      Api.member.session.get(
        'manager',
        (sessionResp) => {
          const authed =
            sessionResp.data?.status === 'authenticated' ||
            sessionResp.data?.status === 'mfa_register' ||
            sessionResp.data?.status === 'password_reset';
          if (authed) {
            Api.manager.profile.get(
              (profileResp) => {
                if (profileResp?.data.organization_id == window.organization_id) {
                  context.dispatch('setProfileAndTimeout', profileResp.data);

                  if (args.success) {
                    SegmentService.forProvider(window.analytics).identifyMember(profileResp.data);
                    args.success(profileResp.data);
                  }
                } else if (args.failure) {
                  args.failure();
                }
              },
              () => {
                if (args.failure) {
                  args.failure();
                }
              },
            );
          } else if (args.failure) {
            args.failure();
          }
        },
        () => {
          args.failure();
        },
      );
    },

    async setProfileAndTimeout(context, profile) {
      await context.commit('setProfile', profile);
      context.commit('resetTimeout');
    },
  },

  getters: {
    defaultLandingPage: (state) => () => {
      const hasBusinessAffiliation = !!state.profile?.business_id;
      const needsBusinessConfirmation =
        !hasBusinessAffiliation &&
        (state.config.enable_provider_access_request ||
          state.config.provider_onboarding_form_schema_id);
      const learningEnabled = state.pages.Learning?.enabled;

      if (needsBusinessConfirmation) {
        return { name: 'RegisterAccess' };
      } else if (hasBusinessAffiliation) {
        return { path: state.site.landing_page || '/providers/list' };
      } else if (learningEnabled) {
        return { name: 'CourseIndex' };
      } else {
        return { name: 'RegisterAccess' };
      }
    },
    hasBusinessAffiliation: (state) => () => !!state.profile?.business_id,
    hasProviderRequestableFormSchemas: (state) => () =>
      !!state.providerRequestableFormSchemas?.length,
    needsBusinessConfirmation: (state) => () =>
      !state.profile?.business_id && !!state.config.provider_onboarding_form_schema_id,
  },

  state: {
    ...defaultState,
  },

  mutations: {
    hide(state, dialog) {
      state[`${dialog}IsVisible`] = false;
    },

    setAgeGroups(state, ageGroups) {
      state.ageGroups = ageGroups;
    },

    setAppBarChip(state, chip) {
      state.appBarChip = chip;
    },

    setBrand(state, brand) {
      state.brand = brand;
    },

    setConfig(state, config) {
      state.config = config;
    },

    setCurrentPageTabs(state, tabs) {
      state.currentPageTabs = tabs;
    },

    setCurrentPageTitle(state, title) {
      state.currentPageTitle = title;
    },

    setFeatures(state, features) {
      state.features = features;
    },

    setFilters(state, args) {
      state.filters[args[0]] = args[1];
    },

    setLanguages(state, languages) {
      state.languages = languages;
    },

    setNewMessage(state, val) {
      state.newMessage = val;
    },

    setRole(state, role) {
      state.role = role;
    },

    setSchemas(state, schemas) {
      state.schemas = schemas;

      if (schemas) {
        state.providerRequestableFormSchemas = Object.values(schemas).filter(
          (schema) => schema.data_type === 'Form' && schema.meta.provider_requestable,
        );
      }
    },

    setSiteWebpageId(state, siteWebpageId) {
      state.siteWebpageId = siteWebpageId;
    },

    setUserSessionTimeout(state, userSessionTimeout) {
      state.userSessionTimeout = userSessionTimeout;
    },

    setPages(state, pages) {
      state.pages = pages;
    },

    setProfile(state, profile) {
      state.profile = profile;
      if (profile && profile.default_locale) {
        state.default_locale = profile.default_locale;
      }
    },

    setProviders(state, providers) {
      state.providers = providers;
    },

    setSite(state, site) {
      state.site = site;
    },

    show(state, dialog) {
      state[`${dialog}IsVisible`] = true;
    },

    resetState(state) {
      if (import.meta.env.NODE_ENV !== 'test')
        throw new Error('resetState should not be called in production');

      Object.assign(state, defaultState);
    },

    /* eslint no-param-reassign: ["error", { "props": false }] */
    resetTimeout: _.throttle((state) => {
      if (!!state.userSessionTimeout && !!state.profile) {
        Api.member.session.get('manager', (resp) => {
          if (resp.data?.status === 'authenticated') state.userSessionActionTimestamp = new Date();
        });
      }
    }, 15000),
  },
});
