const createDateTimeFileName = (prefix, locale) =>
  [
    prefix,
    new Intl.DateTimeFormat(locale || 'en', { dateStyle: 'medium' }).format(new Date()),
    new Intl.DateTimeFormat(locale || 'en', { timeStyle: 'medium' }).format(new Date()),
  ]
    .join('-')
    .replaceAll(',', '')
    .replaceAll(/[\s:]/g, '-');

export { createDateTimeFileName };
