<template>
  <div>
    <template v-if="enrollments">
      <div>
        <div class="d-flex justify-center mb-4">
          <v-text-field
            v-model="query"
            :aria-label="$t('Search students by name or ID')"
            :placeholder="$t('Search students by name or ID')"
            density="compact"
            prepend-inner-icon="search"
            variant="solo-filled"
            flat
            hide-details
          />
        </div>

        <v-row class="d-flex align-center mb-0">
          <v-col
            class="d-flex align-center"
            cols="12"
            md="6"
          >
            <v-btn
              @click="selectAll()"
              class="me-3"
              color="primary"
              data-cy="select-all-button"
            >
              {{ $t(allChecked ? 'Deselect all' : 'Select all') }}
            </v-btn>
            <v-btn
              v-if="$store.state.pages.Interested.features.enable_mass_messaging"
              v-show="someChecked"
              @click="$refs.newMessageDialog.open()"
              :loading="processing"
              class="me-3"
              color="primary"
              data-cy="message-button"
              elevation="2"
            >
              {{ $t('Message') }}
            </v-btn>

            <ResourceDialog
              @save="sendMassMessage"
              ref="newMessageDialog"
              :processing="processing"
              :save-button-disabled="!massMessage.subject || !massMessage.body"
              save-button-text="Send"
              title="New message"
            >
              <template #form>
                <v-row>
                  <LabeledTextfield
                    v-model="massMessage.subject"
                    message="Subject"
                  />
                  <LabeledTextarea
                    v-model="massMessage.body"
                    message="Body"
                  />
                  <v-col class="fs-16">
                    <p>
                      {{ $t('Message will be sent to') }}: <strong>{{ recipientCount }}</strong>
                      {{ $t('recipients') }}
                    </p>
                  </v-col>
                </v-row>
              </template>
            </ResourceDialog>

            <v-pagination
              v-if="pages > 1"
              v-model="page"
              :length="pages"
              :total-visible="8"
              class="d-inline-block"
            />
          </v-col>

          <v-col
            class="d-flex justify-end"
            cols="12"
            md="6"
          >
            <FilterMenu
              ref="providerFilter"
              :actions="false"
              :disabled="someChecked"
              :title="
                providers.find((provider) => filters.provider_id == provider.id)?.name || 'Location'
              "
              classes="ms-3"
              width="400"
              borderless
              left
              outlined
            >
              <template #card>
                <v-autocomplete
                  v-model="filters.provider_id"
                  @update:model-value="$refs.providerFilter.close()"
                  :item-title="(item) => item.name + ' (' + item.address1 + ')'"
                  :items="providers"
                  :placeholder="$t('Search by name or address')"
                  item-value="id"
                  variant="filled"
                  auto-select-first
                  clearable
                  left
                />
              </template>
            </FilterMenu>

            <FilterMenu
              v-if="filterPrograms.length > 0"
              ref="programFilter"
              :actions="false"
              :disabled="someChecked"
              :title="
                filterPrograms.find((program) => filters.program_id == program.id)?.name ||
                terms.program
              "
              classes="ms-3"
              width="400"
              borderless
              left
              outlined
            >
              <template #card>
                <v-select
                  v-model="filters.program_id"
                  @update:model-value="$refs.programFilter.close()"
                  :item-title="(program) => $t(program.name)"
                  :items="filterPrograms"
                  :placeholder="$t('Search by name or address')"
                  item-value="id"
                  variant="filled"
                  auto-select-first
                  clearable
                  left
                />
              </template>
            </FilterMenu>

            <v-menu location="left">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  :loading="processing"
                  class="bg-white"
                  icon="more_vert"
                  size="small"
                  variant="text"
                />
              </template>

              <v-list>
                <v-list-item @click="download('csv')">
                  <v-list-item-title>{{ $t('Export to CSV for Excel') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <v-card
          class="mb-2"
          border
          flat
          tile
        >
          <v-card-text class="fs-15">
            <v-row dense>
              <v-col class="d-flex align-center">
                <template v-if="filterSummary">
                  <v-icon start> filter_alt </v-icon>
                  <span class="me-1">
                    {{ $t('Filtering by:') }}
                  </span>
                  <span class="fw-600">{{ $t(filterSummary) }}</span>
                  <span>.&nbsp;</span>
                </template>
                <span>
                  {{ $t('Displaying first') }} <strong>{{ enrollments.length }}</strong>
                  {{ $t('results') }}, {{ $t('out of') }} <strong>{{ total }}</strong>
                  {{ $t('total results') }}.
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <template v-if="allChecked">
          <v-card
            class="bg-primary-extra-light bc-primary-light b-1 mb-2"
            border
            flat
            tile
          >
            <v-card-text class="fs-15">
              <v-row dense>
                <v-col class="d-flex align-center">
                  <template v-if="selectedAllResults">
                    <span class="me-1"
                      >{{ $t('Selected all') }} <strong>{{ total }}</strong>
                      {{ $t('results') }}.</span
                    >
                    <a
                      @click="selectedAllResults = false"
                      class="underlined c-primary"
                    >
                      <span>
                        {{ $t('Clear selection') }}
                      </span>
                    </a>
                  </template>
                  <template v-else>
                    <span class="me-1">
                      {{ $t('Selected first') }} <strong>{{ enrollments.length }}</strong>
                      {{ $t('results') }}.
                    </span>
                    <a
                      v-if="pages > 1"
                      @click="selectedAllResults = true"
                      class="underlined c-primary"
                      >{{ $t('Select all') }} <strong>{{ total }}</strong> {{ $t('results') }}.</a
                    >
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <template v-if="$vuetify.display.smAndUp">
          <v-card
            class="px-2 py-2"
            border
            flat
            tile
          >
            <v-row>
              <SortableTableHeader
                @check="selectAll()"
                @toggle="toggleSort($event)"
                :check-value="allChecked"
                :checkable="true"
                :sort-dir="sortDirection"
                :sort-field="sortField"
                cols="3"
                field="name"
                title="Child's name"
                check-locked
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="sortDirection"
                :sort-field="sortField"
                cols="1"
                field="dob"
                title="DOB"
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="sortDirection"
                :sort-field="sortField"
                cols="2"
                field="address"
                title="Home address"
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="sortDirection"
                :sort-field="sortField"
                cols="3"
                field="provider"
                title="Location"
              />

              <SortableTableHeader
                @toggle="toggleSort($event)"
                :sort-dir="sortDirection"
                :sort-field="sortField"
                :title="terms.program"
                cols="2"
                field="program"
              />
            </v-row>
          </v-card>
        </template>

        <template v-if="enrollments.length == 0">
          <NullState
            class="my-8"
            hide-new-button
          />
        </template>

        <div
          v-for="enrollment in enrollments"
          @click="display(enrollment)"
          :key="enrollment.id"
          class="mb-2 pointer"
        >
          <EnrollmentRow
            @check="enrollment.checked = !enrollment.checked"
            :displayDotBadge="getEnrolledFormMissing(enrollment)"
            :enrollment="enrollment"
            class="my-2"
          />
        </div>

        <v-pagination
          v-if="pages > 1"
          v-model="page"
          :length="pages"
          :total-visible="8"
          class="mt-4"
        />
      </div>

      <EnrollmentDialog
        @change="load"
        @close="load"
        ref="enrollDialog"
        :subsidy-programs="subsidyPrograms"
      />
    </template>

    <template v-else>
      <v-progress-linear
        class="mt-6"
        indeterminate
      />
    </template>
  </div>
</template>

<script>
import { scrollTo } from 'vuetify/lib/composables/goto';
import API from '@/shared/mixins/api';
import { createDateTimeFileName } from '@/shared/services/date-time-file-name';
import EnrollmentDialog from '@/manager/components/enrollment/EnrollmentDialog.vue';
import EnrollmentRow from '@/manager/components/enrollment/EnrollmentRow.vue';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import Terms from '@/shared/mixins/terms';

export default {
  compatConfig: { MODE: 2 },

  components: {
    EnrollmentDialog,
    EnrollmentRow,
    FilterMenu,
    NullState,
    ResourceDialog,
    SortableTableHeader,
  },

  mixins: [API, Terms],

  props: {
    subsidyProgramId: {
      type: String,
      default: null,
    },
    subsidyPrograms: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      enrollments: null,
      filterPrograms: [],
      filters: {
        program_id: this.$route.query.programId,
        provider_id: this.$route.query.providerId,
      },
      massMessage: { subject: null, body: null },
      page: 1,
      pages: null,
      processing: false,
      programs: [],
      providers: [],
      query: null,
      queryCount: 0,
      subsidyProgram: null,
      selectedAllResults: false,
      sortDirection: null,
      sortField: null,
      total: 0,
    };
  },

  computed: {
    allChecked() {
      return (
        this.enrollments.length > 0 && this.checkedEnrollments.length === this.enrollments.length
      );
    },

    checkedEnrollments() {
      return this.enrollments.filter((enrollment) => enrollment.checked);
    },

    filterSummary() {
      const appliedFilters = [];
      if (this.filters.provider_id && this.providers.length > 0) {
        appliedFilters.push(
          this.providers.find((provider) => provider.id === this.filters.provider_id)?.name,
        );
      }

      if (this.filters.program_id && this.filterPrograms.length > 0) {
        appliedFilters.push(
          this.filterPrograms.find((program) => program.id === this.filters.program_id)?.name,
        );
      }

      return appliedFilters.length > 0 ? appliedFilters.join(', ') : null;
    },

    someChecked() {
      return this.checkedEnrollments.length > 0;
    },

    recipientCount() {
      if (this.selectedAllResults) {
        return this.total;
      }
      return this.checkedEnrollments.length;
    },
  },

  watch: {
    page() {
      this.load();
      scrollTo(0);
    },

    query(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.page !== 1) {
          this.page = 1;
          return; // The change above will trigger watch that calls load()
        }
        this.load();
      }
    },

    'filters.provider_id': {
      immediate: true,
      async handler() {
        await this.loadPrograms(this.filters.provider_id);

        if (
          this.filters.program_id &&
          !this.filterPrograms.find((program) => program.id === this.filters.program_id)
        ) {
          this.filters.program_id = null;
          return; // The change above will trigger watch that calls load()
        }

        if (this.page !== 1) {
          this.page = 1;
          return; // The change above will trigger watch that calls load()
        }
        this.load();
      },
    },

    // eslint-disable-next-line func-names
    'filters.program_id': function () {
      if (this.page !== 1) {
        this.page = 1;
        return; // The change above will trigger watch that calls load()
      }
      this.load();
    },

    subsidyProgramId() {
      this.load();
    },
  },

  created() {
    this.load();
  },

  methods: {
    createMassMessage(enrollmentIds) {
      this.processing = true;
      this.massMessage.enrollment_ids = enrollmentIds;
      this.api.manager.mass_message.create(
        this.massMessage,
        () => {
          this.$refs.newMessageDialog.close();
          this.processing = false;
          this.$eventBus.$emit('chime', 'Message sent');
          this.load();
        },
        (err) => {
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors);
        },
      );
    },

    async display(enrollment) {
      this.$refs.enrollDialog.open(enrollment);
      if (enrollment.seen_by_provider_at) return;

      const params = {
        seen_by_provider: true,
      };
      await this.api.manager.enrollment.update(enrollment.id, params);
      this.load();
    },

    download(format) {
      const params = {
        status: ENROLLMENT_STATUSES.ENROLLED,
      };

      if (this.filters.provider_id) params.provider_id = this.filters.provider_id;
      if (this.filters.program_id) params.program_id = this.filters.program_id;
      if (this.subsidyProgramId) params.subsidy_program_id = this.subsidyProgramId;

      const fileName = createDateTimeFileName(
        this.$store.state.pages.Students.title || 'Students',
        this.$store.state.profile.default_locale,
      );

      const url = this.api.manager.enrollment.downloadUrl(params, fileName, format);
      window.open(url);
    },

    getEnrolledFormMissing(enrollment) {
      if (!this.subsidyProgram?.enrolled_form_schema_id) return false;
      return !enrollment.enrolled_form_id;
    },

    async load(callback) {
      this.subsidyProgram = this.subsidyPrograms?.find(
        (subsidyProgram) => subsidyProgram.id === this.subsidyProgramId,
      );

      await this.loadProviders();
      if (
        this.filters.provider_id &&
        !this.providers.find((provider) => provider.id === this.filters.provider_id)
      ) {
        this.filters.provider_id = null;
        return; // The change above will trigger watch that calls load()
      }

      const params = {};
      params.page = this.page;
      params.query = this.query;
      params.provider_id = this.filters.provider_id;
      params.program_id = this.filters.program_id;
      params.subsidy_program_id = this.subsidyProgramId;
      this.queryCount += 1;
      const currentQueryCount = this.queryCount;

      if (this.sortField) {
        params.sort_field = this.sortField;
        params.sort_dir = this.sortDirection;
        this.$router.push({ query: params });
      }

      if (this.selectedAllResults) {
        params.page_size = 500;
      }

      params.status = ENROLLMENT_STATUSES.ENROLLED;

      this.api.manager.enrollment.index(
        params,
        (response) => {
          if (this.queryCount === currentQueryCount) {
            if (callback) {
              callback(response.data);
            } else {
              this.enrollments = response.data.map((enrollment) => ({
                ...enrollment,
                checked: false,
              }));

              this.pages = parseInt(response.headers['x-page-count'] || 0, 10);
              this.total = parseInt(response.headers['x-total-count'] || 0, 10);
              this.processing = false;
            }
          }
        },
        (err) => {
          this.$eventBus.$emit('error', err);
        },
      );
    },

    async loadPrograms(providerId) {
      if (!providerId) {
        this.filterPrograms = [];
        return;
      }

      const programFilters = {
        subsidy_program_id: this.subsidyProgramId,
      };
      const { data } = await this.api.program.index(providerId, programFilters);
      this.filterPrograms = data;
    },

    async loadProviders() {
      const providerFilters = {
        subsidy_program_id: this.subsidyProgramId,
      };
      const { data } = await this.api.manager.provider.index(providerFilters);
      this.providers = data;
    },

    selectAll() {
      const checked = this.checkedEnrollments.length < this.enrollments.length;
      this.enrollments = this.enrollments.map((enrollment) => ({
        ...enrollment,
        checked,
      }));
    },

    async sendMassMessage() {
      if (this.selectedAllResults) {
        this.load((data) => {
          this.createMassMessage(data.map((enrollment) => enrollment.id));
        });
      } else {
        this.createMassMessage(this.checkedEnrollments.map((enrollment) => enrollment.id));
      }
    },

    toggleSort(field) {
      if (this.sortField !== field) {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.sortField = field;
      this.load();
    },
  },
};
</script>
