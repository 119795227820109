<template>
  <v-container
    v-if="courses"
    class="px-0 pt-0"
    fluid
  >
    <PageHero
      image="https://res.cloudinary.com/bridgecare/image/upload/v1628181324/media/Hands_-_Show_1_egfqly.svg"
      subtitle="Explore courses, complete assigned courses, and track your progress."
      title="Learning"
    />

    <v-container class="mxw-1200">
      <v-row class="d-flex align-center">
        <v-col
          cols="6"
          sm="5"
        >
          <v-tabs
            v-model="tab"
            bg-color="transparent"
          >
            <v-tab class="ls-normal tt-none fs-16 fw-500">
              {{ $t('All') }}
            </v-tab>
            <v-tab class="ls-normal tt-none fs-16 fw-500">
              {{ $t('In progress') }}
            </v-tab>
            <v-tab class="ls-normal tt-none fs-16 fw-500">
              {{ $t('Completed') }}
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col
          class="ta-right"
          cols="2"
          sm="2"
        >
          <v-select
            v-model="selectedLabels"
            :items="courseLabels"
            color="primary"
            density="comfortable"
            item-title="name"
            item-value="id"
            label="All Tags"
            variant="outlined"
            chips
            clearable
            multiple
            rounded
          ></v-select>
        </v-col>

        <v-col
          class="ta-right"
          cols="2"
          sm="2"
        >
          <v-btn
            @click="$refs.invitationCodeDialog.open()"
            color="primary"
            variant="flat"
            border
            tracked
          >
            <v-icon start> key </v-icon>
            <span>{{ $t('Enter code') }}</span>
          </v-btn>
        </v-col>

        <v-col
          class="ta-right"
          cols="3"
          sm="3"
        >
          <v-text-field
            v-model="query"
            :aria-label="$t('Search courses')"
            :placeholder="$t('Search courses')"
            bg-color="white"
            class="mxw-250 ms-auto"
            density="compact"
            prepend-inner-icon="search"
            variant="outlined"
            flat
            hide-details
            rounded
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="course in queriedCourses"
          :key="course.id"
          class="d-grid align-end"
          cols="12"
          md="6"
        >
          <CourseCard
            @get-course-labels="getCourseLabels($event)"
            :course="course"
            :course-completion="
              courseCompletions.filter(
                (courseCompletion) => courseCompletion.course_id == course.id,
              )[0]
            "
            class="mb-4"
          />
        </v-col>
      </v-row>

      <VerticalSpacer />
    </v-container>

    <v-row
      v-if="courses.length === 0"
      class="justify-center px-2"
    >
      <v-col cols="3">
        <v-img :src="$a.url('icons.nullstate')" />
      </v-col>
    </v-row>

    <v-pagination
      v-show="pages > 1"
      v-model="page"
      :length="pages"
      :total-visible="8"
      class="mt-4"
    />

    <ResourceDialog
      @save="searchInvitationCode"
      ref="invitationCodeDialog"
      :fields="[
        {
          value: 'cohortInvitationCode',
          required: true,
        },
      ]"
      save-button-text="Search"
      title="Course code"
      closeable
    >
      <template #description>
        <p class="mb-4">
          {{ $t('Open a course by entering its code below.') }}
        </p>
      </template>

      <template #append>
        <v-card
          class="mt-6"
          color="#D2DEE1"
          border
          flat
        >
          <v-card-title>
            {{ $t("Don't have a code?") }}
          </v-card-title>

          <v-card-text>
            {{
              $t("If you don't know your course's code, please contact your course facilitator.")
            }}
          </v-card-text>
        </v-card>
      </template>
    </ResourceDialog>

    <VerticalSpacer :min-height="60" />
  </v-container>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import PageHero from '@/shared/components/content/PageHero.vue';
import CourseCard from '@/shared/components/learning/CourseCard.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { newUtcDate } from '@/shared/services/date-helper';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();
const courses = ref([]);
const courseCompletions = ref([]);
const courseLabels = ref([]);
const courseManagerId = ref(null);
const selectedLabels = ref([]);
const query = ref('');
const tab = ref(0);
const page = ref(1);
const pages = ref(1);

const queriedCourses = computed(() => {
  if (!query.value && !selectedLabels.value) {
    return filteredCourses.value;
  }

  const queryLower = query.value.toLowerCase();

  return filteredCourses.value.filter((course) => {
    const labelIds = (course.labels ?? []).map((item) => item.id);
    const matchesQuery = course.name.toLowerCase().includes(queryLower);
    const matchesLabels =
      !selectedLabels.value.length || selectedLabels.value.some((lab) => labelIds.includes(lab));

    return matchesQuery && matchesLabels;
  });
});

const filteredCourses = computed(() => {
  if (tab.value === 0) {
    return courses.value;
  }

  if (tab.value === 1) {
    return courses.value.filter((course) => {
      const courseCompletion = courseCompletions.value.find(
        (courseCompletion) => courseCompletion.course_id === course.id,
      );
      return courseCompletion && courseCompletion.progress < 100;
    });
  }

  if (tab.value === 2) {
    return courses.value.filter((course) => {
      const courseCompletion = courseCompletions.value.find(
        (courseCompletion) => courseCompletion.course_id === course.id,
      );
      return courseCompletion && courseCompletion.progress === 100;
    });
  }

  return null;
});

async function searchInvitationCode(query) {
  const filters = {
    filter: {
      invitation_code: query.cohortInvitationCode,
    },
  };

  const cohortResponse = await Api.cohort.index(filters);
  const cohort = cohortResponse?.data?.data[0];

  if (!cohort) {
    eventBus.chime('Code not found');
    return;
  } else if (!cohort.attributes?.start_date || !cohort.attributes?.end_date) {
    eventBus.chime('Code is not active');
    return;
  } else if (newUtcDate(cohort.attributes.end_date) < new Date()) {
    eventBus.chime('Code has expired');
    return;
  }

  const params = {
    data: {
      type: 'cohort_member',
      attributes: {},
      relationships: {
        cohort: {
          data: {
            type: 'cohort',
            id: cohort.id,
          },
        },
      },
    },
  };

  const response = await Api.cohortMember.create(params).catch((error) => {
    eventBus.chime(error.response.data.errors[0]);
  });

  if (response?.status !== 201) return;

  eventBus.chime('Enrolled in cohort');

  const { data } = await Api.cohort.get(cohort.id);

  const courseId = data?.data?.relationships?.course?.data?.id;
  if (!courseId) {
    eventBus.chime('Course not found');
    return;
  }

  router.push({
    name: 'CourseShow',
    params: { courseId },
  });
}

async function loadCoursesFromCohorts() {
  const cohortMembersResopnse = await Api.cohortMember
    .index({ include: 'cohort' })
    .catch((error) => {
      eventBus.chime(error.response.data.errors[0]);
    });

  const cohortMembers = cohortMembersResopnse?.data?.data || [];

  await Promise.all(
    cohortMembers.map(async (cohortMember) => {
      const cohortId = cohortMember.relationships?.cohort?.data?.id;
      if (!cohortId) return;

      const cohortResponse = await Api.cohort.get(cohortId, { include: 'course' });

      const course = cohortResponse?.data?.included[0];
      if (!course) return;

      if (course.attributes?.published) {
        // The other course objects are not in a V2 format
        courses.value.push({
          id: course.id,
          ...course.attributes,
        });
      }
    }),
  );
}

function loadCourseCompletion() {
  Api.manager.course_completion.index(
    {},
    (courseCompletion) => (courseCompletions.value = courseCompletion.data),
  );
}

function load() {
  Api.public_api.organization.course.index(async (response) => {
    courses.value = response.data;
    await loadCoursesFromCohorts();
    loadCourseCompletion();
  });
}

async function getCourseLabels(course) {
  if (course?.labels?.length && !courseManagerId.value) {
    for (const label of course.labels) {
      if (!courseLabels.value.find((item) => item.id === label.id)) {
        courseLabels.value = [...courseLabels.value, label];
      }
    }
  }
}

onMounted(() => {
  load();
});
</script>
