<template>
  <v-card
    class="px-2 py-2"
    data-cy="row"
    data-testid="enrollment-row"
    border
    flat
    tile
  >
    <v-row
      :class="enrollmentRowClasses"
      role="row"
    >
      <TableCell
        @check="$emit('check')"
        :check-value="enrollment.checked"
        :checkable="true"
        cols="3"
        check-locked
      >
        <v-badge
          v-if="displayDotBadge"
          data-testid="enrollment-badge-dot"
          dot
          inline
        />
        {{ enrollment.meta.child.name }}
        <v-badge
          v-if="!enrollment.seen_by_provider_at"
          :content="$t('NEW')"
          :label="$t('New enrollment')"
          color="primary"
          data-testid="enrollment-badge-new"
          text-color="white"
          inline
        />
      </TableCell>

      <TableCell
        :content="enrollment.meta.child.dob"
        cols="1"
      />

      <TableCell
        :content="[enrollment.meta.group.home_address, enrollment.meta.group.home_city].join(' ')"
        cols="2"
      />

      <TableCell
        :content="enrollment.meta.provider.name"
        cols="3"
      />

      <TableCell
        :content="enrollment.meta.program?.name"
        cols="2"
      />
    </v-row>
  </v-card>
</template>

<script>
import TableCell from '@/shared/components/TableCell.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    TableCell,
  },

  props: {
    displayDotBadge: {
      type: Boolean,
      default: false,
    },
    enrollment: {
      type: Object,
      default: null,
    },
    hideSelector: Boolean,
  },

  emits: ['check'],

  computed: {
    enrollmentRowClasses() {
      const classes = 'd-flex align-center fs-16';

      if (this.enrollment.seen_by_provider_at) return classes;
      return `${classes} fw-900`;
    },
  },
};
</script>
