<template>
  <v-card
    class="pa-0 mt-4"
    flat
    tile
  >
    <v-card
      class="mb-2"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row
          class="d-flex"
          dense
        >
          <LabeledAutocomplete
            v-model="selectedGrantId"
            :cols="6"
            :disabled="processing"
            :items="grants"
            :placeholder="$t('Select grant')"
            item-title="name"
            item-value="id"
            clearable
            dense
          />
          <v-spacer />

          <v-btn
            @click="$refs.selectGrantDialog.open()"
            class="mt-1"
            color="primary"
          >
            {{ $t('New claim') }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="tableHeaders"
        :items="claims"
        :loading="processing"
        class="b-radius-0 b-1 bc-outlined-gray"
        item-key="id"
        dense
        disable-sort
        fixed-header
      >
        <template #item.locked="{ item }">
          <v-tooltip location="bottom">
            <template #activator="tooltipOptions">
              <v-icon
                v-bind="tooltipOptions.props"
                size="24"
              >
                {{ item.locked ? 'lock' : 'lock_open' }}
              </v-icon>
            </template>
            <span>
              {{
                $t(
                  item.locked
                    ? 'The claim is locked. You cannot make changes.'
                    : 'The claim is unlocked. You can make changes.',
                )
              }}
            </span>
          </v-tooltip>
        </template>

        <template #item.id="{ item }">
          {{ item.id.slice(0, 6).toUpperCase() }}
        </template>

        <template #item.grant_id="{ item }">
          {{ getGrantName(item) }}
        </template>

        <template #item.requested_amount="{ item }">
          {{ currency(item.requested_amount) }}
        </template>

        <template #item.approved_amount="{ item }">
          <span v-if="item.approved">{{ currency(item.approved_amount) }}</span>
        </template>

        <template #item.status="{ item }">
          <v-tooltip location="bottom">
            <template #activator="tooltipOptions">
              <span
                v-bind="tooltipOptions.props"
                :class="getStatusClasses(item)"
                class="rounded b-1 px-3 py-1"
              >
                <span v-if="item.approved">{{ $t('Approved') }}</span>
                <span v-else-if="item.denied">{{ $t('Denied') }}</span>
                <span v-else-if="item.submitted">{{ $t('Submitted') }}</span>
                <span v-else>{{ $t('Draft') }}</span>
              </span>
            </template>
            <span>
              <LongDate
                :date="item.approved_at || item.denied_at || item.submitted_at || item.created_at"
                short-month
              />
            </span>
          </v-tooltip>
        </template>

        <template #item.created_at="{ item }">
          <LongDate
            :date="item.created_at"
            short-month
          />
        </template>

        <template #item.updated_at="{ item }">
          <LongDate
            :date="item.updated_at"
            short-month
          />
        </template>

        <template #item.actions="{ item }">
          <div class="d-flex justify-end align-center">
            <ActionMenu
              @click:action:continue="$refs.createContinueClaimDialog.open(item.id)"
              @click:action:delete="deleteClaim(item)"
              @click:action:view="$refs.claimReviewDialog.open(item.id)"
              :items="getClaimActions(item)"
              button-icon="more_vert"
            />
          </div>
        </template>

        <template #no-data>
          <NullState class="my-4" />
        </template>

        <template #bottom />
      </v-data-table>
    </v-card-text>

    <ClaimReviewDialog
      @change="load()"
      ref="claimReviewDialog"
    />

    <CreateContinueClaimDialog
      @save="load()"
      ref="createContinueClaimDialog"
      :grant-id="selectedGrantId"
      :schema-id="grantProgram?.claim_schema_id"
    />

    <ResourceDialog
      @save="$refs.createContinueClaimDialog.open()"
      ref="selectGrantDialog"
      save-button-text="Continue"
      title="Select grant for claim"
      close-on-save
      closeable
    >
      <template #form>
        <v-row>
          <LabeledSimpleSelect
            v-model="selectedGrantId"
            :items="grants"
            item-title="name"
            item-value="id"
          />
        </v-row>
      </template>
    </ResourceDialog>
  </v-card>
</template>

<script setup>
import Api from '@/manager/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import ClaimReviewDialog from '@/shared/components/ClaimReviewDialog.vue';
import CreateContinueClaimDialog from '@/shared/components/CreateContinueClaimDialog.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LongDate from '@/shared/components/LongDate.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useTranslator from '@/shared/composables/useTranslator';
import { currency } from '@/plugins/filters';

const eventBus = useEventBus();
const translator = useTranslator(); // WIP Translator - okay here, do not use elsewhere

const props = defineProps({
  grantProgram: {
    type: Object,
    default: null,
  },
  grants: {
    type: Array,
    default: () => [],
  },
});

const claims = ref([]);
const processing = ref(false);
const selectedGrantId = ref(null);
const tableHeaders = computed(() => {
  return [
    {
      title: '',
      value: 'locked',
    },
    {
      title: translator.$t('ID'),
      value: 'id',
    },
    {
      title: translator.$t('Name'),
      key: 'grant_id',
    },
    {
      title: translator.$t('Requested'),
      key: 'requested_amount',
      align: 'center',
    },
    {
      title: translator.$t('Approved'),
      key: 'approved_amount',
      align: 'center',
    },
    {
      title: translator.$t('Status'),
      value: 'status',
      align: 'center',
    },
    {
      title: translator.$t('Created at'),
      key: 'created_at',
      align: 'center',
    },
    {
      title: translator.$t('Last updated'),
      key: 'updated_at',
      align: 'center',
    },
    {
      title: '',
      value: 'actions',
      align: 'right',
    },
  ];
});

onMounted(load);

watch(selectedGrantId, async () => {
  processing.value = true;
  await load();
});

async function load() {
  const params = {
    grant_program_id: props.grantProgram?.id,
    grant_id: selectedGrantId.value,
  };

  const resp = await Api.manager.claim.index(params);
  if (resp?.status !== 200) return;

  claims.value = resp.data;
  processing.value = false;
}

async function deleteClaim(claim) {
  processing.value = true;
  const resp = await Api.manager.claim.destroy(claim.id);

  processing.value = false;
  if (resp?.status === 200) {
    eventBus.chime('Claim deleted.');
    await load();
  }
}

function getClaimActions(claim) {
  if (claim.locked) return [{ avatar: 'visibility', title: 'Review', event: 'view' }];
  if (claim.submitted) return [{ avatar: 'edit', title: 'Edit', event: 'view' }];

  return [
    { avatar: 'edit', title: 'Continue', event: 'continue' },
    { avatar: 'delete', title: 'Delete', event: 'delete' },
  ];
}

function getStatusClasses(claim) {
  if (claim.approved) return 'bc-primary c-primary';
  if (claim.denied) return 'bc-red c-red';
  if (claim.submitted) return 'bc-black c-black';
  return 'bc-gray c-gray';
}

function getGrantName(claim) {
  return props.grants.find((grant) => grant.id === claim.grant_id)?.name;
}
</script>
