<template>
  <v-dialog
    v-model="visible"
    :max-width="1000"
    :min-width="320"
    scrim="transparent"
    persistent
  >
    <v-card
      v-if="enrollment"
      border
      flat
      tile
    >
      <EnrollmentDialogTitle
        @close="close"
        @edit:program="$refs.programDialog.open()"
        @edit:status="statusDialogIsVisible = true"
        :enrollment="enrollment"
        :subsidy-program="subsidyProgram"
      />

      <v-card-text class="px-0 mt-4">
        <v-divider />

        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab
            v-for="tabTitle in tabs"
            :key="tabTitle"
            :value="tabTitle"
            class="c-black fs-16 ls-normal tt-none"
          >
            {{ t(tabTitle) }}
          </v-tab>
        </v-tabs>

        <v-divider />

        <v-window
          v-model="tab"
          class="px-6 py-4"
        >
          <EnrollmentDialogFamilyTab
            :enrollment="enrollment"
            :subsidy-program="subsidyProgram"
          />
          <EnrollmentDialogNotesTab
            @save="saveNotes"
            :notes="enrollment.notes"
          />
          <EnrollmentDialogFormsTab
            :accept-form="acceptForm"
            :subsidy-program="subsidyProgram"
          />
          <EnrollmentDialogDocumentsTab
            :enrollment="enrollment"
            :subsidy-program="subsidyProgram"
          />
        </v-window>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="statusDialogIsVisible"
    max-width="500"
    scrim="transparent"
  >
    <v-card
      v-if="enrollment"
      :title="t('Status')"
      border
      flat
      tile
    >
      <template #append>
        <v-btn
          @click="statusDialogIsVisible = false"
          :aria-label="t('Close')"
          icon="close"
          variant="text"
        />
      </template>

      <v-divider class="mt-2 mb-6" />

      <v-card-text>
        <v-list>
          <v-list-item
            v-for="status in statuses"
            @click="setStatus(status.value)"
            :key="status.value"
            :append-icon="enrollment.status == status.value ? 'check' : undefined"
            :disabled="subsidyProgram?.enable_deferred_acceptance"
            :title="t(status.text || status.value)"
            base-color="primary"
            class="mb-2"
            variant="outlined"
            tile
          />
        </v-list>

        <v-divider />

        <v-btn
          @click="openCreateEnrollmentDialog()"
          :aria-label="terms.enroll"
          :disabled="!canEnroll"
          class="mt-4"
          color="primary"
          role="button"
          size="x-large"
          block
        >
          <span>{{ t(terms.enroll) }}</span>
        </v-btn>

        <template v-if="acceptEnabled">
          <v-btn
            @click="handleacceptPlacement()"
            class="mt-3"
            color="green"
            size="x-large"
            variant="text"
            block
          >
            <span>Accept</span>
          </v-btn>
        </template>

        <template v-if="declineEnabled">
          <v-btn
            @click="draftDecline()"
            :disabled="enrollment.status == ENROLLMENT_STATUSES.DECLINED"
            class="mt-3"
            color="red"
            size="x-large"
            variant="text"
            block
          >
            <span>{{ t(terms.decline) }}</span>
          </v-btn>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="declineDialogIsVisible"
    max-width="500"
  >
    <v-card
      v-if="enrollment"
      class="mx-auto mxw-500 pa-6"
    >
      <v-card-title class="mb-4">{{ t(`${terms.decline} child`) }}</v-card-title>
      <v-card-text>
        <p class="fs-16">
          {{ t(`Are you sure you want to ${terms.decline.toLowerCase()} this child?`) }}
        </p>

        <v-textarea
          v-model="removalReason"
          placeholder="Add optional note"
          variant="filled"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="declineDialogIsVisible = false"
          :loading="processing"
          variant="text"
        >
          {{ t('Cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="decline()"
          :loading="processing"
          color="primary"
        >
          {{ t(`Yes, ${terms.decline.toLowerCase()} child`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <CreateEnrollmentDialog
    @enrolled="handleEnrollment"
    ref="enrollmentDialog"
    :enrollment="enrollment"
    :providers="providers"
    :subsidy-program="subsidyProgram"
    :visible="createDialogIsVisible"
  />

  <template v-if="enrollment && subsidyProgram?.decline_form_schema_id">
    <FormDialog
      @save="setStatus(ENROLLMENT_STATUSES.DECLINED)"
      ref="declineFormDialog"
      :enrollment-id="enrollment.id"
      :group-id="enrollment.group_id"
      :provider-id="enrollment.provider_id"
      :schema-id="subsidyProgram.decline_form_schema_id"
      :subsidy-id="enrollment.subsidy_id"
    />
  </template>

  <ResourceDialog
    @save="confirmAcceptPlacement()"
    ref="confirmSeatReservationDialog"
    :cancellable="true"
    :closeable="true"
    :max-width="600"
    save-button-text="Yes"
    title="Are you sure you want to continue?"
  >
    <template #form>
      <v-row data-testid="confirmSeatReservationDialog">
        <v-col cols="12">
          <div class="fs-14">
            This action reserves a seat for this child and immediately sends the the match to the
            family. Do you wish to proceed?
          </div>
        </v-col>
      </v-row>
    </template>
  </ResourceDialog>

  <ResourceDialog
    @save="confirmDeclinePlacement()"
    ref="confirmDeclinePlacementDialog"
    :cancellable="true"
    :closeable="true"
    :max-width="600"
    save-button-text="Yes"
    title="Are you sure you want to continue?"
  >
    <template #form>
      <v-row data-testid="confirmDeclinePlacementDialog">
        <v-col cols="12">
          <div class="fs-14">
            This action declines the placement and notifies the family they did not receive a seat
            in your program. Do you wish to proceed?
          </div>
        </v-col>
      </v-row>
    </template>
  </ResourceDialog>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';
import FormDialog from '@/shared/components/form/FormDialog.vue';
import CreateEnrollmentDialog from '@/manager/components/enrollment/CreateEnrollmentDialog.vue';
import EnrollmentDialogTitle from '@/manager/components/enrollment/EnrollmentDialogTitle.vue';
import EnrollmentDialogFamilyTab from '@/manager/components/enrollment/EnrollmentDialogFamilyTab.vue';
import EnrollmentDialogFormsTab from '@/manager/components/enrollment/EnrollmentDialogFormsTab.vue';
import EnrollmentDialogNotesTab from '@/manager/components/enrollment/EnrollmentDialogNotesTab.vue';
import EnrollmentDialogDocumentsTab from '@/manager/components/enrollment/EnrollmentDialogDocumentsTab.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useRoute } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useTerms from '@/shared/composables/useTerms';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['change', 'decline']);
const props = defineProps({
  providers: {
    type: Array,
    default: () => [],
  },
  statuses: {
    type: Array,
    default: () => [],
  },
  subsidyProgram: {
    type: Object,
    default: () => null,
  },
});

const eventBus = useEventBus();
const route = useRoute();
const { t } = useI18n();
const { updateQuery } = useRouterHelper();
const { terms } = useTerms();

const acceptForm = ref(null);
const confirmDeclinePlacementDialog = ref(null);
const confirmSeatReservationDialog = ref(null);
const createDialogIsVisible = ref(false);
const declineFormDialog = ref(null);
const enrollment = ref(null);
const enrollmentDialog = ref(null);
const processing = ref(null);
const removalReason = ref(null);
const allowDeclineForStatuses = ref([
  ENROLLMENT_STATUSES.PLACED,
  ENROLLMENT_STATUSES.PROPOSED,
  ENROLLMENT_STATUSES.STANDBY,
  ENROLLMENT_STATUSES.SELECTED,
  ENROLLMENT_STATUSES.WAITLISTED,
]);
const declineDialogIsVisible = ref(false);
const tab = ref(null);
const statusDialogIsVisible = ref(false);
const visible = ref(false);

const canEnroll = computed(() => {
  if (!props.subsidyProgram?.enable_deferred_acceptance) return true;
  return enrollment.value?.status === ENROLLMENT_STATUSES.ACCEPTED;
});

const acceptEnabled = computed(() => {
  return (
    props.subsidyProgram?.enable_provider_direct_enrollment_of_students &&
    (enrollment.value?.status === ENROLLMENT_STATUSES.SELECTED ||
      enrollment.value?.status === ENROLLMENT_STATUSES.WAITLISTED)
  );
});

const declineEnabled = computed(() => {
  if (!props.subsidyProgram) return false;
  if (
    props.subsidyProgram.restrict_provider_decline &&
    !allowDeclineForStatuses.value.includes(enrollment.value?.public_status)
  )
    return false;
  return props.subsidyProgram.enable_decline_enrollment_requests;
});

const showFormsTab = computed(() => {
  return props.subsidyProgram?.accept_form_schema_id && enrollment.value?.accept_form_id;
});

const tabs = computed(() => {
  const tabs = ['Family', 'Notes'];
  if (showFormsTab.value) tabs.push('Forms');
  tabs.push('Documents');
  return tabs;
});

function close() {
  visible.value = false;
  declineDialogIsVisible.value = false;
  removalReason.value = null;
  enrollment.value = null;
  acceptForm.value = null;
  tab.value = null;
  processing.value = false;
}

function confirmDeclinePlacement() {
  confirmDeclinePlacementDialog.value?.close();
  draftDecline({ confirmed: true });
}

function confirmAcceptPlacement() {
  confirmSeatReservationDialog.value?.close();
  setStatus(ENROLLMENT_STATUSES.OFFERED);
}

async function decline() {
  processing.value = true;
  const params = {
    status: ENROLLMENT_STATUSES.DECLINED,
    removal_reason: removalReason.value,
  };
  const response = await api.manager.enrollment.update(enrollment.value.id, params);
  if (response?.status !== 200) {
    processing.value = false;
    return false;
  }

  processing.value = false;
  emit('decline', response.data);
  return true;
}

function draftDecline({ confirmed = false } = {}) {
  if (acceptEnabled.value && !confirmed) {
    confirmDeclinePlacementDialog.value?.open();
    return;
  }

  if (props.subsidyProgram.decline_form_schema_id) return declineFormDialog.value?.open();
  declineDialogIsVisible.value = true;
  return true;
}

function handleacceptPlacement() {
  confirmSeatReservationDialog.value?.open();
}

function handleEnrollment() {
  statusDialogIsVisible.value = false;
  visible.value = false;
  emit('change');
}

async function load() {
  enrollment.value = (await api.manager.enrollment.get(route.query.enrollmentId)).data;
  if (enrollment.value.accept_form_id) await loadAcceptForm();
  visible.value = true;
  if (enrollment.value?.seen_by_provider_at) return;
  const updateResp = await api.manager.enrollment.update(enrollment.value.id, {
    seen_by_provider: true,
  });
  emit('change', updateResp.data);
}

async function loadAcceptForm() {
  acceptForm.value = (
    await api.manager.provider.form.get(
      enrollment.value?.provider_id,
      enrollment.value?.accept_form_id,
    )
  ).data;
}

function openCreateEnrollmentDialog() {
  enrollmentDialog.value?.open(enrollment.value);
}

async function save({ previousStatus = null }) {
  const params = {
    status: enrollment.value.status,
    notes: enrollment.value.notes,
  };

  try {
    const response = await api.manager.enrollment.update(enrollment.value.id, params);

    if (response?.status === 200) {
      enrollment.value = response.data;
      statusDialogIsVisible.value = false;
      emit('change', response.data);
    }
  } catch (err) {
    enrollment.value.status = previousStatus;
    eventBus.chime(err.response.data.errors[0]);
  }
}

async function saveNotes(newVal) {
  await api.manager.enrollment.update(enrollment.value.id, { notes: newVal });
}

function setStatus(status) {
  const previousStatus = enrollment.value.status;
  enrollment.value.status = status;
  void save({ previousStatus });
}

watch(
  () => route.query.enrollmentId,
  (newVal) => {
    if (newVal) {
      tab.value = null;
      enrollment.value = null;
      acceptForm.value = null;
      void load();
    } else {
      visible.value = false;
    }
  },
  { immediate: true },
);

watch(visible, (newVal) => {
  if (!newVal) {
    void updateQuery({ enrollmentId: null });
  }
});

defineExpose({ close });
</script>
